import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const checkMapCar = (setSearch, setLocation, startDate, endDate) => ({
  type: actions.CHECK_MAP_CAR_REQUEST,
  setSearch,
  setLocation,
  startDate,
  endDate,
});

export const checkMapCarFetching = () => ({
  type: actions.CHECK_MAP_CAR_FETCHING,
});

export const checkMapCarFailed = () => ({
  type: actions.CHECK_MAP_CAR_FAILED,
});

export const checkMapCarSuccess = (payload) => ({
  type: actions.CHECK_MAP_CAR_SUCCESS,
  payload,
});

export default {
  checkMapCar,
  checkMapCarFetching,
  checkMapCarFailed,
  checkMapCarSuccess,
};
