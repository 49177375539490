import React, { useEffect, useState } from "react";
import styles from "../../styles/Reviews.module.css";
import { Avatar, Button, Tabs, Typography } from "antd";
import actions from "../../redux/actions";
import line2 from "../../assets/images/line3.png";
import car1 from "../../assets/images/car1.png";
import crown from "../../assets/images/crown.svg";
import profile1 from "../../assets/images/profile1.png";
import tyre from "../../assets/images/tyre.png";
import user from "../../assets/images/user2.png";
import car8 from "../../assets/images/car-8.png";
import star from "../../assets/images/star.svg";
import mycar from "../../assets/images/mycar.png";
import car from "../../assets/images/car.svg";

import { Rate } from "antd";
import { Field, Form, Formik } from "formik";
import TabPane from "antd/es/tabs/TabPane";
import { useDispatch, useSelector } from "react-redux";
import CommentReview from "../component/CommentReview";

export default function Car() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getReview("2"));
  }, []);

  const { Title, Text } = Typography;

  const getReviewCarReducer = useSelector(
    ({ getReviewCarReducer }) => getReviewCarReducer
  );

  return (
    <div className={[styles.container_fluid]}>
      <img
        src={car}
        width="100%"
        // height={400}
        style={{ objectFit: "cover" }}
      />
      <div className={[styles.container_Reviews]}>
        <div style={{ display: "flex" }}>
          {/* left */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 30,
              width: "100%",
              maxWidth: 500,
            }}
          >
            <Title
              level={4}
              className="font_Light"
              style={{ margin: 0, color: "#000" }}
            >
              Mercedes-Benz S-Class 2017
            </Title>
            <Text
              className="font_Light"
              style={{
                margin: 0,
                color: "#767575",
                marginTop: 5,
                fontSize: 16,
              }}
            >
              s 550 4matic
            </Text>

            <div
              style={{ marginTop: 5, display: "flex", alignItems: "center" }}
            >
              <img
                src={star}
                width={15}
                style={{}}
                // height={400}
              />
              <Text
                className="font_Medium"
                style={{
                  margin: 0,
                  marginTop: 5,
                  color: "#000",
                  fontSize: 14,
                  marginLeft: 5,
                }}
              >
                4.5 (35 trips)
              </Text>
            </div>
            <div>
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div style={{ width: 150 }}>
                    <Text
                      className="font_Medium"
                      style={{
                        margin: 0,
                        marginTop: 5,
                        color: "#000",
                        fontSize: 14,
                        marginLeft: 5,
                      }}
                    >
                      20 MPG
                    </Text>
                  </div>
                  <div style={{ width: 150 }}>
                    <Text
                      className="font_Medium"
                      style={{
                        margin: 0,
                        marginTop: 5,
                        color: "#000",
                        fontSize: 14,
                        marginLeft: 5,
                      }}
                    >
                      Gas (Premium)
                    </Text>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: 10,
                  }}
                >
                  <div style={{ width: 150 }}>
                    <Text
                      className="font_Medium"
                      style={{
                        margin: 0,
                        marginTop: 5,
                        color: "#000",
                        fontSize: 14,
                        marginLeft: 5,
                      }}
                    >
                      4 doore
                    </Text>
                  </div>
                  <div style={{ width: 150 }}>
                    <Text
                      className="font_Medium"
                      style={{
                        margin: 0,
                        marginTop: 5,
                        color: "#000",
                        fontSize: 14,
                        marginLeft: 5,
                      }}
                    >
                      5 seats
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <Title
                level={5}
                className="font_Light"
                style={{ margin: 0, color: "#000", marginBottom: 20 }}
              >
                HOSTED BY
              </Title>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: 0 }}>
                  <img src={profile1} width="100" />
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: 20,
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      width: 50,
                      right: -15,
                      top: -15,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    <img src={star} width={15} />
                    <Text
                      className="font_Light"
                      style={{
                        marginLeft: 5,
                        fontSize: 17,
                        fontWeight: "bolder",
                        color: "#4F4F4F",
                      }}
                    >
                      4.5
                    </Text>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 15,
                  }}
                >
                  <Title
                    level={4}
                    className="font_Light"
                    style={{ margin: 0, color: "#000" }}
                  >
                    Svetiana Anyukova
                  </Title>
                  <Text
                    className="font_Light"
                    style={{ margin: 0, color: "#000", fontSize: 16 }}
                  >
                    35 trips joined oct 2022
                  </Text>
                  <Text
                    className="font_Light"
                    style={{ margin: 0, color: "#A1A1A1", fontSize: 12 }}
                  >
                    Typically responds within 1 minute
                  </Text>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 10 }}>
              <Title
                level={5}
                className="font_Light"
                style={{ margin: 0, color: "#000", marginBottom: 10 }}
              >
                RATINGS AND REVIEWS
              </Title>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={star}
                  width={20}
                  style={{}}
                  // height={400}
                />
                <Text
                  className="font_Medium"
                  style={{
                    margin: 0,
                    marginTop: 5,
                    color: "#000",
                    fontSize: 16,
                    marginLeft: 5,
                  }}
                >
                  4.5
                </Text>
              </div>

              <Text
                className="font_Medium"
                style={{
                  margin: 0,
                  marginTop: 10,
                  color: "#4F4F4F",
                  fontSize: 16,
                }}
              >
                (3 ratings)
              </Text>
            </div>
            <Text
              className="font_Medium"
              style={{
                margin: 0,
                marginTop: 10,
                color: "#4F4F4F",
                fontSize: 16,
              }}
            >
              REVIEWS
            </Text>
            {getReviewCarReducer.result
              ? getReviewCarReducer.result.data.map((item) => {
                  return <CommentReview item={item} />;
                })
              : []}
          </div>

          {/* right */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginLeft: 70,
              marginTop: 0,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
