import { all, takeEvery } from "redux-saga/effects";
import * as actions from "../actionTypes";

import { sagaClientVerify } from "./clientVerify.saga";
import { sagaCreateTicketSupport } from "./createTicketSupport.saga";
import {
  sagaAddCreateVerifyCard,
  sagaCreateVerifyCard,
  sagaDeleteCard,
} from "./createVerifyCard.saga";
import { sagaGetCar } from "./getCar.saga";
import { sagaGetCardByid } from "./getCardByid.saga";
import { sagaGetCheckMapCar } from "./getCheckMapCar.saga";
import { sagaGetDataVerifyHost } from "./getDataVerifyHost.saga";
import { sagagetReviewCar } from "./getReviewCar.saga";
import { getReviewClient } from "./getReviewClient.saga";
import { sagaGetTicketSupport } from "./getTicketSupport.saga";
import { sagaHostVerify } from "./hostVerify.saga";
import { sagaPayment } from "./payment.saga";
import { sagaPostReviewCar, sagaPostReviewClient } from "./postReview.saga";
import { sagaSearchLocation } from "./searchLocation.saga";

// clientVerify
function* watchclientVerifyRequest() {
  yield takeEvery(actions.CLIENT_VERIFY_REQUEST, sagaClientVerify);
}

// sagaGetCar
function* watchsagaGetCarRequest() {
  yield takeEvery(actions.GET_CAR_REQUEST, sagaGetCar);
}

// sagaSearchLocation
function* watchsagaSearchLocationRequest() {
  yield takeEvery(actions.SEARCH_LOCATION_REQUEST, sagaSearchLocation);
}

// getCheckMapCar
function* watchsagagetCheckMapCarRequest() {
  yield takeEvery(actions.CHECK_MAP_CAR_REQUEST, sagaGetCheckMapCar);
}

// sagaGetDataVerifyHost
function* watchsagaGetDataVerifyHost() {
  yield takeEvery(actions.GET_DATA_VERIFY_HOST_REQUEST, sagaGetDataVerifyHost);
}

// watchsagaPayment
function* watchsagaPayment() {
  yield takeEvery(actions.PAYMENT_REQUEST, sagaPayment);
}

// watchsagagetReviewCar
function* watchsagagetReviewCar() {
  yield takeEvery(actions.GET_REVIEW_CAR_REQUEST, sagagetReviewCar);
}

// PostReviewCar
function* watchsagaPostReviewCar() {
  yield takeEvery(actions.POST_REVIEW_CAR_REQUEST, sagaPostReviewCar);
}

// PostReviewClient
function* watchsagaPostReviewClient() {
  yield takeEvery(actions.POST_REVIEW_CLIENT_REQUEST, sagaPostReviewClient);
}

// watchsagaGetReviewClient
function* watchsagaGetReviewClient() {
  yield takeEvery(actions.GET_REVIEW_CLIENT_REQUEST, getReviewClient);
}

// getTicketSupport
function* watchsagaGetTicketSupport() {
  yield takeEvery(actions.GET_TICKET_SUPPORT_REQUEST, sagaGetTicketSupport);
}

// getTicketSupport
function* watchsagaCreateTicketSupport() {
  yield takeEvery(
    actions.CREATE_TICKET_SUPPORT_REQUEST,
    sagaCreateTicketSupport
  );
}

// sagaHostVerify
function* watchsagaHostVerify() {
  yield takeEvery(actions.HOST_VERIFY_REQUEST, sagaHostVerify);
}

// sagaCreateVerifyCard

function* watchsagaCreateVerifyCard() {
  yield takeEvery(actions.CREATE_VERIFY_CARD, sagaCreateVerifyCard);
}

// sagaGetCardByid
function* watchsagaGetCardByid() {
  yield takeEvery(actions.GET_CARD_BYID_REQUEST, sagaGetCardByid);
}

// sagaAddCardByid
function* watchaddCreateVerifyCard() {
  yield takeEvery(actions.ADD_CARD_PAYMENT_REQUEST, sagaAddCreateVerifyCard);
}

// sagaDeleteCard
function* watchaddDeleteCard() {
  yield takeEvery(actions.DELETE_CARD_PAYMENT_REQUEST, sagaDeleteCard);
}

export default function* rootSaga() {
  yield all([
    watchclientVerifyRequest(),
    watchsagaGetCarRequest(),
    watchsagaSearchLocationRequest(),
    watchsagagetCheckMapCarRequest(),
    watchsagaGetDataVerifyHost(),
    watchsagaPayment(),
    watchsagagetReviewCar(),
    watchsagaPostReviewCar(),
    watchsagaPostReviewClient(),
    watchsagaGetReviewClient(),
    watchsagaGetTicketSupport(),
    watchsagaCreateTicketSupport(),
    watchsagaHostVerify(),
    watchsagaCreateVerifyCard(),
    watchsagaGetCardByid(),
    watchaddCreateVerifyCard(),
    watchaddDeleteCard(),
  ]);
}
