import * as actions from "../actionTypes";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.GET_CHAT_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };

    case actions.GET_CHAT_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };

    case actions.GET_CHAT_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };

    default:
      return state;
  }
};
