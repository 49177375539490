import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const getUserFetching = () => ({
  type: actions.GET_USER_FETCHING,
});

export const getUserFailed = () => ({
  type: actions.GET_USER_FAILED,
});

export const getUserSuccess = (payload) => ({
  type: actions.GET_USER_SUCCESS,
  payload,
});

const getUser = () => {
  return async (dispatch) => {
    try {
      dispatch(getUserFetching());

      const response = await httpClient.get(server.USER_URL);

      if (response.status == 200 && response.data.status == "ok") {
        dispatch(getUserSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(getUserFailed());
    }
  };
};

export default {
  getUser,
};
