import axios from "axios";
import { ACCESS_TOKEN, apiUrl, apiVersion } from "./Constants";

const httpClient = axios.create({
  baseURL: apiUrl + apiVersion,
});

export const setInterceptor = () => {
  // httpClient.interceptors.request.use(async (req) => {
  //   const token = localStorage.getItem(ACCESS_TOKEN);
  //   if (token) req.headers = { Authorization: "Bearer " + token };
  //   return req;
  // });
  // httpClient.interceptors.response.use(
  //   function (response) {
  //     return response;
  //   },
  //   async function (error) {
  //     if (
  //       401 === error.response.status ||
  //       403 === error.response.status ||
  //       500 === error.response.status
  //     ) {
  //       // dispatch(actions.Logout());
  //     } else {
  //       return Promise.reject(error);
  //     }
  //   }
  // );
};
export default httpClient;
