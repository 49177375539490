import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const getReviewClient = (id) => ({
  type: actions.GET_REVIEW_CLIENT_REQUEST,
  id,
});

export const getReviewClientFetching = () => ({
  type: actions.GET_REVIEW_CLIENT_FETCHING,
});

export const getReviewClientFailed = () => ({
  type: actions.GET_REVIEW_CLIENT_FAILED,
});

export const getReviewClientSuccess = (payload) => ({
  type: actions.GET_REVIEW_CLIENT_SUCCESS,
  payload,
});

export default {
  getReviewClient,
  getReviewClientFetching,
  getReviewClientFailed,
  getReviewClientSuccess,
};
