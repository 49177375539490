import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const clientVerifyRequest = (
  payload,
  showFormSuccess,
  showFormError
) => ({
  type: actions.CLIENT_VERIFY_REQUEST,
  payload,
  showFormSuccess,
  showFormError,
});

export const clientVerifyFetching = () => ({
  type: actions.CLIENT_VERIFY_FETCHING,
});

export const clientVerifyFailed = () => ({
  type: actions.CLIENT_VERIFY_FAILED,
});

export const clientVerifySuccess = (payload) => ({
  type: actions.CLIENT_VERIFY_SUCCESS,
  payload,
});

export default {
  clientVerifyFetching,
  clientVerifyFailed,
  clientVerifyRequest,
  clientVerifySuccess,
};
