import { db, database } from "../../utils/FirebaseDB";
import * as actions from "../actionTypes";

export const getChat = (user) => ({
  type: actions.GET_CHAT_REQUEST,
  user,
});

export const getChatFetching = () => ({
  type: actions.GET_CHAT_FETCHING,
});

export const getChatFailed = () => ({
  type: actions.GET_CHAT_FAILED,
});

export const getChatSuccess = (payload) => ({
  type: actions.GET_CHAT_SUCCESS,
  payload,
});

export default {
  getChat,
  getChatFetching,
  getChatFailed,
  getChatSuccess,
};
