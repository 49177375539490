import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const getReview = (id) => ({
  type: actions.GET_REVIEW_CAR_REQUEST,
  id,
});

export const getReviewCarFetching = () => ({
  type: actions.GET_REVIEW_CAR_FETCHING,
});

export const getReviewCarFailed = () => ({
  type: actions.GET_REVIEW_CAR_FAILED,
});

export const getReviewCarSuccess = (payload) => ({
  type: actions.GET_REVIEW_CAR_SUCCESS,
  payload,
});

export default {
  getReview,
  getReviewCarFetching,
  getReviewCarFailed,
  getReviewCarSuccess,
};
