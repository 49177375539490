import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const getCar = () => ({
  type: actions.GET_CAR_REQUEST,
});

export const getCarFetching = () => ({
  type: actions.GET_CAR_FETCHING,
});

export const getCarFailed = () => ({
  type: actions.GET_CAR_FAILED,
});

export const getCarSuccess = (payload) => ({
  type: actions.GET_CAR_SUCCESS,
  payload,
});

export default {
  getCar,
  getCarFetching,
  getCarFailed,
  getCarSuccess,
};
