import React, { useEffect, useState } from "react";
import styles from "../../styles/Reviews.module.css";
// import Nav from "react-bootstrap/Nav";
import { Button, Rate, Tabs } from "antd";
// import { Button, Card, Form } from "react-bootstrap";
import { Typography } from "antd";
import crown from "../../assets/images/crown.svg";
import { Avatar, Divider, Tooltip } from "antd";
import Moment from "moment";

export default function CommentReview({ item }) {
  const { Title, Text } = Typography;

  console.log(item);
  return (
    <div style={{ width: 350 }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ marginTop: 20, display: "flex" }}>
          <Avatar src={item.user.profile} style={{}} size={65} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 20,
            }}
          >
            <Title
              level={4}
              className="font_Medium"
              style={{ margin: 0, color: "#000" }}
            >
              {item.user.firstname}
            </Title>
            <div style={{ display: "flex", marginTop: 10 }}>
              <img src={crown} width={20} />
              <Title
                level={5}
                className="font_Light"
                style={{
                  margin: 0,
                  color: "#000",
                  marginLeft: 10,
                }}
              >
                All-star hort
              </Title>
            </div>
            <div style={{ marginTop: 10 }}>
              <Title
                level={5}
                className="font_Light"
                style={{ margin: 0, color: "#000" }}
              >
                415 trip joined aug 2020
              </Title>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", marginTop: 10 }}>
        <Rate disabled defaultValue={item.star} />
      </div>
      <div style={{ marginTop: 10 }}>
        <Text className="font_Light" style={{ color: "#939090" }}>
          {item.description}
        </Text>
      </div>
      <div
        style={{
          marginTop: 10,
        }}
      >
        <Text className="font_Medium" style={{ color: "#000" }}>
          {Moment(item.timestamp).endOf("minute").fromNow()}
        </Text>
      </div>
    </div>
  );
}
