import styles from "../../styles/Verify.module.css";
import { Col, Row } from "antd";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { DatePicker } from "antd";
import Select from "react-select";
import { Field, Form, Formik } from "formik";
import { Button, Space } from "antd";
import TextInput from "../component/TextInput";
import { Typography } from "antd";
import { Checkbox } from "antd";
import TextArea from "../component/TextArea";
import actions from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import line2 from "../../assets/images/line2.svg";
import user_upload from "../../assets/images/user_upload.png";
import uploadImage from "../../assets/images/uploadImage.svg";
import lightbulb from "../../assets/images/lightbulb.svg";
import sun from "../../assets/images/sun.svg";
import glasses from "../../assets/images/glasses.svg";
import house from "../../assets/images/house.svg";
import information from "../../assets/images/information.svg";
import dollar from "../../assets/images/dollar.svg";
import dot from "../../assets/images/dot.svg";
import line from "../../assets/images/line.png";

import Swal from "sweetalert2";
const options = [{ value: "test", label: "test" }];

const make = [{ value: "test", label: "test" }];
const model = [{ value: "test", label: "test" }];
const odometer = [
  { value: "0-1000", label: "0-1000" },
  { value: "1001-10000", label: "1001-10000" },
  { value: "10001-100000", label: "10001-100000" },
];
const transmission = [{ value: "test", label: "test" }];
const trim = [{ value: "test", label: "test" }];
const style = [{ value: "test", label: "test" }];
const country = [{ value: "test", label: "test" }];
const brand = [{ value: "scb", label: "scb" }];

const { Title, Text } = Typography;
const { MonthPicker, RangePicker, WeekPicker, YearPicker } = DatePicker;

const CustomInputStart = forwardRef(({ value, onClick }, ref) => (
  <button
    style={{
      color: "#000",
      border: "2px solid #939090",
      fontSize: 16,
      fontWeight: 600,
      borderRadius: 5,
      width: 200,
      height: 40,
    }}
    className="ms-3"
    onClick={onClick}
    ref={ref}
  >
    {value}
  </button>
));

export default function Verify() {
  const dispatch = useDispatch();

  const photoRef = useRef(null);
  const mybookRef = useRef(null);
  const CarphotosRef = useRef(null);

  const [data, setData] = useState({});

  const [salvage_title, setSalvage_title] = useState(false);
  const [get_trip_updates, setGet_trip_updates] = useState(false);
  const [reuire_two_day_minimum, setReuire_two_day_minimum] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [carFeatures, setCarfeatures] = useState([]);
  const [country, setCountry] = useState(null);
  // Get trip updates
  // salvage title.

  const getDataVerifyHostReducer = useSelector(
    ({ getDataVerifyHostReducer }) => getDataVerifyHostReducer
  );

  useEffect(() => {
    dispatch(actions.getDataVerifyHost());
  }, []);

  const showFormError = () => {
    Swal.fire({
      position: "center",
      icon: "error",
      customClass: "font_Light",
      title: "Error",
      text: "",
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {});
  };

  const showFormSuccess = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      customClass: "font_Light",
      title: "Success",
      text: "",
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {});
  };

  const showForm = ({
    errors,
    touched,
    dirty,
    isValid,
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    resetForm,
  }) => {
    return (
      <Form className={[styles.container_verify]}>
        <div
          style={{
            maxWidth: 240,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Title
            level={1}
            className={styles.font_Medium}
            style={{ marginBottom: 0 }}
          >
            List your car
          </Title>
          <img src={line} width={"100%"} height={4} />
        </div>
        {/* Your car */}
        <div>
          <div>
            <Title level={4} style={{ marginTop: 10 }}>
              Your car
            </Title>
          </div>

          <div className="pt-2 text-capitalize">
            <Title
              level={5}
              className={[styles.font_Light]}
              style={{ marginTop: 20 }}
            >
              Where is your car located?
            </Title>
            <Field
              className={styles.tmp_input}
              id="address"
              name="address"
              component={TextInput}
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              placeholder="Enter address"
              aria-describedby="passwordHelpBlock"
            />
          </div>

          <Row>
            <Col
              md={6}
              xs={12}
              style={{ paddingRight: 10 }}
              className="col-md-3 col-6 text-capitalize"
            >
              <Title level={5} className={[styles.font_Light]}>
                year
              </Title>

              <YearPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                placeholder="Select"
                onChange={(date) => {
                  setData((data) => ({
                    ...data,
                    car_year: new Date(date).getTime(),
                  }));
                }}
                customInput={<CustomInputStart />}
              />
              {/* <Select
                options={options}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              /> */}
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ paddingRight: 10, textTransform: "capitalize" }}
            >
              <Title level={5} className={[styles.font_Light]}>
                make
              </Title>
              <Select
                options={make}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    make: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Select"
              />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ paddingRight: 10, textTransform: "capitalize" }}
            >
              <Title level={5} className={[styles.font_Light]}>
                model
              </Title>
              <Select
                options={model}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    model: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Select"
              />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{
                textTransform: "capitalize",
              }}
            >
              <Title level={5} className={[styles.font_Light]}>
                odometer
              </Title>
              <Select
                options={odometer}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    odometer: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Select"
              />
            </Col>
          </Row>
          <Row>
            <Col md={8} xs={24} style={{ paddingRight: 10 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  textTransform: "capitalize",
                }}
              >
                transmission
              </Title>
              <Select
                options={transmission}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    transmission: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Select"
              />
            </Col>
            <Col md={8} xs={24} style={{ paddingRight: 10 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  textTransform: "capitalize",
                }}
              >
                trim
              </Title>
              <Select
                options={trim}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    trim: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Select"
              />
            </Col>
            <Col md={8} xs={24} style={{ paddingRight: 10 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  textTransform: "capitalize",
                }}
              >
                style
              </Title>
              <Select
                options={style}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    style: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Select"
              />
            </Col>
          </Row>
          <div style={{ paddingTop: 15 }}>
            <Checkbox
              onChange={() => {
                setSalvage_title((e) => !e);
                // salvage_title
              }}
            >
              My car has never had a branded or salvage title.
            </Checkbox>
            {/* <Form.Check
              inline
              className={[styles.font_Light]}
              style={{ fontWeight: 500, fontSize: 14 }}
              label="My car has never had a branded or salvage title."
              name="group1"
              type={"checkbox"}
            /> */}
          </div>
        </div>
        {/* line */}
        <img
          src={line2}
          className={styles.line}
          style={{ marginBottom: 20, marginTop: 20 }}
        />
        {/* Profile */}
        <div>
          <div>
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 0 }}
            >
              Profile
            </Title>
          </div>
          <div>
            <Text className={[styles.font_Light]} style={{ fontSize: 14 }}>
              Adding a photo helps hosts and guests recognize each other when
              picking up the car.
            </Text>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {values.file_obj_photo ? (
                    <>
                      <img
                        src={`${values.file_obj_photo}`}
                        width="200"
                        className="m-3"
                        alt="product"
                      />
                    </>
                  ) : (
                    <>
                      <img src={user_upload} width={80} height={80} />
                    </>
                  )}

                  <Title
                    level={5}
                    className={[styles.font_Medium]}
                    style={{ color: "#939090", marginTop: 10, fontWeight: 500 }}
                  >
                    Profile photo
                  </Title>
                </div>
                <div style={{ display: "flex", marginTop: 20 }}>
                  <input
                    ref={photoRef}
                    style={{ display: "none" }}
                    type="file"
                    id="file"
                    name="image"
                    onChange={(e) => {
                      setFieldValue("file_photo", e.target.files[0]);
                      setFieldValue(
                        "file_obj_photo",
                        URL.createObjectURL(e.target.files[0])
                      );
                    }}
                  />
                  <Button
                    className={[styles.button_upload]}
                    style={{
                      backgroundColor: "#4E6AFF",
                      borderColor: "#4E6AFF",
                      borderWidth: 1,
                      padding: 10,
                      height: 40,
                      color: "white",
                      borderStyle: "solid",
                      fontFamily: "Prompt-Light",
                      fontWeight: 600,
                    }}
                  >
                    Use Facebook photo
                  </Button>
                  <Button
                    className={[styles.button_upload]}
                    onClick={() => {
                      photoRef.current.click();
                    }}
                    style={{
                      backgroundColor: "white",
                      marginLeft: 10,
                      borderColor: "#939090",
                      borderWidth: 1,
                      borderStyle: "solid",
                      fontFamily: "Prompt-Light",
                      color: "#939090",
                      fontWeight: 600,
                      height: 40,
                    }}
                  >
                    Upload photo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* line */}

        <Col md={18} xs={24}>
          <div>
            <Title level={5} className={[styles.font_Light]}>
              Payment method
            </Title>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 15,
              marginLeft: 15,
            }}
          >
            <img src={information} width={20} />
            <Text
              level={4}
              className={[styles.font_Light]}
              style={{ marginLeft: 10 }}
            >
              Payment method
            </Text>
          </div>

          <div>
            <Title
              level={5}
              className={[styles.font_Light]}
              style={{ marginTop: 15 }}
            >
              Card information
            </Title>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 15,
              marginLeft: 15,
            }}
          >
            <img src={information} width={20} />
            <Text
              level={4}
              className={[styles.font_Light]}
              style={{ marginLeft: 10 }}
            >
              Your information will be stored securely.
            </Text>
          </div>

          <Row>
            <Col md={8} xs={24} style={{ paddingRight: 10 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 20 }}
              >
                Brand
              </Title>
              <Select
                options={brand}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    brand: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Name on card
              </Title>
              <Field
                className={styles.tmp_input}
                id="name_on_card"
                style={{ padding: 7 }}
                name="name_on_card"
                component={TextInput}
                onChange={handleChange}
                value={values.name_on_card}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>

            <Col md={12} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Credit card number
              </Title>

              <Field
                className={styles.tmp_input}
                id="credit_card_number"
                style={{ padding: 7 }}
                name="credit_card_number"
                component={TextInput}
                onChange={handleChange}
                value={values.credit_card_number}
                onBlur={handleBlur}
                placeholder="0000 0000 0000 0000"
                aria-describedby="passwordHelpBlock"
              />
            </Col>
          </Row>
        </Col>

        <img
          src={line2}
          className={styles.line}
          style={{ marginTop: 0, marginBottom: 0 }}
        />
        {/* Mobile number */}
        <div>
          <div>
            <Title level={4} className={[styles.font_Light]}>
              Mobile number
            </Title>
          </div>
          <div>
            <Title
              className={[styles.font_Light]}
              style={{ fontSize: 14, marginBottom: 0 }}
            >
              Enter your mobile number and we’ll text you a verification code.
            </Title>
          </div>
          <Row>
            <Col md={8} xs={24} style={{ paddingRight: 10 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 20 }}
              >
                Country
              </Title>
              <Select
                options={country}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    country: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              />
            </Col>

            <Col md={8} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 20 }}
              >
                Phone number
              </Title>
              <Field
                className={styles.tmp_input}
                id="phone_number"
                style={{ padding: 7 }}
                name="phone_number"
                component={TextInput}
                onChange={handleChange}
                value={values.phone_number}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>
          </Row>
          <div style={{ marginTop: 15 }}>
            <Checkbox
              onChange={() => {
                setGet_trip_updates((e) => !e);
              }}
            >
              <Text>Get important trip updates via text message</Text>
            </Checkbox>
          </div>
        </div>
        {/* line */}
        <div className="mt-md-3 mb-md-4 mt-1 mb-1">
          <img
            src={line2}
            style={{ marginTop: 20, marginBottom: 1 }}
            className={styles.line}
          />
        </div>
        {/* Driver’s license */}
        <div>
          <div>
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 10 }}
            >
              Driver’s license
            </Title>
          </div>

          <Row>
            <Col md={8} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Issuing country
              </Title>
              <Select
                options={country}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    issuing_country: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              />
            </Col>

            <Col md={8} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Drive’s license number
              </Title>

              <Field
                className={styles.tmp_input}
                id="license_number"
                style={{ padding: 7 }}
                name="license_number"
                component={TextInput}
                onChange={handleChange}
                value={values.license_number}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>

            <div>
              <Title
                className={[styles.font_Light]}
                style={{ fontSize: 14, marginTop: 15 }}
              >
                Enter your name exactly as it appears on your driver's license
              </Title>
            </div>
          </Row>

          <Row>
            <Col md={8} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 15 }}
              >
                First name
              </Title>

              <Field
                className={styles.tmp_input}
                id="fistname"
                name="fistname"
                style={{ padding: 7 }}
                component={TextInput}
                onChange={handleChange}
                value={values.firstname}
                onBlur={handleBlur}
                placeholder="First name"
                aria-describedby="passwordHelpBlock"
              />
            </Col>
            <Col md={8} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 15 }}
              >
                Last name
              </Title>

              <Field
                className={styles.tmp_input}
                id="lastname"
                style={{ padding: 7 }}
                name="lastname"
                component={TextInput}
                onChange={handleChange}
                value={values.lastname}
                onBlur={handleBlur}
                placeholder="Last name"
                aria-describedby="passwordHelpBlock"
              />
            </Col>
          </Row>
          <Title
            level={5}
            className={[styles.font_Light]}
            style={{ marginTop: 15 }}
          >
            Expiration date
          </Title>
          <Row>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
                options={options}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Month"
              /> */}

              <MonthPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_license_expiration_date_month}
                format="MMM"
                mode="month"
                placeholder="Month"
                onChange={(date) => {
                  console.log(new Date(date).getTime());
                  setData((data) => ({
                    ...data,
                    driver_license_expiration_date_month: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />

              {/* onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    issuing_country: e.value,
                  }));
                }} */}
            </Col>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
                options={options}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Day"
              /> */}
              <DatePicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_license_expiration_date_day}
                format="DD"
                mode="date"
                placeholder="Day"
                onChange={(date) => {
                  console.log(new Date(date).getTime());
                  setData((data) => ({
                    ...data,
                    driver_license_expiration_date_day: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
            <Col md={6} xs={8}>
              {/* <Select
                options={options}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Year"
              /> */}

              <YearPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_license_expiration_date_year}
                format="YYYY"
                mode="year"
                placeholder="Year"
                onChange={(date) => {
                  setData((data) => ({
                    ...data,
                    driver_license_expiration_date_year: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
          </Row>

          <Title
            level={5}
            className={[styles.font_Light]}
            style={{ marginTop: 15 }}
          >
            Date of birth
          </Title>
          <Row>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
                options={options}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Month"
              /> */}

              <MonthPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_license_date_of_birth_month}
                format="MMM"
                mode="month"
                placeholder="Month"
                onChange={(date) => {
                  console.log(new Date(date).getTime());
                  setData((data) => ({
                    ...data,
                    driver_license_date_of_birth_month: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
                options={options}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Day"
              /> */}

              <DatePicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_license_date_of_birth_day}
                format="DD"
                mode="date"
                placeholder="Day"
                onChange={(date) => {
                  console.log(new Date(date).getTime());
                  setData((data) => ({
                    ...data,
                    driver_license_date_of_birth_day: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
            <Col md={6} xs={8}>
              <YearPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_license_date_of_birth_year}
                format="YYYY"
                mode="year"
                placeholder="Year"
                onChange={(date) => {
                  setData((data) => ({
                    ...data,
                    driver_license_date_of_birth_year: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
              {/* <Select
                options={options}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Year"
              /> */}
            </Col>
          </Row>

          <div style={{ display: "flex", marginTop: 10 }}>
            <Title className={[styles.font_Light]} style={{ fontSize: 14 }}>
              <span
                className={[styles.font_Light]}
                style={{
                  fontSize: 14,
                }}
              >
                By clicking Next below, you agree that Carrent may collect your
              </span>
              <a
                className={[styles.font_Light]}
                style={{
                  fontSize: 14,
                  textDecorationLine: "underline",
                  marginLeft: 7,
                  color: "#27B1FF",
                }}
              >
                auto insurance score.
              </a>
            </Title>
          </div>
        </div>
        {/* line */}
        <img
          src={line2}
          className={styles.line}
          style={{ marginTop: 15, marginBottom: 15 }}
        />
        {/* Car Book */}
        <div>
          <div>
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 0 }}
            >
              Car Book
            </Title>
          </div>
          <div className="mt-3 ">
            <Title className={[styles.font_Light]} style={{ fontSize: 14 }}>
              Lorem ipsum is placeholder text commonly used in the graphic
            </Title>
          </div>
          {/* Car ID (Front) */}

          <Row>
            <Col md={8} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 15 }}
              >
                Car ID (Front)
              </Title>

              <Field
                className={styles.tmp_input}
                id="card_id_front"
                style={{ padding: 7 }}
                name="card_id_front"
                component={TextInput}
                onChange={handleChange}
                value={values.license_number}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>
            <Col md={8} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 15 }}
              >
                Car ID (Back)
              </Title>

              <Field
                className={styles.tmp_input}
                id="card_id_back"
                style={{ padding: 7 }}
                name="card_id_back"
                component={TextInput}
                onChange={handleChange}
                value={values.license_number}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>
          </Row>

          <Title
            level={5}
            className={[styles.font_Light]}
            style={{ marginTop: 15 }}
          >
            Expiration date
          </Title>
          <Row>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
                options={options}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Month"
              /> */}
              <MonthPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.car_book_expiration_date_month}
                format="MMM"
                mode="month"
                placeholder="Month"
                onChange={(date) => {
                  setData((data) => ({
                    ...data,
                    car_book_expiration_date_month: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>

            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
                options={options}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Day"
              /> */}
              <DatePicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.car_book_expiration_date_day}
                format="DD"
                mode="date"
                placeholder="Day"
                onChange={(date) => {
                  setData((data) => ({
                    ...data,
                    car_book_expiration_date_day: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
                options={options}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Year"
              /> */}
              <YearPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.car_book_expiration_date_year}
                format="YYYY"
                mode="year"
                placeholder="Year"
                onChange={(date) => {
                  setData((data) => ({
                    ...data,
                    car_book_expiration_date_year: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
          </Row>

          <div className="mt-4 col-md-9 col-12">
            <Title level={5} className={[styles.font_Light]}>
              My Book
            </Title>

            <div
              className={[styles.uploadImage]}
              onClick={() => {
                mybookRef.current.click();
              }}
            >
              {values.file_obj_mybook ? (
                <>
                  <img
                    src={values.file_obj_mybook}
                    width={"100%"}
                    height={"100%"}
                    style={{ objectFit: "cover" }}
                  />
                </>
              ) : (
                <>
                  <img src={uploadImage} width={"15%"} />
                </>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <input
                ref={mybookRef}
                style={{ display: "none" }}
                type="file"
                id="file"
                name="image"
                onChange={(e) => {
                  setFieldValue("file_mybook", e.target.files[0]);
                  setFieldValue(
                    "file_obj_mybook",
                    URL.createObjectURL(e.target.files[0])
                  );
                }}
              />
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  color: "#939090",
                  fontWeight: 500,
                }}
              >
                Add Photo
              </Title>
            </div>
          </div>

          {/* line */}
        </div>
        <img
          src={line2}
          className={styles.line}
          style={{ marginTop: 15, marginBottom: 10 }}
        />
        {/* Your goals */}
        <Col md={18} xs={24}>
          <div>
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 15 }}
            >
              Your goals
            </Title>
          </div>

          <Row>
            <Col xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 15 }}
              >
                What is your primary financial goal for sharing this car on
                Carrent?
              </Title>
              <Select
                options={
                  country
                  // getDataVerifyHostReducer.result
                  //   ? getDataVerifyHostReducer.result.why_sharing
                  //   : []
                }
                onChange={(e) => {
                  console.log(e.value);
                  setData((data) => ({
                    ...data,
                    use_carrent: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              />
            </Col>
            <Col xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 15 }}
              >
                How often do you or your family currently use this car?
              </Title>
              <Select
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    use_family: e.value,
                  }));
                }}
                options={
                  country
                  // getDataVerifyHostReducer.result
                  //   ? getDataVerifyHostReducer.result.current_use_car
                  //   : []
                }
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              />
            </Col>
            <Col xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 15 }}
              >
                How often do you want to share your car?
              </Title>
              <Select
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    use_share: e.value,
                  }));
                }}
                options={
                  country
                  // getDataVerifyHostReducer.result
                  //   ? getDataVerifyHostReducer.result.share_your_car
                  //   : []
                }
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              />
            </Col>
          </Row>
        </Col>
        <img
          src={line2}
          className={styles.line}
          style={{ marginTop: 30, marginBottom: 15 }}
        />
        {/* Car availability */}
        <div className="col-md-9 col-12">
          <div>
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 0 }}
            >
              Car availability
            </Title>
          </div>
          <div className="mt-4 mb-2">
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 15 }}
            >
              Advance notice
            </Title>
          </div>
          <Title
            level={5}
            className={[styles.font_Light]}
            style={{ fontSize: 14, marginTop: 0 }}
          >
            How much advance notice do you need before a trip starts?
          </Title>
          <div className="mt-4">
            <Title
              level={5}
              className={[styles.font_Light]}
              style={{ fontSize: 14, marginTop: 15 }}
            >
              Advance notice at home
            </Title>
            <Col xs={24} md={12}>
              <Select
                options={
                  country
                  // getDataVerifyHostReducer.result
                  //   ? getDataVerifyHostReducer.result.alert_home
                  //   : []
                }
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    advance_notice_home: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                placeholder="Select"
              />
            </Col>
          </div>

          <div className="mt-4 mb-2">
            <Title level={4} className={[styles.font_Light]}>
              Trip duration
            </Title>
          </div>
          <Title
            level={5}
            className={[styles.font_Light]}
            style={{ fontSize: 14, marginTop: 0 }}
          >
            What’s the shortest and longest possible trip you’ll accept?
          </Title>

          <div>
            <Row>
              <Col xs={24} md={12}>
                <Title level={5} className={[styles.font_Light]}>
                  Minimum trip duration
                </Title>
                <Select
                  onChange={(e) => {
                    setData((data) => ({
                      ...data,
                      min_trip_duration: e.value,
                    }));
                  }}
                  options={
                    country
                    // getDataVerifyHostReducer.result
                    //   ? getDataVerifyHostReducer.result.minimum_trip_duration
                    //   : []
                  }
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#939090",
                    }),
                  }}
                  className={[styles.tmp_select]}
                  placeholder="Select"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={12}>
                <div style={{ marginTop: 10 }}>
                  <Checkbox
                    onChange={() => {
                      setReuire_two_day_minimum((e) => !e);
                    }}
                  >
                    Reuire a 2-day minimum for trips that start Friday or
                    Saturday.
                  </Checkbox>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={12}>
                <Title level={5} className={[styles.font_Light]}>
                  Maximum trip duration
                </Title>
                <Select
                  options={
                    country
                    // getDataVerifyHostReducer.result
                    //   ? getDataVerifyHostReducer.result.maximum_trip_duration
                    //   : []
                  }
                  onChange={(e) => {
                    setData((data) => ({
                      ...data,
                      max_trip_duration: e.value,
                    }));
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#939090",
                    }),
                  }}
                  className={[styles.tmp_select]}
                  placeholder="Select"
                />
              </Col>
            </Row>
          </div>
        </div>

        <img
          src={line2}
          style={{ marginTop: 20, marginBottom: 5 }}
          className={styles.line}
        />

        <Col md={12} xs={24}>
          <div>
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 5 }}
            >
              Car details
            </Title>
          </div>

          <Col md={24} xs={24}>
            <Title
              level={5}
              className={[styles.font_Light]}
              style={{ marginTop: 15 }}
            >
              License plate number
            </Title>

            <Field
              className={styles.tmp_input}
              id="plate_number"
              style={{ padding: 7 }}
              name="plate_number"
              component={TextInput}
              onChange={handleChange}
              value={values.license_number}
              onBlur={handleBlur}
              placeholder="Plate number"
              aria-describedby="passwordHelpBlock"
            />
            <div style={{ marginTop: 10 }}>
              <Text className={[styles.font_Light]} style={{ fontSize: 14 }}>
                Your license plate information won’t be publicly visible
              </Text>
            </div>
          </Col>
        </Col>
        <div className="col-md-9 col-12 mt-5">
          <div>
            <Title
              level={5}
              className={[styles.font_Light]}
              style={{ marginTop: 15 }}
            >
              Car features
            </Title>
          </div>
          <div style={{ marginTop: 15 }}>
            <Row>
              {getDataVerifyHostReducer.result &&
              getDataVerifyHostReducer.result ? (
                getDataVerifyHostReducer.result.car_features.map((item) => {
                  return (
                    <>
                      <Col
                        md={8}
                        xs={8}
                        style={{ paddingRight: 10, paddingBottom: 10 }}
                      >
                        <Checkbox
                          onChange={({ e, idx }) => {
                            if (carFeatures.includes(item)) {
                              const checked = carFeatures.filter(
                                (d) => d != item
                              );
                              setCarfeatures(checked);
                            } else {
                              setCarfeatures((data) => [...data, item]);
                            }
                            // if (carFeatures.findIndex(e) >= 0) {
                            // const checked = carFeatures.filter(
                            //   (d) => d != item
                            // );
                            // console.log("hi");
                            // setCarfeatures(checked);
                            // } else {
                            //   console.log("hi2");
                            //   console.log(item);
                            //   setCarfeatures((data) => [...data, item]);
                            // }
                          }}
                        >
                          <Text>{item}</Text>
                        </Checkbox>
                      </Col>
                    </>
                  );
                })
              ) : (
                <></>
              )}

              {/* <Row>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>AUX input</Text>
                </Checkbox>
              </Col>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Backup camera</Text>
                </Checkbox>
              </Col>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Bike rack</Text>
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Blind spot warning</Text>
                </Checkbox>
              </Col>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Bluetooth</Text>
                </Checkbox>
              </Col>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Child seat</Text>
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Convertible</Text>
                </Checkbox>
              </Col>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>GPS</Text>
                </Checkbox>
              </Col>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Heated seats</Text>
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Keyless entry</Text>
                </Checkbox>
              </Col>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Pet friendly</Text>
                </Checkbox>
              </Col>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Ski rack</Text>
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Snow tires or chains</Text>
                </Checkbox>
              </Col>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Sunroof</Text>
                </Checkbox>
              </Col>
              <Col
                md={6}
                xs={8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Checkbox>
                  <Text>Toll pass</Text>
                </Checkbox>
              </Col>
            </Row> */}
            </Row>
          </div>
          <Title
            className={[styles.font_Light]}
            style={{ fontSize: 14, marginTop: 30 }}
          >
            Apple CarPlay is a registered trademark of Apple Inc. Android is a
            trademark of Google LLC.
          </Title>
        </div>
        <Col md={24} xs={24} className="col-md-9 col-12 mt-5">
          <div>
            <div className="mt-md-4 mb-2">
              <Title level={4} className={[styles.font_Light]}>
                Description
              </Title>
            </div>
            <Title
              level={5}
              className={[styles.font_Light]}
              style={{ fontSize: 14, marginTop: 0 }}
            >
              Tell guests what makes your car unique and why they'll love
              driving it.
            </Title>
            <div>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginBottom: 20, marginTop: 25 }}
              >
                What should I include?
              </Title>
              <Col md={16} xs={24}>
                <div className={[styles.tipsBox]}>
                  <div style={{ display: "flex" }}>
                    <Title
                      className={[styles.font_Medium]}
                      style={{
                        fontSize: 16,
                      }}
                    >
                      Tips to get more bookings
                    </Title>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <div style={{ alignSelf: "flex-start" }}>
                      <img src={lightbulb} width={50} height={50} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: 30,
                        maxWidth: 500,
                      }}
                    >
                      <Title
                        className={[styles.font_Light]}
                        style={{
                          fontSize: 15,
                          lineHeight: 1.5,
                          color: "#939090",
                          marginTop: 0,
                        }}
                      >
                        Lorem ipsum is placeholder text commonly used in the
                        graphic, print, and publishing industries for previewing
                        layouts and visual mockups.
                      </Title>
                    </div>
                  </div>
                </div>

                <Field
                  className={[styles.tmp_input]}
                  style={{
                    marginTop: 20,
                    padding: 20,
                  }}
                  id="description"
                  name="description"
                  component={TextArea}
                  onChange={handleChange}
                  value={values.description}
                  onBlur={handleBlur}
                  placeholder="Lorem ipsum is placeholder text commonly used in the graphic, print,
                  and publishing industries for previewing layouts and visual mockups."
                  aria-describedby="passwordHelpBlock"
                />

                <Title
                  level={5}
                  className={[styles.font_Light]}
                  style={{ fontSize: 14, marginTop: 15 }}
                >
                  50 word minimun
                </Title>
              </Col>
            </div>
          </div>
        </Col>
        <img
          src={line2}
          className={styles.line}
          style={{ marginBottom: 5, marginTop: 5 }}
        />
        <Col md={18} xs={24} className="col-md-9 col-12">
          <div>
            <div className="mt-md-4  mb-2">
              <Title
                level={4}
                className={[styles.font_Light]}
                style={{ marginTop: 10 }}
              >
                Car photos
              </Title>
            </div>
            <Title
              className={[styles.font_Light]}
              style={{ fontSize: 14, maxWidth: 500, lineHeight: 1.6 }}
            >
              High quality photos increase your earning potential by attracting
              more guests. Upload at least 6 photos, including multiple exterior
              angles with the whole car in frame, as well as interior shots.
              Learn more here.
            </Title>

            <div className="mt-4">
              <Row className="row mt-5">
                <Col
                  md={12}
                  xs={24}
                  className="col-md-6 col-12"
                  style={{ marginTop: 15 }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img src={sun} />
                    <Title
                      className={[styles.font_Light]}
                      style={{
                        fontSize: 16,
                        maxWidth: 500,
                        lineHeight: 1.6,
                        marginLeft: 20,
                      }}
                    >
                      Shoot during the daytime
                    </Title>
                  </div>
                </Col>
                <Col
                  md={12}
                  xs={24}
                  className="col-md-6 col-12 mt-5 mt-md-0"
                  style={{ marginTop: 15 }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img src={glasses} />
                    <Title
                      className={[styles.font_Light]}
                      style={{
                        fontSize: 16,
                        maxWidth: 500,
                        lineHeight: 1.6,
                        marginLeft: 20,
                      }}
                    >
                      Take clear, crisp photos
                    </Title>
                  </div>
                </Col>
              </Row>
              <Row className="row mt-5" style={{ marginTop: 15 }}>
                <Col
                  md={12}
                  xs={24}
                  className="col-md-6 col-12"
                  style={{ marginTop: 15 }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img src={house} />
                    <Title
                      className={[styles.font_Light]}
                      style={{
                        fontSize: 16,
                        maxWidth: 500,
                        lineHeight: 1.6,
                        marginLeft: 20,
                      }}
                    >
                      Try somewhere open or scenic
                    </Title>
                  </div>
                </Col>
                <Col
                  md={12}
                  xs={24}
                  className="col-md-6 col-12"
                  style={{ marginTop: 15 }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img src={information} />
                    <Title
                      className={[styles.font_Light]}
                      style={{
                        fontSize: 16,
                        maxWidth: 500,
                        lineHeight: 1.6,
                        marginLeft: 20,
                      }}
                    >
                      Look out for moving cars
                    </Title>
                  </div>
                </Col>
              </Row>
            </div>

            <div
              className="d-flex flex-md-column flex-row"
              style={{ display: "flex" }}
            >
              <div
                style={{
                  width: "100%",
                  maxWidth: 360,
                  marginBottom: 30,
                  marginTop: 40,
                }}
              >
                <div
                  onClick={() => {
                    CarphotosRef.current.click();
                  }}
                  className={[styles.uploadImage]}
                  style={{
                    height: 200,
                  }}
                >
                  <input
                    ref={CarphotosRef}
                    style={{ display: "none" }}
                    type="file"
                    id="file"
                    name="image"
                    onChange={(e) => {
                      setFieldValue("file_carphotos", e.target.files[0]);
                      setFieldValue(
                        "file_obj_carphotos",
                        URL.createObjectURL(e.target.files[0])
                      );
                    }}
                  />
                  {values.file_obj_carphotos ? (
                    <>
                      <img
                        src={values.file_obj_carphotos}
                        width={"100%"}
                        height={"100%"}
                        style={{ objectFit: "cover" }}
                      />
                    </>
                  ) : (
                    <>
                      <img src={uploadImage} width={"30%"} />
                    </>
                  )}
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{
                    marginTop: 15,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Title
                    level={5}
                    className={[styles.font_Light]}
                    style={{
                      color: "#939090",
                      fontWeight: 500,
                      marginTop: 15,
                    }}
                  >
                    Add Photo
                  </Title>
                </div>
              </div>
            </div>
            <Title
              level={5}
              className={[styles.font_Light]}
              style={{
                color: "#939090",
                fontWeight: 500,
                marginTop: 0,
              }}
            >
              Photos must be at least 640px by 320px and smaller than 10mb
            </Title>
          </div>
        </Col>
        <img
          src={line2}
          className={styles.line}
          style={{ marginBottom: 5, marginTop: 0 }}
        />
        <div className="col-md-9 col-12">
          <div>
            <div className="mt-md-4 mb-2">
              <Title level={4} className={[styles.font_Light]}>
                Payout
              </Title>
            </div>
            <div className="mt-4">
              <Title level={4} className={[styles.font_Light]}>
                Stripe
              </Title>
              <Title
                className={[styles.font_Light]}
                style={{ fontSize: 14, marginTop: 20 }}
              >
                To complete your Turo listing, please set up your payout account
                with Stripe, a leading payout provider.
              </Title>
              <Col
                md={16}
                xs={24}
                className="col-md-8 col-12 mt-4"
                style={{ marginTop: 20 }}
              >
                <div className={[styles.tipsBox]}>
                  <div
                    className="d-flex flex-column justify-content-between  h-100"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="d-flex">
                      <h5
                        className={[styles.font_Medium]}
                        style={{
                          fontSize: 16,
                        }}
                      >
                        Set up Stripe account
                      </h5>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="align-self-start">
                        <img src={dollar} width={30} height={30} />
                      </div>
                      <div
                        className="d-flex ms-3"
                        style={{
                          marginLeft: 20,
                          display: "flex",
                        }}
                      >
                        <Title
                          className={[styles.font_Light]}
                          style={{
                            fontSize: 15,
                            lineHeight: 1.5,
                            color: "#939090",
                          }}
                        >
                          Lorem ipsum is placeholder text commonly used in the
                          graphic, print, and publishing industries for
                          previewing layouts and visual mockups.
                        </Title>
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-end"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Title
                        className={[styles.font_Light]}
                        style={{
                          fontSize: 15,
                          color: "#27B1FF",
                          marginTop: 15,
                        }}
                      >
                        set up account
                      </Title>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </div>
        </div>
        <img
          src={line2}
          className={styles.line}
          style={{ marginTop: 15, marginBottom: 15 }}
        />
        {/* Safety & quality standards */}
        <div className="col-md-9 col-12">
          <div>
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              Safety & quality standards
            </Title>
            <Title className={[styles.font_Light]} style={{ fontSize: 14 }}>
              To complete your Turo listing, please set up your payout account
              with Stripe, a leading payout provider.
            </Title>
          </div>
          <div className="mt-4" style={{ marginTop: 20 }}>
            <Title className={[styles.font_Light]} style={{ fontSize: 14 }}>
              Lorem ipsum is placeholder text commonly used in the graphic,
              print, and publishing industries for previewing layouts and visual
              mockups.
            </Title>
          </div>
          <div className="mt-4" style={{ marginTop: 15 }}>
            <div className="d-flex" style={{ display: "flex" }}>
              <img
                src={dot}
                className="align-self-start me-3"
                style={{ paddingTop: 2, marginRight: 18 }}
              />
              <Title className={[styles.font_Light]} style={{ fontSize: 14 }}>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </Title>
            </div>
          </div>
          <div className="mt-4 mb-md-3" style={{ marginTop: 15 }}>
            <div className="d-flex" style={{ display: "flex" }}>
              <img
                src={dot}
                className="align-self-start me-3"
                style={{ paddingTop: 2, marginRight: 18 }}
              />
              <Title className={[styles.font_Light]} style={{ fontSize: 14 }}>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </Title>
            </div>
          </div>
        </div>
        <img
          src={line2}
          className={styles.line}
          style={{ marginBottom: 5, marginTop: 20 }}
        />

        <Button
          htmlType="submit"
          className={[styles.tmp_button, "mt-4"]}
          style={{ marginTop: 10 }}
        >
          <Text
            style={{
              color: "white",
            }}
          >
            Agree
          </Text>
        </Button>
      </Form>
    );
  };

  return (
    <Formik
      validate={(values) => {
        let error = {};
        return error;
      }}
      initialValues={{ address: "" }}
      onSubmit={(values, { setSubmitting }) => {
        let formData = new FormData();
        let payload = {
          ...values,
          ...data,

          salvage_title,
          get_trip_updates,
          reuire_two_day_minimum,
          approved: false,
        };

        // file_obj_carphotos
        // file_obj_mybook
        // file_obj_photo
        console.log(payload);
        formData.append(
          "car_book_expiration_date_day",
          new Date(payload.car_book_expiration_date_day).getTime()
        );
        formData.append(
          "car_book_expiration_date_month",

          new Date(payload.car_book_expiration_date_month).getTime()
        );
        formData.append(
          "car_book_expiration_date_year",
          new Date(payload.car_book_expiration_date_year).getTime()
        );

        formData.append(
          "driver_license_date_of_birth_day",
          new Date(payload.driver_license_date_of_birth_day).getTime()
        );
        formData.append(
          "driver_license_date_of_birth_month",
          new Date(payload.driver_license_date_of_birth_month).getTime()
        );
        formData.append(
          "driver_license_date_of_birth_year",
          new Date(payload.driver_license_date_of_birth_year).getTime()
        );

        formData.append(
          "driver_license_expiration_date_day",
          new Date(payload.driver_license_expiration_date_day).getTime()
        );
        formData.append(
          "driver_license_expiration_date_month",
          new Date(payload.driver_license_expiration_date_month).getTime()
        );
        formData.append(
          "driver_license_expiration_date_year",
          new Date(payload.driver_license_expiration_date_year).getTime()
        );

        console.log("carFeatures", carFeatures);

        formData.append("address", payload.address);
        formData.append("advance_notice_home", payload.advance_notice_home);
        formData.append("approved", false);
        formData.append("card_id_back", payload.card_id_back);
        formData.append("card_id_front", payload.card_id_front);
        formData.append("country", payload.country);
        formData.append("description", payload.description);
        formData.append("fistname", payload.fistname);
        formData.append("get_trip_updates", payload.get_trip_updates);
        formData.append("issuing_country", payload.issuing_country);
        formData.append("lastname", payload.lastname);
        formData.append("license_number", payload.license_number);
        formData.append("make", payload.make);
        formData.append("max_trip_duration", payload.max_trip_duration);
        formData.append("min_trip_duration", payload.min_trip_duration);
        formData.append("model", payload.model);
        formData.append("odometer", payload.odometer);
        formData.append("phone_number", payload.phone_number);
        formData.append("plate_number", payload.plate_number);
        formData.append(
          "reuire_two_day_minimum",
          payload.reuire_two_day_minimum
        );
        formData.append("salvage_title", payload.salvage_title);
        formData.append("style", payload.style);
        formData.append("transmission", payload.transmission);
        formData.append("trim", payload.trim);
        formData.append("use_carrent", payload.use_carrent);
        formData.append("use_share", payload.use_share);
        formData.append("car_year", payload.car_year);
        formData.append("car_features", JSON.stringify(carFeatures));

        formData.append("name_on_card", payload.name_on_card);
        formData.append("credit_card_number", payload.credit_card_number);
        formData.append("brand", payload.brand);

        if (payload.file_carphotos) {
          formData.append("file", payload.file_carphotos);
        }
        if (payload.file_mybook) {
          formData.append("file", payload.file_mybook);
        }
        if (payload.file_photo) {
          formData.append("file", payload.file_photo);
        }
        console.log(formData);
        dispatch(actions.hostVerify(formData, showFormSuccess, showFormError));
        setSubmitting(false);
      }}
    >
      {(props) => showForm(props)}
    </Formik>
  );
}
