import React, { useEffect, useState } from "react";
import styles from "../../styles/Reviews.module.css";
import { useNavigate } from "react-router-dom";
// import Nav from "react-bootstrap/Nav";
import Mychat from "./Mychat";
import Read from "./Read";
import { useSelector, useDispatch } from "react-redux";
import { Button, Tabs } from "antd";
import actions from "../../redux/actions";
// import { Button, Card, Form } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import CardCar from "../component/CardCar";
import { Layout, Menu, theme } from "antd";
import { Col, Row } from "antd";
import { Space } from "antd";
import { Typography } from "antd";
import line from "../../assets/images/line.png";
import like from "../../assets/images/like.png";
import line2 from "../../assets/images/line3.png";
import car1 from "../../assets/images/car1.png";
import crown from "../../assets/images/crown.svg";
import profile1 from "../../assets/images/profile1.png";
import star from "../../assets/images/star.svg";
import tyre from "../../assets/images/tyre.png";
import user from "../../assets/images/man-user2.png";
import car8 from "../../assets/images/car-8.png";
import { Rate } from "antd";
import { Field, Form, Formik } from "formik";
import document from "../../assets/images/document.png";
import Script from "react-load-script";
import TabPane from "antd/es/tabs/TabPane";
import { Checkbox } from "antd";
import { Axios } from "axios";
import { apiUrl } from "../../utils/Constants";
import { Progress } from "antd";
import TextInput from "../component/TextInput";
import TextArea from "../component/TextArea";
import Swal from "sweetalert2";

export default function PostReviews() {
  const dispatch = useDispatch();
  const [value, setValue] = useState(5);
  const [value2, setValue2] = useState(5);
  const navigation = useNavigate();
  const [focus, setFocus] = useState("1");

  const desc = [];

  useEffect(() => {}, []);

  const { Title, Text } = Typography;

  const onChange = (index) => {
    console.log(index);
  };

  const showFormError = () => {
    Swal.fire({
      position: "center",
      icon: "error",
      customClass: "font_Light",
      title: "Error",
      text: "",
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {});
  };

  const showFormSuccess = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      customClass: "font_Light",
      title: "Review Success",
      text: "",
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {
      // navigation(`/reviews?id=2`);
    });
  };

  if (value == 0) {
    setValue(1);
  }

  const showForm = ({
    errors,
    touched,
    dirty,
    isValid,
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    resetForm,
  }) => {
    return (
      <Form className={[styles.container_verify]}>
        <div className="pt-2 text-capitalize">
          <Title
            level={5}
            className={[styles.font_Light]}
            style={{ marginTop: 20 }}
          >
            Description
          </Title>
          <Field
            className={styles.tmp_input}
            id="description"
            name="description"
            component={TextArea}
            onChange={handleChange}
            value={values.description}
            onBlur={handleBlur}
            placeholder="Enter address"
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
        >
          <Button
            htmlType="submit"
            className={[styles.tmp_button]}
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            <Text
              className="font_Medium"
              style={{
                color: "white",
              }}
            >
              Review
            </Text>
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <div className={[styles.container_fluid]}>
      <div className={[styles.container_Reviews]}>
        <Tabs defaultActiveKey="1" onChange={onChange}>
          <TabPane tab="BOOKED" key="1"></TabPane>
          <TabPane tab="HISTORY" key="2"></TabPane>
          <TabPane tab="CANCEL" key="3"></TabPane>
        </Tabs>

        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Title
              level={5}
              className="font_Light"
              style={{ margin: 0, color: "#000" }}
            >
              Svetiana anyukova
            </Title>
            <img src={crown} width={15} style={{ marginLeft: 10 }} />
          </div>

          <div style={{ display: "flex" }}>
            <Button
              // htmlType="submit"
              className={[styles.tmp_button]}
              style={{ marginTop: 0, marginBottom: 0, width: 100 }}
            >
              <Text
                className="font_Medium"
                style={{
                  color: "white",
                }}
              >
                End trip
              </Text>
            </Button>
            <Button
              // htmlType="submit"
              className={[styles.tmp_button]}
              style={{
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 10,
                width: 100,
                backgroundColor: "#27B1FF",
                borderColor: "#27B1FF",
              }}
            >
              <Text
                className="font_Medium"
                style={{
                  color: "white",
                }}
              >
                chat
              </Text>
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={star} width={13} style={{ marginRight: 2 }} />
          <img src={star} width={13} style={{ marginRight: 2 }} />
          <img src={star} width={13} style={{ marginRight: 2 }} />
          <img src={star} width={13} style={{ marginRight: 2 }} />
          <img src={star} width={13} />
          <Text
            className="font_Medium"
            style={{
              marginLeft: 10,
              marginTop: 3,
              color: "black",
            }}
          >
            4.5 (35 Trips)
          </Text>
        </div>
        <div style={{ marginTop: 10, display: "flex" }}>
          <img src={car1} width="200" height={130} />
          {/* Modal */}
          <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 15 }}
          >
            <Text
              className="font_Medium"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              Modal
            </Text>
            <Text
              className="font_Light"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              Mercedes-Benz S-Class 2017
            </Text>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={tyre} width="20" height={20} />
              <Text
                className="font_Light"
                style={{
                  marginLeft: 5,
                  color: "black",
                }}
              >
                s 550 4matic
              </Text>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <img src={user} width="15" height={15} />
              <Text
                className="font_Light"
                style={{
                  marginLeft: 5,
                  color: "black",
                }}
              >
                5
              </Text>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <img src={car8} width="15" height={15} />
              <Text
                className="font_Light"
                style={{
                  marginLeft: 5,
                  color: "black",
                }}
              >
                Diesel
              </Text>
            </div>
          </div>
          {/* Trip start */}
          <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 15 }}
          >
            <Text
              className="font_Medium"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              Trip start
            </Text>
            <Text
              className="font_Light"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              Sun, 9/10/2022 10:00 a.m.
            </Text>

            <Text
              className="font_Medium"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              Trip ends
            </Text>
            <Text
              className="font_Light"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              Wed, 12/10/2022 10:00 a.m.
            </Text>

            <Text
              className="font_Medium"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              Day
            </Text>
            <Text
              className="font_Light"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              4 Days
            </Text>
          </div>
          {/* Meeting Location */}
          <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 15 }}
          >
            <Text
              className="font_Medium"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              Meeting Location
            </Text>
            <Text
              className="font_Light"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              San Francisco, CA 94103
            </Text>
          </div>

          {/* total */}
          <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 15 }}
          >
            <Text
              className="font_Medium"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              total
            </Text>
            <Text
              className="font_Light"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              $101.00 x 4 days
            </Text>
            <Text
              className="font_Light"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              Trip fee
            </Text>
            <Text
              className="font_Light"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              Standard protection
            </Text>
            <Text
              className="font_Light"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              600 total miles
            </Text>
            <Text
              className="font_Medium"
              style={{
                marginTop: 28,
                color: "#27B1FF",
              }}
            >
              Total
            </Text>
          </div>

          {/* total */}
          <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 15 }}
          >
            <Text
              className="font_Light"
              style={{
                marginTop: 28,
                color: "black",
              }}
            >
              $403.00
            </Text>
            <Text
              className="font_Light"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              $79.43
            </Text>
            <Text
              className="font_Light"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              $121.20
            </Text>
            <Text
              className="font_Light"
              style={{
                marginTop: 3,
                color: "black",
              }}
            >
              FREE
            </Text>
            <Text
              className="font_Medium"
              style={{
                marginTop: 28,
                color: "#27B1FF",
              }}
            >
              $403.00
            </Text>
          </div>
        </div>
        <img src={line2} style={{ width: "100%" }} />

        <div style={{ marginTop: 10 }}>
          <Title
            level={5}
            className="font_Light"
            style={{ margin: 0, color: "#000" }}
          >
            Review Car
          </Title>

          <Rate tooltips={desc} onChange={setValue} value={value} />

          <Formik
            validate={(values) => {
              let error = {};
              return error;
            }}
            initialValues={{ description: "" }}
            onSubmit={(values, { setSubmitting }) => {
              let payload = {
                ...values,
                star: value,
                carId: 2,
                userId: 1,
              };

              dispatch(
                actions.postReviewCar(payload, showFormSuccess, showFormError)
              );
              console.log("payload", payload);
              setSubmitting(false);
            }}
          >
            {(props) => showForm(props)}
          </Formik>

          <Title
            level={5}
            className="font_Light"
            style={{ margin: 0, color: "#000" }}
          >
            Review Client
          </Title>

          <Rate tooltips={desc} onChange={setValue2} value={value2} />

          <Formik
            validate={(values) => {
              let error = {};
              return error;
            }}
            initialValues={{ description: "" }}
            onSubmit={(values, { setSubmitting }) => {
              let payload = {
                ...values,
                star: value2,
                clientId: 1,
                userId: 0,
              };

              dispatch(
                actions.postReviewClinet(
                  payload,
                  showFormSuccess,
                  showFormError
                )
              );

              setSubmitting(false);
            }}
          >
            {(props) => showForm(props)}
          </Formik>
        </div>
      </div>
    </div>
  );
}
