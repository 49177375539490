import React, { forwardRef, useEffect, useRef, useState } from "react";
import styles from "../../styles/SearchCar.module.css";
// import Nav from "react-bootstrap/Nav";
import Mychat from "./Mychat";
import Read from "./Read";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../redux/actions";
// import { Button, Card, Dropdown, Form } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import CardCar from "../component/CardCar";
import CardCarMap from "../component/CardCarMap";
import { useSearchParams } from "react-router-dom";
// import DatePicker from "react-datepicker";
import Rcslider from "react-datepicker";
import Slider from "rc-slider";
import { Button, Col, Dropdown, Row, Space, Typography } from "antd";
import { DatePicker } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import * as geofire from "geofirestore";
import line2 from "../../assets/images/line2.svg";
import like from "../../assets/images/like.svg";
import { db } from "../../utils/FirebaseDB";
import { GeoFirestore } from "../../utils/GeofirestoreDB";
import Moment from "moment";
// import ShowMap from "../component/ShowMap";
// import Map from "../component/Map";

export default function SearchCar(props) {
  const [focus, setFocus] = useState("tab1");
  const [dateFrom, onDateFrom] = useState("");
  const [dataMap, setDataMap] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [timeFrom, onTimeFrom] = useState("tab1");
  const [value, onChange] = useState("");
  const [select, onSelect] = useState(null);
  const [current, onCurrent] = useState(null);
  const [show, onShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState([]);
  const [data, setData] = useState([]);
  const [location, setLocation] = useState({ lat: 0, lng: 0 });

  const [price, setPrice] = useState([10, 100]);
  const { Title, Text } = Typography;
  const mapRef = useRef(null);
  const dispatch = useDispatch();

  const getCheckMapCarReducer = useSelector(
    ({ getCheckMapCarReducer }) => getCheckMapCarReducer
  );

  useEffect(() => {
    // dispatch(actions.getCheckMapCar(setSearch, setLocation, null, null));
    if (startDate || endDate) {
      do_search(startDate, endDate, setLocation, setSearch);
    } else {
      dispatch(actions.checkMapCar(setSearch, setLocation, null, null));
    }
  }, [
    setLocation,
    searchParams.get("lat"),
    searchParams.get("lng"),
    startDate,
    endDate,
    setSearch,
    setData,
  ]);

  const defaultProps = {
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 14,
  };
  const renderMarkersProfile = (map, maps, item) => {
    let marker = new maps.Marker({
      position: {
        lat: item.location.lat,
        lng: item.location.lng,
      },
      map,
    });

    marker.addListener("click", () => {
      onSelect(null);
    });

    return marker;
  };

  const renderMarkers = (map, maps, item) => {
    let marker = new maps.Marker({
      position: {
        lat: item.location.lat,
        lng: item.location.lng,
      },
      map,
    });

    // map.addListener("center_changed", () => {
    //   // 3 seconds after the center of the map has changed, pan back to the
    //   // marker.
    //   window.setTimeout(() => {
    //     onSelect(null);
    //     map.panTo(marker.getPosition());
    //   }, 3000);
    // });

    attachSecretMessage(maps, map, marker, item);

    // map.addListener("click", () => {
    //   // 3 seconds after the center of the map has changed, pan back to the
    //   // marker.
    //   onSelect(null);
    // });

    // marker.addListener("center_changed", () => {
    //   onSelect(null);
    // });
    // console.log(marker.getPosition());
    // marker.addListener("click", () => {
    //   onSelect(item);
    //   map.setZoom(8);
    //   map.setCenter(marker.getPosition());
    // });
  };

  const showCar = (item) => {
    return `<Card  style="position: relative;" >
    <img src=${item.image} width="100%" />

    <div class="pt-2 px-2 pb-3">
      <div class="d-flex justify-content-between mt-2">
            <h6
            class="p-0 m-0 pt-0  font_Medium"
              style="font-size: 14, color: "#000" , padding : 0 , margin-top "
            >
              ${item.name}
            </h6>
      </div>
      <div class="d-flex align-items-center ">
        <div class="d-flex align-items-center">
          <img src="/assets/images/star.svg" width={15} />
          <h6
            class="p-0 m-0 ps-1 pt-1 font_Light"
            style="font-size: 12px; color: #000;"
          >
            ${item.star}
          </h6>
        </div>
        <div class="d-flex align-items-center ms-3 pt-1">
          <img src="/assets/images/crown.svg" width={15} class="pb-0" />
          <h6
            class="p-0 m-0 ps-1  font_Light"
            style="font-size: 12px; color: #000;"
          >
          ${item.crown}
          </h6>
        </div>
      </div>
      <div class="d-flex align-items-center mt-1">
        <div class="d-flex align-items-center">
          <img src="/assets/images/man-user.svg" width={15} />
          <h6
            class="p-0 m-0 ps-1 pt-1 font_Light"
            style="font-size: 12px; color: #000;"
          >
            ${item.user}
          </h6>
        </div>
        <div class="d-flex align-items-center ms-3 pt-1">
          <img src="/assets/images/iconcar.svg" width={18} class="pb-1" />
          <h6
            class="p-0 m-0 ps-1  font_Light"
            style="font-size: 12px; color: #000;"
          >
            ${item.type}
          </h6>
          <h6
            class="p-0 m-0 ps-2  font_Light"
            style="font-size: 12px; color: #000;"
          >
            (${item.trip} trip)
          </h6>
        </div>
      </div>
      <img
      src="/assets/images/line3.svg"
      height="0.5"
      width="240:
      style="max-width: 200px;"
    />
    <div class="d-flex justify-content-end">
      <h6
        class="p-0 m-0 font_Medium"
        style="font-size: 14px; color: #000;"
      >
        $${item.price}/day
      </h6>
    </div>
    </div>
  </Card>`;
  };

  function attachSecretMessage(maps, map, marker, item) {
    const infowindow = new maps.InfoWindow();

    marker.addListener("click", () => {
      infowindow.setContent(showCar(item));
      infowindow.open(marker.getMap(), marker);
    });
  }

  const Marker = (props) => {
    return (
      <React.Fragment>
        <div
          onClick={() => {
            onSelect(props.data.id);
            setDataMap(props.data);
          }}
          style={{
            backgroundColor: select === props.data.id ? "#000" : "#fff",
            color: select === props.data.id ? "#fff" : "#000",
          }}
          className="markMap"
        >
          <h6
            className="font_Medium text-capitalize pt-2"
            style={{ fontSize: 14 }}
            onClick={() => {
              onSelect(props.data);
            }}
          >
            USD
          </h6>
          <h6
            className="font_Medium text-capitalize pt-2"
            style={{ fontSize: 14 }}
            onClick={() => {
              onSelect(props.data);
            }}
          >
            {props.data.total}
          </h6>
        </div>

        {/* Below is info window component */}
        {select === props.data.id ? (
          <div
            style={{
              position: "relative",
              width: 250,
              zIndex: 999999,
              right: 95,
              top: 5,
            }}
          >
            <CardCarMap item={props.data} />
          </div>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  };

  const Marker2 = (props) => {
    return (
      <React.Fragment>
        <img
          src={require("../../assets/images/pin.png")}
          width={20}
          height={20}
        />
      </React.Fragment>
    );
  };

  const do_search = (startDate, endDate, setLocation, setSearch) => {
    // const d = new Date("January 2, 2023 00:00:00").getTime()
    // let temp_rows = data.filter((e) => JSON.stringify(e).indexOf(value) >= 0);

    dispatch(actions.checkMapCar(setSearch, setLocation, startDate, endDate));
  };

  const CustomInputStart = forwardRef(({ value, onClick }, ref) => (
    <button
      style={{
        color: "#000",
        border: "2px solid #939090",
        fontSize: 16,
        fontWeight: 600,
        borderRadius: 5,
        width: 200,
        height: 40,
      }}
      className="ms-3"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  const filterPrice = () => {
    const filterPrice = [];
    getCheckMapCarReducer.result &&
      getCheckMapCarReducer.result.map((item) => {
        if (+item.price >= +price[0] && +item.price <= +price[1]) {
          filterPrice.push(item);
        }
      });

    setSearch(filterPrice);
  };

  return (
    <div className={[styles.container]}>
      <Row>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            {/* FROM */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h6
                className="font_Medium text-capitalize pt-2"
                style={{ fontSize: 16, paddingRight: 20 }}
              >
                From
              </h6>
              <DatePicker
                showTime
                style={{ padding: 10 }}
                className={[styles.tmp_input]}
                selected={startDate}
                onChange={(date) => setStartDate(new Date(date).getTime())}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                customInput={<CustomInputStart />}
                showTimeInput
              />
            </div>

            {/* UNTIL */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 20,
              }}
            >
              <h6
                className="font_Medium text-capitalize pt-2"
                style={{ fontSize: 16, paddingRight: 20 }}
              >
                Until
              </h6>

              <DatePicker
                showTime
                style={{ padding: 10 }}
                className={[styles.tmp_input]}
                selected={endDate}
                onChange={(date) => setEndDate(new Date(date).getTime())}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                customInput={<CustomInputStart />}
                showTimeInput
              />
            </div>
          </div>

          {/* Sort By */}
          <div
            style={{
              paddingTop: 25,
              display: "flex",
              marginRight: 50,
              justifyContent: "center",
            }}
          >
            <div class="dropdown">
              <button
                class="dropbtn"
                style={{
                  backgroundColor: "white",
                  marginLeft: 10,
                  borderColor: "#939090",
                  borderWidth: 1,
                  width: 150,
                  borderStyle: "solid",
                  fontFamily: "Prompt-Medium",
                  color: "#000",
                  fontWeight: 600,
                }}
              >
                Sort by
              </button>
              <div class="dropdown-content">
                <a
                  href="#"
                  onClick={async () => {
                    setSearch(
                      [...search].sort(function (a, b) {
                        return parseFloat(a.price) - parseFloat(b.price);
                      })
                    );
                  }}
                >
                  Price: low to high
                </a>
                <a
                  href="#"
                  onClick={() => {
                    setSearch(
                      [...search]
                        .sort(function (a, b) {
                          return parseFloat(a.price) - parseFloat(b.price);
                        })
                        .reverse()
                    );
                  }}
                >
                  Price: high to low
                </a>
              </div>
            </div>
            <div class="dropdown">
              <button
                class="dropbtn"
                style={{
                  backgroundColor: "white",
                  marginLeft: 10,
                  borderColor: "#939090",
                  borderWidth: 1,
                  width: 150,
                  borderStyle: "solid",
                  fontFamily: "Prompt-Medium",
                  color: "#000",
                  fontWeight: 600,
                }}
              >
                Price
              </button>
              <div
                class="dropdown-content"
                style={{ width: 300, padding: 20, left: 0 }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text className="font_Medium" style={{ fontSize: 16 }}>
                    USD{price[0]} - USD{price[1]}+/day
                  </Text>
                </div>
                <Slider
                  trackStyle={{ backgroundColor: "#000000" }}
                  railStyle={{ backgroundColor: "#00000090" }}
                  style={{
                    marginTop: 15,
                    marginBlock: 15,
                  }}
                  range
                  value={price}
                  onChange={(e) => {
                    setPrice(e);
                  }}
                />
                <Button
                  onClick={() => {
                    filterPrice();
                  }}
                  className={[styles.tmp_button]}
                  style={{ fontSize: 16, color: "white" }}
                >
                  Filter
                </Button>
              </div>
            </div>
            {/* <Dropdown>
              <Dropdown.Toggle
                className={[styles.button_upload]}
                style={{
                  backgroundColor: "white",
                  marginLeft: 10,
                  borderColor: "#939090",
                  borderWidth: 1,
                  width: 150,
                  borderStyle: "solid",
                  fontFamily: "Prompt-Medium",
                  color: "#000",
                  fontWeight: 600,
                }}
              >
                Sort by
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={async () => {
                    setSearch(
                      [...search].sort(function (a, b) {
                        return parseFloat(a.price) - parseFloat(b.price);
                      })
                    );
                  }}
                >
                  Price: low to high
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setSearch(
                      [...search]
                        .sort(function (a, b) {
                          return parseFloat(a.price) - parseFloat(b.price);
                        })
                        .reverse()
                    );
                  }}
                >
                  Price: high to low
                </Dropdown.Item>
                <Dropdown.Item>Distance away</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}

            {/* <Dropdown>
              <Dropdown.Toggle
                className={[styles.button_upload]}
                style={{
                  backgroundColor: "white",
                  marginLeft: 10,
                  borderColor: "#939090",
                  borderWidth: 1,
                  width: 150,
                  borderStyle: "solid",
                  fontFamily: "Prompt-Medium",
                  color: "#000",
                  fontWeight: 600,
                }}
              >
                Price
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ width: 300, padding: 20, left: 0 }}>
                <div className="d-flex justify-content-between">
                  <h6
                    className="font_Medium text-capitalize pt-2"
                    style={{ fontSize: 16 }}
                  >
                    USD{price[0]} - USD{price[1]}+/day
                  </h6>
                </div>
                <Slider
                  range
                  value={price}
                  onChange={(e) => {
                    setPrice(e);
                  }}
                />
                <Button
                  onClick={() => {
                    filterPrice();
                  }}
                  className={[styles.tmp_button]}
                  style={{ fontSize: 16 }}
                >
                  filter
                </Button>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        </div>
        {/* line */}
        <img src={line2} className={[styles.line]} style={{ marginTop: 15 }} />
        <Col md={11} style={{ marginRight: 10 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={like} width={24} style={{ margin: 0, padding: 0 }} />
            <Text
              className={[styles.font_Light]}
              style={{
                fontSize: 16,
                color: "#939090",
                marginLeft: 7,
              }}
            >
              Don’t stress: you can cancel your trip for free, up to 24 hours
              before it starts.
            </Text>
          </div>
          <Row style={{ marginTop: 10 }}>
            {search.map((item) => {
              return (
                <Col
                  md={12}
                  className="col-md-6 col-12 mt-3"
                  style={{ paddingLeft: 8, paddingRight: 8, marginTop: 10 }}
                >
                  <CardCar item={item} />
                </Col>
              );
            })}
          </Row>
        </Col>

        <Col md={12}>
          <div
            style={{
              height: "60vh",
              width: "100%",
              position: "sticky",
              top: 20,
            }}
          >
            {/* <Map search={search} /> */}
            {/* <ShowMap search={search} props={props} /> */}

            <GoogleMapReact
              ref={mapRef}
              bootstrapURLKeys={{
                key: "AIzaSyAEypeqZqk-NxP9jGJK4iljbeXFqZG9PN4",
                libraries: "places",
              }}
              defaultCenter={location}
              defaultZoom={defaultProps.zoom}
              center={location}
              onGoogleApiLoaded={({ map, maps, ref }) => {
                map.addListener("click", () => {
                  onSelect(null);
                });
              }}
            >
              <Marker2
                key={`${Math.random()}`}
                lat={location.lat}
                lng={location.lng}
              />
              {search.map((item, key) => {
                return (
                  <Marker
                    key={`${key}${Math.random()}`}
                    lat={item.location.lat}
                    lng={item.location.lng}
                    data={item}
                  />
                );
              })}
            </GoogleMapReact>
          </div>
        </Col>
      </Row>
    </div>
  );
}
