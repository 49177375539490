import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

const createToken = (payload, showFormSuccess) => {
  return async (dispatch) => {
    try {
      console.log(payload);
      const response = await httpClient.post(server.PAYMENT, payload);

      if (response.status == 200) {
        showFormSuccess();
        console.log("response", JSON.stringify(response));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export default {
  createToken,
};
