import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";
import Actions from "../actions";
import { call, put, delay } from "redux-saga/effects";

export function* sagaSearchLocation({ payload, navigation }) {
  try {
    yield put(Actions.searchLoactionFetching());

    yield put(Actions.searchLoactionSuccess(payload));
    localStorage.setItem("location", JSON.stringify(payload));
    navigation(`/searchCar?lat=${payload.lat}&lng=${payload.lng}`);
  } catch (error) {
    yield put(Actions.searchLoactionFailed());
  }
}
