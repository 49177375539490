import React, { useEffect, useState } from "react";
import styles from "../../styles/Inbox.module.css";
// import Nav from "react-bootstrap/Nav";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../redux/actions";
import Title from "antd/es/typography/Title";
import line from "../../assets/images/line.png";
import verify from "../../assets/images/verify.png";
import { db, database, storage } from "../../utils/FirebaseDB";
import Moment from "moment";
import {
  getDoc,
  collection,
  getDocs,
  setDoc,
  doc,
  where,
  query,
  addDoc,
} from "firebase/firestore";
import {
  getDatabase,
  get,
  ref,
  set,
  onValue,
  push,
  update,
} from "firebase/database";
import * as storageDB from "firebase/storage";

export default function Mychat({
  setFocus,
  selected,
  setSelected,
  message,
  setMessage,
  chatroom,
  setChatroom,
  room,
}) {
  const dispatch = useDispatch();

  const [text, setText] = useState("");
  const [image, setImage] = useState(null);
  const [blob, setBlob] = useState(null);
  //   Reducer
  const getUserReducer = useSelector(({ getUserReducer }) => getUserReducer);
  const getChatReducer = useSelector(({ getChatReducer }) => getChatReducer);

  const showMessage = (message) => {
    let data = [];
    message.map((msg, index) => {
      if (
        index != 0 &&
        data[index - 1].sender &&
        data[index - 1].sender == msg.sender
      ) {
        data.push({ ...msg, showImage: false });
      } else {
        data.push({ ...msg, showImage: true });
      }
    });
    setMessage(data);
  };

  return (
    <>
      <div className="p-md-5 px-2 pt-4">
        {room &&
          room.map((item) => {
            return (
              <>
                <div
                  onClick={async () => {
                    localStorage.setItem(
                      "chatroomId",
                      JSON.stringify(item.chatroomId)
                    );
                    setFocus("2");
                    console.log(item.chatroomId);
                    await showMessage(chatroom[0][item.chatroomId].messages);

                    setSelected({
                      profile: item.profile,
                      firstname: item.firstname,
                      lastname: item.lastname,
                      chatroomId: item.chatroomId,
                      ...chatroom[0][item.chatroomId],
                    });
                    localStorage.setItem(
                      "chatroomId",
                      JSON.stringify(item.chatroomId)
                    );
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                    className="d-flex align-items-center justify-content-between w-100"
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={item.profile}
                        style={{
                          width: 50,
                          height: 50,
                          marginTop: 10,
                          borderRadius: 100,
                          objectFit: "cover",
                        }}
                      />
                      <div
                        className="d-flex flex-column pt-md-2 pt-1 ms-3"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: 10,
                          paddingTop: 5,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Title
                            level={4}
                            className={"font_Medium"}
                            style={{ margin: 0, padding: 0 }}
                          >
                            {item.firstname}
                          </Title>
                          <img
                            src={verify}
                            style={{
                              width: 15,
                              height: 15,
                              marginTop: 6,
                              marginLeft: 5,
                            }}
                          />
                        </div>
                        {/* <div style={{ height: 17 }}></div> */}
                        <Title
                          level={5}
                          className={"font_Light"}
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: 14,
                            marginTop: 5,
                          }}
                        >
                          {chatroom[0][item.chatroomId]
                            ? chatroom[0][item.chatroomId].messages
                              ? chatroom[0][item.chatroomId].messages[
                                  chatroom[0][item.chatroomId].messages.length -
                                    1
                                ].text
                                ? chatroom[0][item.chatroomId].messages[
                                    chatroom[0][item.chatroomId].messages
                                      .length - 1
                                  ].text.length.toString() > 12
                                  ? chatroom[0][item.chatroomId].messages[
                                      chatroom[0][item.chatroomId].messages
                                        .length - 1
                                    ].text
                                      .toString()
                                      .substring(0, 12 - 3) + "..."
                                  : chatroom[0][item.chatroomId].messages[
                                      chatroom[0][item.chatroomId].messages
                                        .length - 1
                                    ].text.toString()
                                : chatroom[0][item.chatroomId].messages[
                                    chatroom[0][item.chatroomId].messages
                                      .length - 1
                                  ].image
                                ? "รูปภาพ"
                                : "ไฟล์"
                              : ""
                            : ""}
                        </Title>
                      </div>
                    </div>
                    <Title
                      level={5}
                      className={"font_Light"}
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: 14,
                        marginTop: 5,
                      }}
                    >
                      {chatroom[0][item.chatroomId]
                        ? chatroom[0][item.chatroomId].messages
                          ? Moment(
                              chatroom[0][item.chatroomId].messages[
                                chatroom[0][item.chatroomId].messages.length - 1
                              ].createdAt
                            )
                              .endOf("minute")
                              .fromNow()
                          : ""
                        : ""}
                    </Title>
                  </div>
                  <img
                    src={line}
                    style={{
                      padding: 0,
                      margin: 0,
                      height: 0.2,
                      width: "100%",
                    }}
                  />
                </div>
              </>
            );
          })}
      </div>
    </>
  );
}
