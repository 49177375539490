import * as actions from "../actionTypes";
import Actions from "../actions";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";
import { call, put, delay } from "redux-saga/effects";

export function* sagaClientVerify({ payload, showFormSuccess, showFormError }) {
  try {
    yield put(Actions.clientVerifyFetching());

    const response = yield call(
      httpClient.post,
      server.CLIENT_VERIFY_URL,
      payload
    );

    if (response.status == 200 && response.data.data) {
      yield put(Actions.clientVerifySuccess(response.data.data));
      showFormSuccess();
    }
  } catch (error) {
    yield put(Actions.clientVerifyFailed());
    showFormError();
  }
}
