import styles from "../../styles/Verify.module.css";
import { Col, Row } from "antd";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { DatePicker } from "antd";
import Select from "react-select";
import { Field, Form, Formik } from "formik";
import { Button, Space } from "antd";
import TextInput from "../component/TextInput";
import { Typography } from "antd";
import { Checkbox } from "antd";
import TextArea from "../component/TextArea";
import actions from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import TextInputPassword from "../component/TextInputPassword";
import Swal from "sweetalert2";

import line2 from "../../assets/images/line2.svg";
import line from "../../assets/images/line.png";
import user_upload from "../../assets/images/user_upload.png";
import uploadImage from "../../assets/images/uploadImage.svg";
import lightbulb from "../../assets/images/lightbulb.svg";
import sun from "../../assets/images/sun.svg";
import glasses from "../../assets/images/glasses.svg";
import house from "../../assets/images/house.svg";
import information from "../../assets/images/information.png";
import dollar from "../../assets/images/dollar.svg";
import dot from "../../assets/images/dot.svg";
import Script from "react-load-script";
import axios from "axios";
import CreditCard from "../component/CreditCard";

const options = [{ value: "test", label: "test" }];

const make = [{ value: "test", label: "test" }];
const model = [{ value: "test", label: "test" }];
const odometer = [
  { value: "0-1000", label: "0-1000" },
  { value: "1001-10000", label: "1001-10000" },
  { value: "10001-100000", label: "10001-100000" },
];
const transmission = [{ value: "test", label: "test" }];
const trim = [{ value: "test", label: "test" }];
const style = [{ value: "test", label: "test" }];
const country = [{ value: "test", label: "test" }];
const state = [{ value: "test", label: "test" }];

const { Title, Text } = Typography;
const { MonthPicker, RangePicker, WeekPicker, YearPicker } = DatePicker;

let OmiseCard;

const CustomInputStart = forwardRef(({ value, onClick }, ref) => (
  <button
    style={{
      color: "#000",
      border: "2px solid #939090",
      fontSize: 16,
      fontWeight: 600,
      borderRadius: 5,
      width: 200,
      height: 40,
    }}
    className="ms-3"
    onClick={onClick}
    ref={ref}
  >
    {value}
  </button>
));

export default function Verify() {
  const dispatch = useDispatch();

  const photoRef = useRef(null);
  const mybookRef = useRef(null);
  const CarphotosRef = useRef(null);

  const [data, setData] = useState({});

  const [salvage_title, setSalvage_title] = useState(false);
  const [get_trip_updates, setGet_trip_updates] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [carFeatures, setCarfeatures] = useState(null);
  const [DocId, setDocId] = useState(null);
  const [PaymentId, setPaymentId] = useState(null);

  const getDataVerifyHostReducer = useSelector(
    ({ getDataVerifyHostReducer }) => getDataVerifyHostReducer
  );

  const createVerifyCardReducer = useSelector(
    ({ createVerifyCardReducer }) => createVerifyCardReducer
  );

  useEffect(() => {
    dispatch(actions.getDataVerifyHost());
  }, []);

  const handleLoadScript = () => {
    OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      publicKey: process.env.REACT_APP_OMISE_PUBLIC_KEY,
      currency: "THB",
      frameLabel: "Borntodev Shop",
      submitLabel: "Pay NOW",
      buttonLabel: "Pay with Omise",
    });
  };

  const creditCardConfigure = () => {
    OmiseCard.configure({
      defaultPaymentMethod: "credit_card",
      otherPaymentMethods: [],
    });
    OmiseCard.configureButton("#credit-card");
    OmiseCard.attach();
  };

  const handleClick = (values) => {
    creditCardConfigure();

    let payload = {
      ...values,
      ...data,
      get_trip_updates,
      approved: false,
    };

    const tokenParameters = {
      // city: payload.billing_city,
      email: payload.email,
      // country: payload.billing_country ? payload.billing_country : "",
      // postal_code: payload.billing_zip_code,
      // state: payload.billing_state ? payload.billing_state : "",
      // street1: payload.billing_street_address,
      expiration_month: payload.expiration_date.split("/")[0],
      expiration_year: payload.expiration_date.split("/")[1], // 2/2023
      name: payload.name_on_card,
      number: payload.credit_card_number,
      phone_number: payload.phone_number,
      security_code: payload.security_code,
    };

    OmiseCard.createToken(
      "card",
      tokenParameters,
      function (statusCode, response) {
        console.log(response);
        console.log("tokenParameters", tokenParameters);

        dispatch(
          actions.createVerifyCard(
            {
              uid: "1",
              email: tokenParameters.email,
              name: tokenParameters.name,
              amount: "2000",
              omiseToken: response.id,
              orderReceivedUrl:
                "https://97cd-2001-fb1-11c-e854-c99-21aa-db6-d086.ap.ngrok.io/api/v1/omise/webhook",
            },
            showFormVerifyCardSuccess
          )
        );
      }
    );
  };

  const showFormError = () => {
    Swal.fire({
      position: "center",
      icon: "error",
      customClass: "font_Light",
      title: "Error",
      text: "",
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {});
  };

  const showFormSuccess = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      customClass: "font_Light",
      title: "Success",
      text: "",
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {});
  };

  const showFormVerifyCardSuccess = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      customClass: "font_Light",
      title: "Verify Card Success",
      text: "",
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {});
  };

  const showForm = ({
    errors,
    touched,
    dirty,
    isValid,
    handleSubmit,
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    resetForm,
  }) => {
    return (
      <Form className={[styles.container_verify]}>
        <Script url="https://cdn.omise.co/omise.js" onLoad={handleLoadScript} />
        <div
          style={{
            maxWidth: 420,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Title
            level={1}
            className={styles.font_Medium}
            style={{ marginBottom: 0 }}
          >
            Get approved to drive
          </Title>
          <img src={line} width={"100%"} height={4} />
        </div>
        {/* Your car */}
        <div>
          <div>
            <Title level={5} style={{ marginTop: 15 }}>
              Since this is your first trip, you’ll need to provide us with some
              information before you can check out.
            </Title>
          </div>
          <Title
            level={4}
            className={[styles.font_Light]}
            style={{ marginTop: 20 }}
          >
            Verify your email
          </Title>
          <Row>
            <Col md={12}>
              <div className="pt-2 text-capitalize">
                <Title
                  level={5}
                  className={[styles.font_Light]}
                  style={{ marginTop: 15 }}
                >
                  Email
                </Title>
                <Field
                  className={styles.tmp_input}
                  id="email"
                  name="email"
                  component={TextInput}
                  onChange={handleChange}
                  value={values.email}
                  onBlur={handleBlur}
                  placeholder=""
                  aria-describedby="passwordHelpBlock"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="pt-2 text-capitalize">
                <Title
                  level={5}
                  className={[styles.font_Light]}
                  style={{ marginTop: 15 }}
                >
                  Password
                </Title>
                <Field
                  className={styles.tmp_input}
                  id="password"
                  name="password"
                  component={TextInputPassword}
                  onChange={handleChange}
                  value={values.password}
                  onBlur={handleBlur}
                  placeholder=""
                  aria-describedby="passwordHelpBlock"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="pt-2 text-capitalize">
                <Title
                  level={5}
                  className={[styles.font_Light]}
                  style={{ marginTop: 15 }}
                >
                  Confirm password
                </Title>
                <Field
                  className={styles.tmp_input}
                  id="confirm_password"
                  name="confirm_password"
                  component={TextInputPassword}
                  onChange={handleChange}
                  value={values.confirm_password}
                  onBlur={handleBlur}
                  placeholder=""
                  aria-describedby="passwordHelpBlock"
                />
              </div>
            </Col>
          </Row>
          <div style={{ paddingTop: 5 }}>
            {/* <Form.Check
            inline
            className={[styles.font_Light]}
            style={{ fontWeight: 500, fontSize: 14 }}
            label="My car has never had a branded or salvage title."
            name="group1"
            type={"checkbox"}
          /> */}
          </div>
        </div>
        {/* line */}
        <img
          src={line2}
          className={styles.line}
          style={{ marginBottom: 20, marginTop: 20 }}
        />
        {/* Profile */}
        <div>
          <div>
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 0 }}
            >
              Profile
            </Title>
          </div>
          <div>
            <Text className={[styles.font_Light]} style={{ fontSize: 14 }}>
              Adding a photo helps hosts and guests recognize each other when
              picking up the car.
            </Text>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {values.file_obj_profile ? (
                    <>
                      <img
                        src={`${values.file_obj_profile}`}
                        width="200"
                        className="m-3"
                        alt="product"
                      />
                    </>
                  ) : (
                    <>
                      <img src={user_upload} width={80} height={80} />
                    </>
                  )}

                  <Title
                    level={5}
                    className={[styles.font_Medium]}
                    style={{
                      color: "#939090",
                      marginTop: 10,
                      fontWeight: 500,
                    }}
                  >
                    Profile photo
                  </Title>
                </div>
                <div style={{ display: "flex", marginTop: 20 }}>
                  <input
                    ref={photoRef}
                    style={{ display: "none" }}
                    type="file"
                    id="file"
                    name="image"
                    onChange={(e) => {
                      setFieldValue("file_profile", e.target.files[0]);
                      setFieldValue(
                        "file_obj_profile",
                        URL.createObjectURL(e.target.files[0])
                      );
                    }}
                  />
                  <Button
                    className={[styles.button_upload]}
                    style={{
                      backgroundColor: "#4E6AFF",
                      borderColor: "#4E6AFF",
                      borderWidth: 1,
                      padding: 10,
                      height: 40,
                      color: "white",
                      borderStyle: "solid",
                      fontFamily: "Prompt-Light",
                      fontWeight: 600,
                    }}
                  >
                    Use Facebook photo
                  </Button>
                  <Button
                    className={[styles.button_upload]}
                    onClick={() => {
                      photoRef.current.click();
                    }}
                    style={{
                      backgroundColor: "white",
                      marginLeft: 10,
                      borderColor: "#939090",
                      borderWidth: 1,
                      borderStyle: "solid",
                      fontFamily: "Prompt-Light",
                      color: "#939090",
                      fontWeight: 600,
                      height: 40,
                    }}
                  >
                    Upload photo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* line */}
        <img src={line2} className={styles.line} />
        {/* Mobile number */}
        <div>
          <div>
            <Title level={4} className={[styles.font_Light]}>
              Mobile number
            </Title>
          </div>
          <div>
            <Title
              className={[styles.font_Light]}
              style={{ fontSize: 14, marginBottom: 0 }}
            >
              Enter your mobile number and we’ll text you a verification code.
            </Title>
          </div>
          <Row>
            <Col md={8} xs={24} style={{ paddingRight: 10 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 20 }}
              >
                Country
              </Title>
              <Select
                options={country}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    country: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              />
            </Col>

            <Col md={8} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 20 }}
              >
                Phone number
              </Title>
              <Field
                className={styles.tmp_input}
                id="phone_number"
                style={{ padding: 7 }}
                name="phone_number"
                component={TextInput}
                onChange={handleChange}
                value={values.phone_number}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>
          </Row>
          <div style={{ marginTop: 15 }}>
            <Checkbox
              onChange={() => {
                setGet_trip_updates((e) => !e);
              }}
            >
              <Text>Get trip updates via text</Text>
            </Checkbox>
          </div>
        </div>
        {/* line */}
        <div className="mt-md-3 mb-md-4 mt-1 mb-1">
          <img
            src={line2}
            style={{ marginTop: 20, marginBottom: 1 }}
            className={styles.line}
          />
        </div>
        {/* ID Driving */}
        <div>
          <div>
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 10 }}
            >
              ID Driving
            </Title>
            <div>
              <Title
                className={[styles.font_Light]}
                style={{ fontSize: 14, marginBottom: 10, marginTop: 15 }}
              >
                Lorem ipsum is placeholder text commonly used in the graphic
              </Title>
            </div>
          </div>

          <Row>
            <Col md={8} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 15 }}
              >
                Name
              </Title>

              <Field
                className={styles.tmp_input}
                id="name"
                name="name"
                style={{ padding: 7 }}
                component={TextInput}
                onChange={handleChange}
                value={values.name}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>
            <Col md={8} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 15 }}
              >
                ID No.
              </Title>

              <Field
                className={styles.tmp_input}
                id="id_no"
                style={{ padding: 7 }}
                name="id_no"
                component={TextInput}
                onChange={handleChange}
                value={values.id_no}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>
          </Row>
          <Title
            level={5}
            className={[styles.font_Light]}
            style={{ marginTop: 15 }}
          >
            Expiration date
          </Title>
          <Row>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#939090",
                }),
              }}
              placeholder="Month"
            /> */}

              <MonthPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_id_expiration_date_month}
                format="MMM"
                mode="month"
                placeholder="Month"
                onChange={(date) => {
                  console.log(new Date(date).getTime());
                  setData((data) => ({
                    ...data,
                    driver_id_expiration_date_month: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />

              {/* onChange={(e) => {
                setData((data) => ({
                  ...data,
                  issuing_country: e.value,
                }));
              }} */}
            </Col>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#939090",
                }),
              }}
              placeholder="Day"
            /> */}
              <DatePicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_id_expiration_date_day}
                format="DD"
                mode="date"
                placeholder="Day"
                onChange={(date) => {
                  console.log(new Date(date).getTime());
                  setData((data) => ({
                    ...data,
                    driver_id_expiration_date_day: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
            <Col md={6} xs={8}>
              {/* <Select
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#939090",
                }),
              }}
              placeholder="Year"
            /> */}

              <YearPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_id_expiration_date_year}
                format="YYYY"
                mode="year"
                placeholder="Year"
                onChange={(date) => {
                  setData((data) => ({
                    ...data,
                    driver_id_expiration_date_year: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
          </Row>

          <Title
            level={5}
            className={[styles.font_Light]}
            style={{ marginTop: 15 }}
          >
            Date of birth
          </Title>
          <Row>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#939090",
                }),
              }}
              placeholder="Month"
            /> */}

              <MonthPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_id_date_of_birth_month}
                format="MMM"
                mode="month"
                placeholder="Month"
                onChange={(date) => {
                  console.log(new Date(date).getTime());
                  setData((data) => ({
                    ...data,
                    driver_id_date_of_birth_month: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#939090",
                }),
              }}
              placeholder="Day"
            /> */}

              <DatePicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_id_date_of_birth_day}
                format="DD"
                mode="date"
                placeholder="Day"
                onChange={(date) => {
                  console.log(new Date(date).getTime());
                  setData((data) => ({
                    ...data,
                    driver_id_date_of_birth_day: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
            <Col md={6} xs={8}>
              <YearPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_id_date_of_birth_year}
                format="YYYY"
                mode="year"
                placeholder="Year"
                onChange={(date) => {
                  setData((data) => ({
                    ...data,
                    driver_id_date_of_birth_year: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
              {/* <Select
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#939090",
                }),
              }}
              placeholder="Year"
            /> */}
            </Col>
          </Row>

          <div>
            <Title level={5} className={[styles.font_Light]}>
              My Photo
            </Title>

            <div
              className={[styles.uploadImage]}
              onClick={() => {
                mybookRef.current.click();
              }}
            >
              {values.file_obj_photo ? (
                <>
                  <img
                    src={values.file_obj_photo}
                    width={"100%"}
                    height={"100%"}
                    style={{ objectFit: "cover" }}
                  />
                </>
              ) : (
                <>
                  <img src={uploadImage} width={"15%"} />
                </>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <input
                ref={mybookRef}
                style={{ display: "none" }}
                type="file"
                id="file"
                name="image"
                onChange={(e) => {
                  setFieldValue("file_photo", e.target.files[0]);
                  setFieldValue(
                    "file_obj_photo",
                    URL.createObjectURL(e.target.files[0])
                  );
                }}
              />
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  color: "#939090",
                  fontWeight: 500,
                }}
              >
                Add Photo
              </Title>
            </div>
          </div>
        </div>
        {/* line */}
        <img
          src={line2}
          className={styles.line}
          style={{ marginTop: 15, marginBottom: 15 }}
        />
        {/* Driver’s license */}

        <div>
          <div>
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 10 }}
            >
              Driver’s license
            </Title>
            <div>
              <Title
                className={[styles.font_Light]}
                style={{ fontSize: 14, marginBottom: 10, marginTop: 15 }}
              >
                Enter your info exactly as it appears on your license so Carrent
                can verify your eligibility to drive.
              </Title>
              <Title
                className={[styles.font_Light]}
                style={{ fontSize: 14, marginBottom: 10, marginTop: 15 }}
              >
                Hosts see your name and date of birth after you book a trip; the
                rest stays private.
              </Title>
            </div>
          </div>

          <Row>
            <Col md={8} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Issuing country
              </Title>
              <Select
                options={country}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    issuing_country: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              />
            </Col>

            <Col md={8} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                License number
              </Title>

              <Field
                className={styles.tmp_input}
                id="license_number"
                style={{ padding: 7 }}
                name="license_number"
                component={TextInput}
                onChange={handleChange}
                value={values.license_number}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>

            <div>
              <Title
                className={[styles.font_Light]}
                style={{ fontSize: 14, marginTop: 15 }}
              >
                Enter your name exactly as it appears on your driver's license
              </Title>
            </div>
          </Row>

          <Row>
            <Col md={8} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 15 }}
              >
                First name
              </Title>

              <Field
                className={styles.tmp_input}
                id="fistname"
                name="fistname"
                style={{ padding: 7 }}
                component={TextInput}
                onChange={handleChange}
                value={values.firstname}
                onBlur={handleBlur}
                placeholder="First name"
                aria-describedby="passwordHelpBlock"
              />
            </Col>
            <Col md={8} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 15 }}
              >
                Last name
              </Title>

              <Field
                className={styles.tmp_input}
                id="lastname"
                style={{ padding: 7 }}
                name="lastname"
                component={TextInput}
                onChange={handleChange}
                value={values.lastname}
                onBlur={handleBlur}
                placeholder="Last name"
                aria-describedby="passwordHelpBlock"
              />
            </Col>
          </Row>
          <Title
            level={5}
            className={[styles.font_Light]}
            style={{ marginTop: 15 }}
          >
            Expiration date
          </Title>
          <Row>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#939090",
                }),
              }}
              placeholder="Month"
            /> */}

              <MonthPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_license_expiration_date_month}
                format="MMM"
                mode="month"
                placeholder="Month"
                onChange={(date) => {
                  setData((data) => ({
                    ...data,
                    driver_license_expiration_date_month: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />

              {/* onChange={(e) => {
                setData((data) => ({
                  ...data,
                  issuing_country: e.value,
                }));
              }} */}
            </Col>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#939090",
                }),
              }}
              placeholder="Day"
            /> */}
              <DatePicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_license_expiration_date_day}
                format="DD"
                mode="date"
                placeholder="Day"
                onChange={(date) => {
                  console.log(new Date(date).getTime());
                  setData((data) => ({
                    ...data,
                    driver_license_expiration_date_day: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
            <Col md={6} xs={8}>
              {/* <Select
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#939090",
                }),
              }}
              placeholder="Year"
            /> */}

              <YearPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_license_expiration_date_year}
                format="YYYY"
                mode="year"
                placeholder="Year"
                onChange={(date) => {
                  setData((data) => ({
                    ...data,
                    driver_license_expiration_date_year: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
          </Row>

          <Title
            level={5}
            className={[styles.font_Light]}
            style={{ marginTop: 15 }}
          >
            Date of birth
          </Title>
          <Row>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#939090",
                }),
              }}
              placeholder="Month"
            /> */}

              <MonthPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_license_date_of_birth_month}
                format="MMM"
                mode="month"
                placeholder="Month"
                onChange={(date) => {
                  console.log(new Date(date).getTime());
                  setData((data) => ({
                    ...data,
                    driver_license_date_of_birth_month: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
            <Col md={6} xs={8} style={{ paddingRight: 15 }}>
              {/* <Select
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#939090",
                }),
              }}
              placeholder="Day"
            /> */}

              <DatePicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_license_date_of_birth_day}
                format="DD"
                mode="date"
                placeholder="Day"
                onChange={(date) => {
                  console.log(new Date(date).getTime());
                  setData((data) => ({
                    ...data,
                    driver_license_date_of_birth_day: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
            </Col>
            <Col md={6} xs={8}>
              <YearPicker
                style={{ padding: 7, width: "100%" }}
                className={[styles.tmp_input]}
                value={data.driver_license_date_of_birth_year}
                format="YYYY"
                mode="year"
                placeholder="Year"
                onChange={(date) => {
                  setData((data) => ({
                    ...data,
                    driver_license_date_of_birth_year: date,
                  }));
                }}
                customInput={<CustomInputStart />}
              />
              {/* <Select
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#939090",
                }),
              }}
              placeholder="Year"
            /> */}
            </Col>
          </Row>

          <div style={{ display: "flex", marginTop: 10 }}>
            <Title className={[styles.font_Light]} style={{ fontSize: 14 }}>
              <span
                className={[styles.font_Light]}
                style={{
                  fontSize: 14,
                }}
              >
                By clicking Next below, you agree that Carrent may collect your
              </span>
              <a
                className={[styles.font_Light]}
                style={{
                  fontSize: 14,
                  textDecorationLine: "underline",
                  marginLeft: 7,
                  color: "#27B1FF",
                }}
              >
                auto insurance score.
              </a>
            </Title>
          </div>
        </div>

        <img
          src={line2}
          className={styles.line}
          style={{ marginTop: 15, marginBottom: 10 }}
        />
        {/* Your goals */}
        <Col md={18} xs={24}>
          <div>
            <Title
              level={5}
              className={[styles.font_Light]}
              style={{ marginTop: 15 }}
            >
              Payment method
            </Title>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 15,
              marginLeft: 15,
            }}
          >
            <img src={information} width={20} />
            <Text
              level={4}
              className={[styles.font_Light]}
              style={{ marginLeft: 10 }}
            >
              Payment method
            </Text>
          </div>

          <div>
            <Title
              level={5}
              className={[styles.font_Light]}
              style={{ marginTop: 15 }}
            >
              Card information
            </Title>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 15,
              marginLeft: 15,
            }}
          >
            <img src={information} width={20} />
            <Text
              level={4}
              className={[styles.font_Light]}
              style={{ marginLeft: 10 }}
            >
              Your information will be stored securely.
            </Text>
          </div>

          {/* <Row>
            <Col md={12} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Name on card
              </Title>
              <Field
                className={styles.tmp_input}
                id="name_on_card"
                style={{ padding: 7 }}
                name="name_on_card"
                component={TextInput}
                onChange={handleChange}
                value={values.name_on_card}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>

            <Col md={12} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Credit card number
              </Title>

              <Field
                className={styles.tmp_input}
                id="credit_card_number"
                style={{ padding: 7 }}
                name="credit_card_number"
                component={TextInput}
                onChange={handleChange}
                value={values.credit_card_number}
                onBlur={handleBlur}
                placeholder="0000 0000 0000 0000"
                aria-describedby="passwordHelpBlock"
              />
            </Col>
          </Row>

          <Row>
            <Col md={12} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Expiration date
              </Title>
              <Field
                className={styles.tmp_input}
                id="expiration_date"
                style={{ padding: 7 }}
                name="expiration_date"
                component={TextInput}
                onChange={handleChange}
                value={values.expiration_date}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>

            <Col md={12} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Security code
              </Title>

              <Field
                className={styles.tmp_input}
                id="security_code"
                style={{ padding: 7 }}
                name="security_code"
                component={TextInput}
                onChange={handleChange}
                value={values.security_code}
                onBlur={handleBlur}
                placeholder="000"
                aria-describedby="passwordHelpBlock"
              />
            </Col>
          </Row> */}
        </Col>
        {/* <Button
          onClick={() => {
            handleClick(values);
          }}
          className={[styles.tmp_button, "mt-4"]}
          style={{ marginTop: 10 }}
        >
          <Text
            style={{
              color: "white",
            }}
          >
            Verify Card
          </Text>
        </Button> */}

        <CreditCard values={values} />

        {/* Verify Card */}

        <img
          src={line2}
          className={styles.line}
          style={{ marginTop: 30, marginBottom: 15 }}
        />

        {/* Billing address */}

        <div>
          <div>
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 10 }}
            >
              Billing address
            </Title>
          </div>

          <Row>
            <Col md={18} style={{ width: "100%" }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Street address
              </Title>

              <Field
                className={styles.tmp_input}
                id="billing_street_address"
                style={{ padding: 7 }}
                name="billing_street_address"
                component={TextInput}
                onChange={handleChange}
                value={values.billing_street_address}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>
          </Row>
          <Row>
            <Col md={9} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                City
              </Title>

              <Field
                className={styles.tmp_input}
                id="billing_city"
                style={{ padding: 7 }}
                name="billing_city"
                component={TextInput}
                onChange={handleChange}
                value={values.billing_city}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>
            <Col md={9} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                State
              </Title>
              <Select
                options={state}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    billing_state: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              />
            </Col>
          </Row>

          <Row>
            <Col md={9} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Zip Code
              </Title>

              <Field
                className={styles.tmp_input}
                id="billing_zip_code"
                style={{ padding: 7 }}
                name="billing_zip_code"
                component={TextInput}
                onChange={handleChange}
                value={values.billing_zip_code}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>
            <Col md={9} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Country
              </Title>
              <Select
                options={country}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    billing_country: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              />
            </Col>
          </Row>
        </div>
        <img
          src={line2}
          className={styles.line}
          style={{ marginTop: 30, marginBottom: 15 }}
        />
        {/* Current address */}
        <div>
          <div>
            <Title
              level={4}
              className={[styles.font_Light]}
              style={{ marginTop: 10 }}
            >
              Current address
            </Title>
          </div>

          <Row>
            <Col md={18} style={{ width: "100%" }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Street address
              </Title>

              <Field
                className={styles.tmp_input}
                id="current_street_address"
                style={{ padding: 7 }}
                name="current_street_address"
                component={TextInput}
                onChange={handleChange}
                value={values.current_street_address}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>
          </Row>
          <Row>
            <Col md={9} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                City
              </Title>

              <Field
                className={styles.tmp_input}
                id="current_city"
                style={{ padding: 7 }}
                name="current_city"
                component={TextInput}
                onChange={handleChange}
                value={values.current_city}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>
            <Col md={9} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                State
              </Title>
              <Select
                options={state}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    current_state: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              />
            </Col>
          </Row>

          <Row>
            <Col md={9} xs={24} style={{ paddingRight: 15 }}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Zip Code
              </Title>

              <Field
                className={styles.tmp_input}
                id="current_zip_code"
                style={{ padding: 7 }}
                name="current_zip_code"
                component={TextInput}
                onChange={handleChange}
                value={values.current_zip_code}
                onBlur={handleBlur}
                placeholder=""
                aria-describedby="passwordHelpBlock"
              />
            </Col>
            <Col md={9} xs={24}>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{
                  marginTop: 10,
                }}
              >
                Country
              </Title>
              <Select
                options={country}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    current_country: e.value,
                  }));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#939090",
                  }),
                }}
                className={styles.tmp_select}
                placeholder="Select"
              />
            </Col>
          </Row>
        </div>
        <img
          src={line2}
          className={styles.line}
          style={{ marginTop: 30, marginBottom: 15 }}
        />
        <Button
          htmlType="submit"
          className={[styles.tmp_button, "mt-4"]}
          style={{ marginTop: 10 }}
        >
          <Text
            style={{
              color: "white",
            }}
          >
            Save and Continue
          </Text>
        </Button>
      </Form>
    );
  };

  return (
    <Formik
      validate={(values) => {
        let error = {};
        return error;
      }}
      initialValues={{}}
      onSubmit={async (values, { setSubmitting }) => {
        let formData = new FormData();
        let payload = {
          ...values,
          ...data,
          get_trip_updates,
          approved: false,
        };

        formData.append(
          "driver_license_expiration_date_day",
          new Date(payload.driver_license_expiration_date_day).getTime()
        );
        formData.append(
          "driver_license_expiration_date_month",
          new Date(payload.driver_license_expiration_date_month).getTime()
        );
        formData.append(
          "driver_license_expiration_date_year",
          new Date(payload.driver_license_expiration_date_year).getTime()
        );

        formData.append(
          "driver_license_date_of_birth_day",
          new Date(payload.driver_license_date_of_birth_day).getTime()
        );
        formData.append(
          "driver_license_date_of_birth_month",
          new Date(payload.driver_license_date_of_birth_month).getTime()
        );
        formData.append(
          "driver_license_date_of_birth_year",
          new Date(payload.driver_license_date_of_birth_year).getTime()
        );

        formData.append(
          "driver_id_expiration_date_day",
          new Date(payload.driver_id_expiration_date_day).getTime()
        );
        formData.append(
          "driver_id_expiration_date_month",
          new Date(payload.driver_id_expiration_date_month).getTime()
        );
        formData.append(
          "driver_id_expiration_date_year",
          new Date(payload.driver_id_expiration_date_year).getTime()
        );

        formData.append(
          "driver_id_date_of_birth_day",
          new Date(payload.driver_id_date_of_birth_day).getTime()
        );
        formData.append(
          "driver_id_date_of_birth_month",
          new Date(payload.driver_id_date_of_birth_month).getTime()
        );
        formData.append(
          "driver_id_date_of_birth_year",
          new Date(payload.driver_id_date_of_birth_year).getTime()
        );

        formData.append("approved", false);
        formData.append("billing_city", payload.billing_city);
        formData.append("billing_country", payload.billing_country);
        formData.append("billing_state", payload.billing_state);
        formData.append(
          "billing_street_address",
          payload.billing_street_address
        );
        formData.append("billing_zip_code", payload.billing_zip_code);
        formData.append("confirm_password", payload.confirm_password);
        formData.append("country", payload.country);
        formData.append("current_city", payload.current_city);
        formData.append("current_country", payload.current_country);
        formData.append("current_state", payload.current_state);
        formData.append(
          "current_street_address",
          payload.current_street_address
        );
        formData.append("current_zip_code", payload.current_zip_code);
        formData.append("email", payload.email);

        formData.append("fistname", payload.fistname);
        formData.append("get_trip_updates", payload.get_trip_updates);
        formData.append("id_no", payload.id_no);
        formData.append("issuing_country", payload.issuing_country);
        formData.append("lastname", payload.lastname);
        formData.append("license_number", payload.license_number);
        formData.append("name", payload.name);
        formData.append("password", payload.password);
        formData.append("phone_number", payload.phone_number);
        formData.append("name_on_card", payload.name_on_card);
        formData.append("security_code", payload.security_code);
        formData.append("credit_card_number", payload.credit_card_number);
        formData.append("expiration_date", payload.expiration_date);
        formData.append("uid", 1);
        formData.append(
          "doc_id",
          createVerifyCardReducer.result &&
            createVerifyCardReducer.result.doc_id
        );
        formData.append(
          "payment_id",
          createVerifyCardReducer.result &&
            createVerifyCardReducer.result.payment_id
        );

        formData.append(
          "result_cardId",
          createVerifyCardReducer.result &&
            createVerifyCardReducer.result.result_cardId
        );

        if (payload.file_profile) {
          formData.append("file", payload.file_profile);
        }
        if (payload.file_photo) {
          formData.append("file", payload.file_photo);
        }

        if (
          createVerifyCardReducer.result &&
          createVerifyCardReducer.result.payment_id &&
          createVerifyCardReducer.result.doc_id &&
          createVerifyCardReducer.result.result_cardId
        ) {
          dispatch(
            actions.clientVerifyRequest(
              formData,
              showFormSuccess,
              showFormError
            )
          );
        }

        // // authen
        // await axios
        //   .post(
        //     "https://mac-portal.appmanteam.com/auth/realms/mac-portal/protocol/openid-connect/token",
        //     new URLSearchParams({
        //       client_id: "sandbox-case-keeper-service-account",
        //       client_secret: "d629a6e1-9570-4c9e-a897-bad7db7184fc",
        //       grant_type: "client_credentials",
        //     })
        //   )
        //   .then(async (response) => {
        //     console.log();
        //     // KYC
        //     if (response.status == 200) {
        //       await axios
        //         .post(
        //           "https://mac-portal.appmanteam.com/api/v2/case-keeper/proprietors",
        //           {
        //             firstName: values.firstname,
        //             lastName: values.lastname,
        //             phoneNumber: values.phone_number,
        //             notifyType: "sms",
        //             frontIdCardConfig: {
        //               required: true,
        //               isEditable: true,
        //               threshHold: 0.8,
        //               dependenciesRequired: false,
        //             },
        //             backIdCardConfig: {
        //               required: true,
        //               isEditable: true,
        //               threshHold: 0.8,
        //               dependenciesRequired: false,
        //             },
        //             idFaceRecognitionConfig: {
        //               required: true,
        //               isEditable: true,
        //               threshHold: 0.8,
        //               dependenciesRequired: false,
        //             },
        //             criminalCheckConfig: {
        //               required: true,
        //             },
        //           },
        //           {
        //             headers: {
        //               Authorization: "Bearer " + response.data.access_token,
        //               "Content-Type": "application/json",
        //             },
        //           }
        //         )
        //         .then((response) => {
        //           // console.log(response);
        //         });
        //     }
        //   });

        setSubmitting(false);
      }}
    >
      {(props) => showForm(props)}
    </Formik>
  );
}
