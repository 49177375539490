import React from "react";
import { Input } from "antd";

export default function TextArea({
  field,
  label,
  name,
  id,
  value,
  form: { touched, errors },
  ...props
}) {
  const { TextArea } = Input;
  return (
    <>
      <TextArea rows={4} id={id} type="text" {...field} {...props} />
    </>
  );
}
