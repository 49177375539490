import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../styles/Inbox.module.css";
// import { Form, InputGroup } from "react-bootstrap";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import line from "../../assets/images/line.png";
import profile from "../../assets/images/profile.png";
import gallery from "../../assets/images/image-gallery.svg";
import folder1 from "../../assets/images/open-folder1.svg";
import folder2 from "../../assets/images/open-folder2.svg";
import document from "../../assets/images/document.png";
import sendMessage from "../../assets/images/sendMessage.png";
import { Avatar, Divider, Tooltip } from "antd";

// src="/assets/images/profile.png"
import {
  getDoc,
  collection,
  getDocs,
  setDoc,
  doc,
  where,
  query,
  addDoc,
} from "firebase/firestore";
import {
  getDatabase,
  get,
  ref,
  set,
  onValue,
  push,
  update,
  off,
} from "firebase/database";

import * as storageDB from "firebase/storage";
import { db, database, storage } from "../../utils/FirebaseDB";
import Moment from "moment";
export default function Read({
  setSelected,
  selected,
  message,
  setMessage,
  user,
}) {
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);

  const imageRef = useRef(null);
  const fileRef = useRef(null);

  function onClick(e) {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setText(text + emoji);
  }

  const showMessage = (message) => {
    let data = [];
    message.map((msg, index) => {
      if (
        index != 0 &&
        data[index - 1].sender &&
        data[index - 1].sender == msg.sender
      ) {
        data.push({ ...msg, showImage: false });
      } else {
        data.push({ ...msg, showImage: true });
      }
    });
    setMessage(data);
  };

  useEffect(() => {
    const dbRef2 = ref(database, `chatrooms/-N8bjGghJwkqTaFOOjPV`);

    onValue(dbRef2, async (snapshot) => {
      showMessage(snapshot.val().messages);
    });

    return () => {
      off(dbRef2);
    };
  }, []);

  const onSend = useCallback(
    async (msg = {}) => {
      const currentChatroom = await fetchMessages();
      let room = localStorage.getItem("chatroomId");
      let res = JSON.parse(room);

      if (msg.image) {
        const filename = Date.now();

        const imageRef = storageDB.ref(storage, `images/${filename}.png`);
        storageDB.uploadBytes(imageRef, msg.image).then((snapshot) => {
          storageDB.getDownloadURL(snapshot.ref).then(async (url) => {
            const lastMessages = currentChatroom.messages || [];
            const updateRef = {};
            updateRef[`chatrooms/${res}/messages`] = [
              ...lastMessages,
              {
                image: url,
                sender: user,
                createdAt: new Date(),
              },
            ];

            const lastAlert = currentChatroom[currentChatroom.secondUser] || 0;
            updateRef[`chatrooms/${res}/${currentChatroom.secondUser}`] =
              lastAlert + 1;
            showMessage([
              ...message,
              {
                image: msg.blob,
                sender: user,
                createdAt: new Date(),
              },
            ]);

            update(ref(database), updateRef);
          });
        });
      } else if (msg.file) {
        // e.target.files[0]
        const filename = Date.now();
        const last = msg.file.name.split(".")[1];
        const imageRef = storageDB.ref(
          storage,
          `files/file${filename}.${last}`
        );
        const newMetadata = {
          contentType: msg.file.type,
        };

        storageDB
          .uploadBytes(imageRef, msg.file, newMetadata)
          .then((snapshot) => {
            storageDB.getDownloadURL(snapshot.ref).then(async (url) => {
              const lastMessages = currentChatroom.messages || [];
              const updateRef = {};

              // console.log(url);
              updateRef[`chatrooms/${res}/messages`] = [
                ...lastMessages,
                {
                  file: { url: url, filename: msg.file.name },
                  sender: user,
                  createdAt: new Date(),
                },
              ];

              // const lastAlert = currentChatroom[currentChatroom.secondUser] || 0;
              // updateRef[`chatrooms/${res}/${currentChatroom.secondUser}`] =
              //   lastAlert + 1;
              showMessage([
                ...message,
                {
                  file: { url: msg.blob, filename: msg.file.name },
                  sender: user,
                  createdAt: new Date(),
                },
              ]);

              update(ref(database), updateRef);
            });
          });
      } else {
        const lastMessages = currentChatroom.messages || [];
        const updateRef = {};
        updateRef[`chatrooms/${res}/messages`] = [
          ...lastMessages,
          {
            text: msg.text,
            sender: user,
            createdAt: new Date(),
          },
        ];

        // const lastAlert = currentChatroom[currentChatroom.secondUser] || 0;
        // updateRef[`chatrooms/${res}/${currentChatroom.secondUser}`] =
        //   lastAlert + 1;

        // ]);

        showMessage([
          ...message,
          {
            text: msg.text,
            sender: user,
            createdAt: new Date(),
          },
        ]);
        update(ref(database), updateRef);
        setShow(false);
        setText("");
      }
    },
    [user]
  );

  const fetchMessages = useCallback(async () => {
    let room = localStorage.getItem("chatroomId");
    let res = JSON.parse(room);

    const mySnapshot = await get(ref(database, `chatrooms/${res}`));
    return mySnapshot.val();
  }, []);

  return (
    <>
      {/* style=
      {{


        overflowX: "hidden",
        width: "100%",

      }} */}
      <div
        id="selected"
        style={{
          position: "relative",
          marginBottom: 50,
          maxHeight: 400,
          overflowY: "scroll",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div className="p-md-5 px-2 pt-4">
          {/* time */}
          <div
            className={"d-flex justify-content-center align-items-center"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={styles.border_time}>
              <Text
                className={"font_Light"}
                style={{
                  margin: 0,
                  padding: 10,
                  fontSize: 12,
                  color: "#939090",
                }}
              >
                11/10/2022
              </Text>
            </div>
          </div>
          <div style={{ height: "100%" }}>
            {selected && message
              ? message.map((item, index) => {
                  // console.log("item", item);
                  if (item.sender == user) {
                    return (
                      <div>
                        <div
                          className="d-flex align-items-center justify-content-end w-100"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <div
                            className="d-flex align-items-start"
                            style={{
                              display: "flex",
                              alignItems: "start",
                            }}
                          >
                            <div
                              className="d-flex flex-column pt-md-1 pt-1 me-3"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                className="d-flex justify-content-end"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Title
                                  level={5}
                                  className={"font_Medium"}
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    paddingTop: 10,
                                    fontSize: 18,
                                  }}
                                >
                                  {/* {selected ? selected.username : ""} */}
                                </Title>
                              </div>
                              {/* message */}
                              <div
                                className="d-flex justify-content-center align-items-center pt-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  paddingTop: 10,
                                }}
                              >
                                <div
                                  className="d-flex flex-column "
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className={[styles.box_message]}
                                    style={{
                                      padding: 10,
                                      textAlign: "end",
                                    }}
                                  >
                                    {/* <h6
                                          className="font_Light text-capitalize p-3 m-0"
                                          style={{ fontSize: 14, lineHeight: 1.8 }}
                                        >
                                          Lorem ipsum is placeholder text commonly used in the
                                          graphic, print, and publishing industries for previewing
                                          layouts and visual mockups.
                                        </h6> */}

                                    {item.image ? (
                                      <>
                                        <img
                                          src={item.image && item.image}
                                          width="200"
                                        />
                                      </>
                                    ) : item.file ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <a
                                          href={item.file.url}
                                          target="_blank"
                                          download={item.file.filename}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <img
                                            src={document}
                                            style={{ width: 30, height: 30 }}
                                          />
                                          <Text
                                            className={"font_Light"}
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              fontSize: 14,
                                              marginLeft: 10,
                                            }}
                                          >
                                            {item.file.filename}
                                          </Text>
                                        </a>
                                      </div>
                                    ) : (
                                      <Text
                                        className={"font_Light"}
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          fontSize: 14,
                                        }}
                                      >
                                        {item.text}
                                      </Text>
                                    )}
                                  </div>
                                  <Text
                                    className={"font_Light"}
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      paddingTop: 10,
                                      fontSize: 14,
                                      color: "#939090",
                                    }}
                                  >
                                    {Moment(item.createdAt)
                                      .endOf("minute")
                                      .fromNow()}
                                  </Text>
                                  {/* <p
                                        className="text-start font_Light mt-1"
                                        style={{
                                          textAlign: "start",
                                          color: "#939090",
                                          fontSize: 14,
                                        }}
                                      >
                                        09:30 AM
                                      </p> */}
                                </div>
                              </div>
                            </div>

                            {/* <Avatar
                            src={
                              "https://cdn.britannica.com/91/181391-050-1DA18304/cat-toes-paw-number-paws-tiger-tabby.jpg"
                            }
                            style={{ marginTop: 10 }}
                            className={styles.profile_read}
                            size={65}
                          /> */}
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <div
                          className="d-flex align-items-center justify-content-start w-100"
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="d-flex align-items-start"
                            style={{
                              display: "flex",
                              alignItems: "start",
                            }}
                          >
                            <Avatar
                              src={selected.profile}
                              className={styles.profile_read}
                              style={{ marginTop: 10 }}
                              size={65}
                            />
                            <div
                              className="d-flex flex-column pt-md-1 pt-1 ms-3"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 10,
                              }}
                            >
                              <div className="d-flex">
                                {/* <h6
                              className="font_Medium text-capitalize pt-2"
                              style={{ fontSize: 18 }}
                            ></h6> */}

                                <Title
                                  level={5}
                                  className={"font_Medium"}
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    paddingTop: 10,
                                    fontSize: 18,
                                  }}
                                >
                                  {selected.firstname}
                                </Title>
                              </div>
                              {/* message */}
                              <div
                                className="d-flex justify-content-center align-items-center pt-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  paddingTop: 10,
                                }}
                              >
                                <div
                                  className="d-flex flex-column "
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className={[styles.box_message]}
                                    style={{
                                      padding: 10,
                                    }}
                                  >
                                    {item.image ? (
                                      <>
                                        <img
                                          src={item.image && item.image}
                                          width="200"
                                        />
                                      </>
                                    ) : item.file ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <a
                                          href={item.file.url}
                                          target="_blank"
                                          download={item.file.filename}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <img
                                            src={document}
                                            style={{ width: 30, height: 30 }}
                                          />
                                          <Text
                                            className={"font_Light"}
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              fontSize: 14,
                                              marginLeft: 10,
                                            }}
                                          >
                                            {item.file.filename}
                                          </Text>
                                        </a>
                                      </div>
                                    ) : (
                                      <Text
                                        className={"font_Light"}
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          fontSize: 14,
                                        }}
                                      >
                                        {item.text}
                                      </Text>
                                    )}

                                    {/* <h6
                                  className="font_Light text-capitalize p-3 m-0"
                                  style={{ fontSize: 14, lineHeight: 1.8, padding: 10 }}
                                ></h6> */}
                                  </div>
                                  <Text
                                    className={"font_Light"}
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      paddingTop: 10,
                                      fontSize: 14,
                                      color: "#939090",
                                    }}
                                  >
                                    09:30 AM
                                  </Text>
                                  {/* <p
                                className="text-end font_Light mt-1"
                                style={{
                                  color: "#939090",
                                  fontSize: 14,
                                }}
                              >
                                09:30 AM
                              </p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              : []}
          </div>

          {/* INPUT */}
        </div>
      </div>

      <div style={{ position: "absolute", bottom: -80, width: "100%" }}>
        <div className="mt-4" style={{ marginTop: 30 }}></div>
        <div className={styles.input_inbox}>
          <div
            className="d-flex"
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              id="file-input"
              type="file"
              ref={imageRef}
              style={{ display: "none" }}
              onChange={(e) => {
                // console.log(e.target.files[0]);
                onSend({
                  image: e.target.files[0],
                  blob: URL.createObjectURL(e.target.files[0]),
                });
                // setImage(e.target.files[0]);
                // setBlob(URL.createObjectURL(e.target.files[0]));
              }}
            />
            <img
              src={gallery}
              onClick={() => {
                imageRef.current.click();
              }}
              style={{ width: 30, marginLeft: 20 }}
            />
            <input
              id="file-input-2"
              type="file"
              ref={fileRef}
              style={{ display: "none" }}
              onChange={(e) => {
                // console.log(e.target.files[0]);
                onSend({
                  file: e.target.files[0],
                  blob: URL.createObjectURL(e.target.files[0]),
                });
                // setImage(e.target.files[0]);
                // setBlob(URL.createObjectURL(e.target.files[0]));
              }}
            />
            <img
              src={folder1}
              onClick={() => {
                fileRef.current.click();
              }}
              style={{ width: 30, marginLeft: 20 }}
              className="ms-4"
            />
            <div
              className="w-100 ms-4"
              style={{ width: "100%", marginLeft: 20 }}
            >
              <input
                className={styles.input_search}
                placeholder=""
                value={text}
                onChange={(e) => setText(e.target.value)}
                style={{ width: "100%", paddingLeft: 10 }}
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            {/* <Emoji unified={selectedEmoji} size={22} /> */}
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={sendMessage}
                  onClick={() => {
                    if (text != "") {
                      onSend({ text: text });
                    }
                  }}
                  style={{ width: 30, marginLeft: 40 }}
                  className="ms-4"
                />
              </div>
              <div style={{ display: "flex" }}>
                <img
                  src={folder2}
                  onClick={() => {
                    setShow((s) => !s);
                  }}
                  style={{ width: 30, marginLeft: 20 }}
                  className="ms-4"
                />
                {show && (
                  <div style={{ position: "absolute", top: -430, right: 0 }}>
                    <EmojiPicker
                      onEmojiClick={onClick}
                      autoFocusSearch={false}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// {/* message other */}
// <div>
//   <div
//     className="d-flex align-items-center justify-content-start w-100"
//     style={{
//       display: "flex",
//       justifyContent: "start",
//       alignItems: "center",
//     }}
//   >
//     <div
//       className="d-flex align-items-start"
//       style={{
//         display: "flex",
//         alignItems: "start",
//       }}
//     >
//       <img
//         src={profile}
//         className={styles.profile_read}
//         style={{ width: 65 }}
//       />
//       <div
//         className="d-flex flex-column pt-md-1 pt-1 ms-3"
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           paddingLeft: 10,
//         }}
//       >
//         <div className="d-flex">
//           {/* <h6
//             className="font_Medium text-capitalize pt-2"
//             style={{ fontSize: 18 }}
//           ></h6> */}

//           <Title
//             level={5}
//             className={"font_Medium"}
//             style={{
//               margin: 0,
//               padding: 0,
//               paddingTop: 10,
//               fontSize: 18,
//             }}
//           >
//             Lorem ipsum
//           </Title>
//         </div>
//         {/* message */}
//         <div
//           className="d-flex justify-content-center align-items-center pt-2"
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             paddingTop: 10,
//           }}
//         >
//           <div
//             className="d-flex flex-column "
//             style={{
//               display: "flex",
//               flexDirection: "column",
//             }}
//           >
//             <div
//               className={[styles.box_message]}
//               style={{
//                 padding: 10,
//               }}
//             >
//               <Text
//                 className={"font_Light"}
//                 style={{
//                   margin: 0,
//                   padding: 10,
//                   fontSize: 14,
//                 }}
//               >
//                 Lorem ipsum is placeholder text commonly used in the
//                 graphic, print, and publishing industries for previewing
//                 layouts and visual mockups.
//               </Text>
//               {/* <h6
//                 className="font_Light text-capitalize p-3 m-0"
//                 style={{ fontSize: 14, lineHeight: 1.8, padding: 10 }}
//               ></h6> */}
//             </div>
//             <Text
//               className={"font_Light"}
//               style={{
//                 margin: 0,
//                 padding: 0,
//                 paddingTop: 10,
//                 fontSize: 14,
//                 color: "#939090",
//               }}
//             >
//               09:30 AM
//             </Text>
//             {/* <p
//               className="text-end font_Light mt-1"
//               style={{
//                 color: "#939090",
//                 fontSize: 14,
//               }}
//             >
//               09:30 AM
//             </p> */}
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// {/* my message */}
// <div>
//   <div
//     className="d-flex align-items-center justify-content-end w-100"
//     style={{
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "end",
//     }}
//   >
//     <div
//       className="d-flex align-items-start"
//       style={{
//         display: "flex",
//         alignItems: "start",
//       }}
//     >
//       <div
//         className="d-flex flex-column pt-md-1 pt-1 me-3"
//         style={{
//           display: "flex",
//           flexDirection: "column",
//         }}
//       >
//         <div
//           className="d-flex justify-content-end"
//           style={{
//             display: "flex",
//             justifyContent: "end",
//           }}
//         >
//           <Title
//             level={5}
//             className={"font_Medium"}
//             style={{
//               margin: 0,
//               padding: 0,
//               paddingTop: 10,
//               fontSize: 18,
//             }}
//           >
//             Lorem ipsum
//           </Title>
//         </div>
//         {/* message */}
//         <div
//           className="d-flex justify-content-center align-items-center pt-2"
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             paddingTop: 10,
//           }}
//         >
//           <div
//             className="d-flex flex-column "
//             style={{
//               display: "flex",
//               flexDirection: "column",
//             }}
//           >
//             <div
//               className={[styles.box_message]}
//               style={{
//                 padding: 10,
//               }}
//             >
//               {/* <h6
//                 className="font_Light text-capitalize p-3 m-0"
//                 style={{ fontSize: 14, lineHeight: 1.8 }}
//               >
//                 Lorem ipsum is placeholder text commonly used in the
//                 graphic, print, and publishing industries for previewing
//                 layouts and visual mockups.
//               </h6> */}

//               <Text
//                 className={"font_Light"}
//                 style={{
//                   margin: 0,
//                   padding: 10,
//                   fontSize: 14,
//                 }}
//               >
//                 Lorem ipsum is placeholder text commonly used in the
//                 graphic, print, and publishing industries for previewing
//                 layouts and visual mockups.
//               </Text>
//             </div>
//             <Text
//               className={"font_Light"}
//               style={{
//                 margin: 0,
//                 padding: 0,
//                 paddingTop: 10,
//                 fontSize: 14,
//                 color: "#939090",
//               }}
//             >
//               09:30 AM
//             </Text>
//             {/* <p
//               className="text-start font_Light mt-1"
//               style={{
//                 textAlign: "start",
//                 color: "#939090",
//                 fontSize: 14,
//               }}
//             >
//               09:30 AM
//             </p> */}
//           </div>
//         </div>
//       </div>
//       <img
//         src={profile}
//         className={styles.profile_read}
//         style={{ width: 65 }}
//       />
//     </div>
//   </div>
// </div>
