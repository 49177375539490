import getUserAction from "./getUser.action";
import getCheckMapCarAction from "./getCheckMapCar.action";
import hostVerifyAction from "./hostVerify.action";
import clientVerifyAction from "./clientVerify.action";
import getDataVerifyHostAction from "./getDataVerifyHost.action";
import searchLocationAction from "./searchLocation.action";
import getCarAction from "./getCar.action";
import getChatAction from "./getChat.action";
import postReviewAction from "./postReview.action";
import getReviewCarAction from "./getReviewCar.action";
import createTokenAction from "./createToken.action";
import getReviewClientAction from "./getReviewClient.action";
import getTicketSupportAction from "./getTicketSupport.action";
import createTicketSupportAction from "./createTicketSupport.action";
import createVerifyCardAction from "./createVerifyCard.action";
import paymentAction from "./payment.action";
import getCardByidAction from "./getCardByid.action";

export default {
  ...getCarAction,
  ...getUserAction,
  ...getCheckMapCarAction,
  ...hostVerifyAction,
  ...clientVerifyAction,
  ...getDataVerifyHostAction,
  ...searchLocationAction,
  ...getChatAction,
  ...postReviewAction,
  ...getReviewCarAction,
  ...createTokenAction,
  ...getReviewClientAction,
  ...getTicketSupportAction,
  ...createTicketSupportAction,
  ...createVerifyCardAction,
  ...paymentAction,
  ...getCardByidAction,
};
