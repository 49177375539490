import React, { useEffect, useState } from "react";
import styles from "../../styles/Reviews.module.css";
import { Avatar, Button, Tabs, Typography } from "antd";
import actions from "../../redux/actions";
import line2 from "../../assets/images/line3.png";
import car1 from "../../assets/images/car1.png";
import crown from "../../assets/images/crown.svg";
import profile1 from "../../assets/images/profile1.png";
import tyre from "../../assets/images/tyre.png";
import user from "../../assets/images/user2.png";
import car8 from "../../assets/images/car-8.png";
import star from "../../assets/images/star-3.png";

import { Rate } from "antd";
import { Field, Form, Formik } from "formik";
import TabPane from "antd/es/tabs/TabPane";
import { useDispatch, useSelector } from "react-redux";
import CommentReviewClient from "../component/CommentReviewClient";

export default function Profile() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getReviewClient(0));
  }, []);

  const getReviewClientReducer = useSelector(
    ({ getReviewClientReducer }) => getReviewClientReducer
  );

  const { Title, Text } = Typography;

  return (
    <div className={[styles.container_Reviews]}>
      <div style={{ display: "flex" }}>
        <Avatar src={profile1} style={{ width: 130, height: 130 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 30,
            marginTop: 10,
            width: "100%",
            maxWidth: 400,
          }}
        >
          <Title
            level={3}
            className="font_Light"
            style={{ margin: 0, color: "#000" }}
          >
            Svetiana Anyukova
          </Title>
          <Title
            level={5}
            className="font_Light"
            style={{ margin: 0, color: "#000", marginTop: 5 }}
          >
            joined oct 2022
          </Title>

          <div style={{ marginTop: 15 }}>
            <Text
              className="font_Medium"
              style={{
                margin: 0,
                color: "#939090",
                fontSize: 16,
              }}
            >
              VERIFIED INFO
            </Text>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <Text
                className="font_Medium"
                style={{ margin: 0, color: "#000", fontSize: 16 }}
              >
                Approved to drive
              </Text>

              <div style={{ display: "flex", width: 170 }}>
                <Text
                  className="font_Medium"
                  style={{ margin: 0, color: "#27B1FF", fontSize: 16 }}
                >
                  Verify ID
                </Text>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 7,
              }}
            >
              <Text
                className="font_Medium"
                style={{ margin: 0, color: "#000", fontSize: 16 }}
              >
                Email address
              </Text>
              <div style={{ display: "flex", width: 170 }}>
                <Text
                  className="font_Medium"
                  style={{ margin: 0, color: "#27B1FF", fontSize: 16 }}
                >
                  carrental@gmail.com
                </Text>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 7,
              }}
            >
              <Text
                className="font_Medium"
                style={{ margin: 0, color: "#000", fontSize: 16 }}
              >
                Phone number
              </Text>
              <div style={{ display: "flex", width: 170 }}>
                <Text
                  className="font_Medium"
                  style={{ margin: 0, color: "#27B1FF", fontSize: 16 }}
                >
                  +66 XX XXX XXXX
                </Text>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 7,
              }}
            >
              <Text
                className="font_Medium"
                style={{ margin: 0, color: "#000", fontSize: 16 }}
              >
                Facebook
              </Text>
              <div style={{ display: "flex", width: 170 }}>
                <Text
                  className="font_Medium"
                  style={{ margin: 0, color: "#27B1FF", fontSize: 16 }}
                >
                  carrental@gmail.com
                </Text>
              </div>
            </div>

            <div style={{ marginTop: 15 }}>
              <Text
                className="font_Medium"
                style={{
                  margin: 0,
                  color: "#939090",
                  fontSize: 12,
                }}
              >
                Build trust with other users on Turo by verifying your contact
                informaton
              </Text>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginLeft: 70,
            marginTop: 0,
          }}
        >
          <Title
            level={5}
            className="font_Light"
            style={{ margin: 0, color: "#939090" }}
          >
            REVIEWS FROM HOSTS
          </Title>
          {getReviewClientReducer.result &&
          getReviewClientReducer.result.length > 0 ? (
            getReviewClientReducer.result.map((item) => (
              <CommentReviewClient item={item} />
            ))
          ) : (
            <div
              style={{
                marginTop: 10,
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                }}
              >
                <img src={user} width={50} height={50} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 15,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      src={star}
                      width={15}
                      height={15}
                      style={{ marginRight: 5 }}
                    />
                    <img
                      src={star}
                      width={15}
                      height={15}
                      style={{ marginRight: 5 }}
                    />
                    <img
                      src={star}
                      width={15}
                      height={15}
                      style={{ marginRight: 5 }}
                    />
                    <img
                      src={star}
                      width={15}
                      height={15}
                      style={{ marginRight: 5 }}
                    />
                    <img
                      src={star}
                      width={15}
                      height={15}
                      style={{ marginRight: 5 }}
                    />
                  </div>
                  <Text
                    className="font_Light"
                    style={{ margin: 0, color: "#939090", marginTop: 10 }}
                  >
                    No reviews yet
                  </Text>
                  <Text
                    className="font_Light"
                    style={{ margin: 0, color: "#939090", marginTop: 5 }}
                  >
                    ktc hasn’t received a review on Turo yet.
                  </Text>
                </div>
              </div>

              {/* star */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
