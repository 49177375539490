import React, { useEffect, useState } from "react";
import styles from "../../styles/Reviews.module.css";
// import Nav from "react-bootstrap/Nav";
import { Button, Rate, Tabs } from "antd";
// import { Button, Card, Form } from "react-bootstrap";
import { Typography } from "antd";
import crown from "../../assets/images/crown.svg";
import { Avatar, Divider, Tooltip } from "antd";
import Moment from "moment";

export default function CommentReviewClient({ item }) {
  const { Title, Text } = Typography;

  return (
    <div
      style={{
        marginTop: 10,
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          marginTop: 10,
          display: "flex",
        }}
      >
        <Avatar
          src={item.user.profile}
          style={{ width: 50, height: 50 }}
          size={50}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 15,
          }}
        >
          <div style={{ display: "flex" }}>
            <Rate disabled defaultValue={item.star} />
          </div>
          <Text
            className="font_Light"
            style={{ margin: 0, color: "#939090", marginTop: 10 }}
          >
            {item.description}
          </Text>
        </div>
      </div>

      {/* star */}
    </div>
  );
}
