import React, { forwardRef, useEffect, useRef, useState } from "react";
import styles from "../../styles/Reviews.module.css";
import { Avatar, Button, Tabs, Typography } from "antd";
import actions from "../../redux/actions";
import line2 from "../../assets/images/line3.png";
import car1 from "../../assets/images/car1.png";
import crown from "../../assets/images/crown.svg";
import profile1 from "../../assets/images/profile1.png";
import tyre from "../../assets/images/tyre.png";
import user from "../../assets/images/user2.png";
import car8 from "../../assets/images/car-8.png";
import star from "../../assets/images/star-3.png";
import Select from "react-select";
import { Rate } from "antd";
import { Field, Form, Formik } from "formik";
import TabPane from "antd/es/tabs/TabPane";
import { useDispatch, useSelector } from "react-redux";
import CommentReviewClient from "../component/CommentReviewClient";
import { Col, Row } from "antd";
import Swal from "sweetalert2";
import TextInput from "../component/TextInput";
import TextArea from "../component/TextArea";
import { useNavigate } from "react-router-dom";

const CustomInputStart = forwardRef(({ value, onClick }, ref) => (
  <button
    style={{
      color: "#000",
      border: "2px solid #939090",
      fontSize: 16,
      fontWeight: 600,
      borderRadius: 5,
      width: 200,
      height: 40,
    }}
    className="ms-3"
    onClick={onClick}
    ref={ref}
  >
    {value}
  </button>
));

const customStyles = {
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused ? "#fff" : isSelected ? "#fefefe" : undefined,
    color: "black",
    zIndex: 1,
  }),
};

export default function SupportTicket() {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [file, setFile] = useState(null);
  const navigation = useNavigate();
  const photoRef = useRef(null);
  useEffect(() => {
    dispatch(actions.getTicketSupport());
  }, []);

  const getTicketSupportReducer = useSelector(
    ({ getTicketSupportReducer }) => getTicketSupportReducer
  );

  const showFormError = () => {
    Swal.fire({
      position: "center",
      icon: "error",
      customClass: "font_Light",
      title: "Error",
      text: "",
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {});
  };

  const showFormSuccess = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      customClass: "font_Light",
      title: "Success",
      text: "",
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {
      navigation("/");
    });
  };

  const { Title, Text } = Typography;

  const showForm = ({
    errors,
    touched,
    dirty,
    isValid,
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
  }) => {
    return (
      <div style={{}}>
        <Title
          level={3}
          className="font_Light"
          style={{ margin: 0, color: "#000" }}
        >
          Open a New Ticket
        </Title>
        <Row>
          <Col md={8} style={{ paddingRight: 10 }}>
            <div>
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 20 }}
              >
                Department
              </Title>
              <Select
                styles={customStyles}
                options={
                  getTicketSupportReducer.result
                    ? getTicketSupportReducer.result
                    : []
                }
                onChange={(e) => {
                  setData(e.values);
                }}
              />
            </div>
          </Col>
          <Col md={16}></Col>
          <Col md={8} style={{ paddingRight: 10 }}>
            <div>
              <div className="pt-2 text-capitalize">
                <Title
                  level={5}
                  className={[styles.font_Light]}
                  style={{ marginTop: 20 }}
                >
                  Your Email
                </Title>
                <Field
                  className={styles.tmp_input}
                  id="email"
                  name="email"
                  component={TextInput}
                  onChange={handleChange}
                  value={values.email}
                  onBlur={handleBlur}
                  placeholder="Enter Email"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
            </div>
          </Col>
          <Col md={24} style={{ paddingRight: 10 }}>
            <div>
              <div className="pt-2 text-capitalize">
                <Title
                  level={5}
                  className={[styles.font_Light]}
                  style={{ marginTop: 20 }}
                >
                  Subject
                </Title>
                <Field
                  className={styles.tmp_input}
                  id="subject"
                  name="subject"
                  component={TextInput}
                  onChange={handleChange}
                  value={values.subject}
                  onBlur={handleBlur}
                  placeholder="Enter Subject"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
            </div>
          </Col>

          <Col md={24} style={{ paddingRight: 10 }}>
            <div>
              <div className="pt-2 text-capitalize">
                <Title
                  level={5}
                  className={[styles.font_Light]}
                  style={{ marginTop: 20 }}
                >
                  Message
                </Title>
                <Field
                  className={styles.tmp_input}
                  id="message"
                  name="message"
                  component={TextArea}
                  onChange={handleChange}
                  value={values.message}
                  onBlur={handleBlur}
                  placeholder="Message"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
            </div>
          </Col>

          <div
            style={{ display: "flex", marginTop: 20, flexDirection: "column" }}
          >
            <a href={values.file_obj} target="_blank">
              <Title
                level={5}
                className={[styles.font_Light]}
                style={{ marginTop: 0 }}
              >
                {file}
              </Title>
            </a>
            <input
              ref={photoRef}
              style={{ display: "none" }}
              type="file"
              id="file"
              name="image"
              onChange={(e) => {
                setFieldValue("file", e.target.files[0]);
                setFile(e.target.files[0].name);
                setFieldValue(
                  "file_obj",
                  URL.createObjectURL(e.target.files[0])
                );
              }}
            />
            <Button
              className={[styles.button_upload]}
              onClick={() => {
                photoRef.current.click();
              }}
              style={{
                backgroundColor: "white",
                borderColor: "#939090",
                borderWidth: 1,
                borderStyle: "solid",
                fontFamily: "Prompt-Light",
                color: "#939090",
                fontWeight: 600,
                fontSize: 13,
                width: 100,
                height: 35,
              }}
            >
              Add File
            </Button>
          </div>
        </Row>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            className={[styles.button_upload]}
            onClick={handleSubmit}
            style={{
              backgroundColor: "black",
              borderColor: "#000",
              borderWidth: 1,
              borderStyle: "solid",
              fontFamily: "Prompt-Light",
              color: "#fff",
              fontWeight: 600,
              fontSize: 13,
              width: 100,
              height: 35,
            }}
          >
            Send
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={[styles.container_Reviews]}>
      <Formik
        validate={(values) => {
          let error = {};
          return error;
        }}
        initialValues={{ subject: "", message: "", email: "" }}
        onSubmit={(values, { setSubmitting }) => {
          let formData = new FormData();
          console.log(data);
          formData.append("department", data);
          formData.append("subject", values.subject);
          formData.append("message", values.message);
          formData.append("email", values.email);

          if (file) {
            formData.append("file", values.file);
          }

          dispatch(
            actions.createTicketSupportRequest(formData, showFormSuccess)
          );

          setSubmitting(false);
        }}
      >
        {(props) => showForm(props)}
      </Formik>
    </div>
  );
}
