import { combineReducers } from "redux";
import getUserReducer from "./getUser.reducer";
import getCheckMapCarReducer from "./getCheckMapCar.reducer";
import hostVerifyReducer from "./hostVerify.reducer";
import clientVerifyReducer from "./clientVerify.reducer";
import getDataVerifyHostReducer from "./getDataVerifyHost.reducer";
import searchLoactionReducer from "./searchLoaction.reducer";
import getCarReducer from "./getCar.reducer";
import getChatReducer from "./getChat.reducer";
import postReviewReducer from "./postReview.reducer";
import getReviewCarReducer from "./getReviewCar.reducer";
import getReviewClientReducer from "./getReviewClient.reducer";
import getTicketSupportReducer from "./getTicketSupport.reducer";
import createVerifyCardReducer from "./createVerifyCard.reducer";
import getCardByidReducer from "./getCardByid.reducer";

export default combineReducers({
  getUserReducer,
  getCheckMapCarReducer,
  hostVerifyReducer,
  clientVerifyReducer,
  getDataVerifyHostReducer,
  searchLoactionReducer,
  getCarReducer,
  getChatReducer,
  postReviewReducer,
  getReviewCarReducer,
  getReviewClientReducer,
  getTicketSupportReducer,
  createVerifyCardReducer,
  getCardByidReducer,
});
