import * as actions from "../actionTypes";
import Actions from "../actions";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";
import { call, put, delay } from "redux-saga/effects";

export function* sagaCreateVerifyCard({ payload, showFormVerifyCardSuccess }) {
  try {
    const response = yield call(httpClient.post, server.CUSTOMER_URL, payload);

    if (response.status == 200) {
      yield put(Actions.createVerifyCardSuccess(response.data.data));
      showFormVerifyCardSuccess();
    }
  } catch (error) {}
}

export function* sagaAddCreateVerifyCard({
  payload,
  showFormVerifyCardSuccess,
}) {
  try {
    const response = yield call(httpClient.post, server.ADD_CARD_URL, payload);

    if (response.status == 200) {
      showFormVerifyCardSuccess();
    }
  } catch (error) {}
}

export function* sagaDeleteCard({ payload, showFormDeleteCardSuccess }) {
  try {
    const response = yield call(httpClient.post, `${server.DELETE_CARD_URL}`, {
      ...payload,
    });

    if (response.status == 200) {
      showFormDeleteCardSuccess();
    }
  } catch (error) {}
}
