import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";
import Actions from "../actions";
import { call, put, delay } from "redux-saga/effects";

export function* sagaPostReviewCar({ payload, showFormSuccess, showFormError }) {
  try {
    const response = yield call(
      httpClient.post,
      `${server.POST_REVIEW_CAR}`,
      payload
    );

    if (response.status == 200) {
      showFormSuccess();
    }
  } catch (error) {
    showFormError();
  }
}

export function* sagaPostReviewClient({ payload, showFormSuccess, showFormError }) {
  try {
    const response = yield call(
      httpClient.post,
      `${server.POST_REVIEW_CLIENT}`,
      payload
    );

    if (response.status == 200) {
      showFormSuccess();
    }
  } catch (error) {
    showFormError();
  }
}
