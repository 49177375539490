import React from "react";
import { Input } from "antd";

export default function TextInput({
  field,
  label,
  name,
  id,
  value,
  form: { touched, errors },
  ...props
}) {
  return (
    <>
      <Input id={id} type="text" {...field} {...props} />
    </>
  );
}
