import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const getDataVerifyHost = () => ({
  type: actions.GET_DATA_VERIFY_HOST_REQUEST,
});

export const getDataVerifyHostFetching = () => ({
  type: actions.GET_DATA_VERIFY_HOST_FETCHING,
});

export const getDataVerifyHostFailed = () => ({
  type: actions.GET_DATA_VERIFY_HOST_FAILED,
});

export const getDataVerifyHostSuccess = (payload) => ({
  type: actions.GET_DATA_VERIFY_HOST_SUCCESS,
  payload,
});

export default {
  getDataVerifyHost,
  getDataVerifyHostFetching,
  getDataVerifyHostFailed,
  getDataVerifyHostSuccess,
};
