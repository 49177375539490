import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const getTicketSupport = () => ({
  type: actions.GET_TICKET_SUPPORT_REQUEST,
});

export const getTicketSupportFetching = () => ({
  type: actions.GET_TICKET_SUPPORT_FETCHING,
});

export const getTicketSupportFailed = () => ({
  type: actions.GET_TICKET_SUPPORT_FAILED,
});

export const getTicketSupportSuccess = (payload) => ({
  type: actions.GET_TICKET_SUPPORT_SUCCESS,
  payload,
});

export default {
  getTicketSupport,
  getTicketSupportFetching,
  getTicketSupportFailed,
  getTicketSupportSuccess,
};
