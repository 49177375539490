import React, { useEffect, useState } from "react";
import styles from "../../styles/Inbox.module.css";
// import Nav from "react-bootstrap/Nav";
import { Button, Tabs, Modal, Typography } from "antd";

import Mychat from "./Mychat";
import Read from "./Read";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../redux/actions";
import { db, database, storage } from "../../utils/FirebaseDB";
import {
  getDoc,
  collection,
  getDocs,
  setDoc,
  doc,
  where,
  query,
  addDoc,
} from "firebase/firestore";
import {
  getDatabase,
  get,
  ref,
  set,
  off,
  onValue,
  push,
  update,
} from "firebase/database";
import * as storageDB from "firebase/storage";
import TabPane from "antd/es/tabs/TabPane";

export default function Inbox() {
  const [user, setUser] = useState(0);
  const [focus, setFocus] = useState("1");
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [room, setRoom] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [message, setMessage] = useState([]);
  const dispatch = useDispatch();
  const [chatroom, setChatroom] = useState([]);
  useEffect(() => {
    const dbRef2 = ref(database, `chatrooms/`);
    onValue(dbRef2, async (snapshot) => {
      await getData(user);
      setChatroom([snapshot.val()]);
      let room = localStorage.getItem("chatroomId");
      let res = JSON.parse(room);

      if (message.length > 0) {
        let objDiv = document.getElementById("selected");
        objDiv.scrollTop = objDiv.scrollHeight;
      }
      // if (selected && res) {
      //   showMessage(snapshot.val()[res].messages);

      //   // if (snapshot.val()[res]) {
      //   //   const updates = {};
      //   //   if (selected.name) {
      //   //     updates[`chatrooms/${res}/influenceradmin`] = 0;
      //   //   } else {
      //   //     updates[`chatrooms/${res}/marketeradmin`] = 0;
      //   //   }
      //   //   update(ref(database), updates);
      //   // }
      // }
    });

    return () => {
      off(dbRef2);
    };
  }, [setChatroom, selected, user]);

  const getData = async (user) => {
    const data = [];

    const mySnapshot = await get(ref(database, `users/${user}`));
    const userRef = collection(db, "Users");

    const userDB = await getDocs(userRef);

    const friends = mySnapshot.val().friends || [];

    friends.map((res) => {
      userDB.docs.map((user) => {
        if (user.data().uid == res.uid) {
          data.push({ ...user.data(), chatroomId: res.chatroomId });
        }
      });
    });

    setRoom(data);
  };
  const showModal = () => {
    setOpen(true);
  };

  const { Title, Text } = Typography;

  const onChange = (key) => {
    console.log(key);
  };

  // const items = [
  //   {
  //     key: "1",
  //     label: `My Chat`,
  //     children: <Mychat setFocus={setFocus} />,
  //   },
  //   {
  //     key: "2",
  //     label: `Read`,
  //     children: <Read />,
  //   },
  // ];

  return (
    <div className={styles.container_inbox}>
      {/* <Nav variant="tabs">
        <Nav.Item
          className={[
            focus == "tab1" ? styles.border_line : "",
            "font_Medium text-uppercase",
          ]}
          onClick={() => {
            setFocus("tab1");
          }}
        >
          <Nav.Link style={{ color: "black" }}>my chat</Nav.Link>
        </Nav.Item>
        <Nav.Item
          className={[
            focus == "tab2" ? styles.border_line : "",
            "font_Medium text-uppercase",
          ]}
          onClick={() => {
            setFocus("tab2");
          }}
        >
          <Nav.Link style={{ color: "black" }}>read</Nav.Link>
        </Nav.Item>
      </Nav> */}
      <div style={{ position: "relative" }}>
        <div style={{ display: "flex", position: "absolute", right: 0 }}>
          <button
            onClick={() => {
              setUser(0);
              setFocus("1");
            }}
            className={[styles.tmp_button]}
            style={{
              color: "white",
              marginLeft: 10,
              padding: 10,

              zIndex: 99999,
            }}
          >
            User 1
          </button>
          <button
            onClick={() => {
              setFocus("1");
              setUser(1);
            }}
            className={[styles.tmp_button]}
            style={{
              color: "white",
              marginLeft: 10,
              padding: 10,

              zIndex: 99999,
            }}
          >
            User 2
          </button>
        </div>

        <Tabs defaultActiveKey="1" activeKey={focus} onChange={onChange}>
          <TabPane tab="My Chat" key="1">
            <Mychat
              setFocus={setFocus}
              setSelected={setSelected}
              selected={selected}
              message={message}
              setMessage={setMessage}
              chatroom={chatroom}
              room={room}
              setChatroom={setChatroom}
            />
          </TabPane>

          <TabPane tab="Read" key="2">
            <Read
              user={user}
              setSelected={setSelected}
              selected={selected}
              message={message}
              setMessage={setMessage}
            />
          </TabPane>
        </Tabs>
      </div>

      <Modal title="Title" open={open}>
        <Text
          className="font_Medium"
          style={{
            margin: 0,
            marginTop: 10,
            color: "#4F4F4F",
            fontSize: 16,
          }}
        >
          REVIEWS
        </Text>
      </Modal>

      {/* {focus == "tab1" ? (
        <>
          <Mychat />
        </>
      ) : (
        <>
          <Read />
        </>
      )} */}
    </div>
  );
}
