import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAEypeqZqk-NxP9jGJK4iljbeXFqZG9PN4",
  authDomain: "carrental-th.firebaseapp.com",
  databaseURL:
    "https://carrental-th-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "carrental-th",
  storageBucket: "carrental-th.appspot.com",
  messagingSenderId: "67847001590",
  appId: "1:67847001590:web:55a7726f7c142557c623d7",
  measurementId: "G-CP252RL91V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const database = getDatabase(app);
export const storage = getStorage(app);
