import React, { useEffect, useState } from "react";
import styles from "../../styles/SearchCar.module.css";
// import { Card } from "react-bootstrap";
import { Card, Typography } from "antd";
import { Layout, Menu, theme } from "antd";
import { Col, Row } from "antd";
import star from "../../assets/images/star.svg";
import crown from "../../assets/images/crown.svg";
import manuser from "../../assets/images/man-user.svg";
import iconcar from "../../assets/images/iconcar.svg";
import heart from "../../assets/images/heart.svg";
import distance from "../../assets/images/distance.png";
import clock from "../../assets/images/clock.png";

import line3 from "../../assets/images/line3.svg";
import Moment from "moment";
export default function CardCar({ item }) {
  const { Header, Content, Footer } = Layout;
  const { Title, Text } = Typography;

  return (
    <div
      className={[styles.border_card]}
      style={{
        position: "relative",
        width: "100%",
        height: item.distance ? 350 : 305,
        paddingBottom: 10,
        marginBottom: 15,
      }}
    >
      <img src={`${item.image}`} width={"100%"} />
      <img
        src={heart}
        width={22}
        style={{ position: "absolute", right: 10, top: 10 }}
      />
      <div style={{ padding: 10 }}>
        <Title
          level={5}
          className={"p-0 m-0 pt-2  font_Medium"}
          style={{ fontSize: 18, color: "#000", margin: 0 }}
        >
          {item.name}
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 7,
          }}
        >
          {/* star */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img src={star} width={15} style={{ paddingBottom: 4 }} />
            <Text
              className={"p-0 m-0 ps-1 pt-1 font_Light"}
              style={{
                fontSize: 15,
                color: "#000",
                margin: 0,
                marginLeft: 5,
              }}
            >
              {item.star}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginLeft: 10,
            }}
          >
            <img
              src={crown}
              width={15}
              className="pb-0"
              style={{ paddingBottom: 2 }}
            />
            <Text
              className={"p-0 m-0 ps-1  font_Light"}
              style={{ fontSize: 15, color: "#000", marginLeft: 5 }}
            >
              {item.crown}
            </Text>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center", marginTop: 7 }}>
          {/* star */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img src={manuser} width={15} style={{ paddingBottom: 2 }} />
            <Text
              className={"p-0 m-0 ps-1 pt-1 font_Light"}
              style={{ fontSize: 15, color: "#000", marginLeft: 5 }}
            >
              {item.user}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginLeft: 10,
            }}
          >
            <img src={iconcar} width={18} style={{ paddingBottom: 2 }} />
            <Text
              className={"p-0 m-0 ps-1  font_Light"}
              style={{ fontSize: 15, color: "#000", marginLeft: 5 }}
            >
              {item.type}
            </Text>
            <Text
              className={"p-0 m-0 ps-2  font_Light"}
              style={{ fontSize: 15, color: "#000", marginLeft: 5 }}
            >
              ({item.trip} trip)
            </Text>
          </div>
        </div>

        {item.distance ? (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 5,
              }}
            >
              <img src={distance} width={18} className="pb-1" />

              <Text
                className={"font_Light"}
                style={{ fontSize: 15, color: "#000", marginLeft: 10 }}
              >
                {item.distance} km.
              </Text>
            </div>
          </div>
        ) : (
          <></>
        )}
        {/*
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          <img src={clock} width={18} className="pb-1" />
          <Text
            className={"font_Light"}
            style={{ fontSize: 11, color: "#000", marginLeft: 10 }}
          >
            {console.log(item)}
            {Moment(+item.from).format("DD/MMM/YYYY h:mm:ss")} -{" "}
            {Moment(+item.until).format("DD/MMM/YYYY h:mm:ss")}
          </Text>
        </div> */}

        {/* line */}
        <img
          src={line3}
          height={0.5}
          style={{ marginBottom: 5, marginTop: 5 }}
          className={[styles.line]}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Text
            className="font_Medium"
            style={{
              fontSize: 16,
              color: "#000",
              margin: 0,
            }}
          >
            ${item.price}/day
          </Text>
        </div>
      </div>
    </div>
  );
}
