import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const paymentRequest = (payload, showFormSuccess) => ({
  type: actions.PAYMENT_REQUEST,
  payload,
  showFormSuccess,
});

export default {
  paymentRequest,
};
