export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const USER_ID = "user_id";
export const ROLE = "role";
export const MARKETER = "Marketer";
export const INFLUENCER = "Influencer";
export const ADDRESS = "address";
export const NO = "no";
export const YES = "yes";
export const OK = "ok";
export const NOK = "nok";
export const CART = "cart";
export const apiUrl = "https://us-central1-carrental-th.cloudfunctions.net/app";
// export const apiUrl = "http://localhost:5000";
export const apiVersion = "/api/v1";
export const apiImage = "/images/";

//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
  "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE =
  "An error has occurred. The photo was unable to upload.";

// Error Code
export const E_PICKER_CANCELLED = "E_PICKER_CANCELLED";
export const E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR =
  "E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR";
export const E_PERMISSION_MISSING = "E_PERMISSION_MISSING";
export const E_PICKER_NO_CAMERA_PERMISSION = "E_PICKER_NO_CAMERA_PERMISSION";
export const E_USER_CANCELLED = "E_USER_CANCELLED";
export const E_UNKNOWN = "E_UNKNOWN";
export const E_DEVELOPER_ERROR = "E_DEVELOPER_ERROR";
export const TIMEOUT_NETWORK = "ECONNABORTED"; // request service timeout
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

export const server = {
  USER_URL: `user`,
  SEARCH_URL: "search",
  MAP_URL: "map",
  HOST_URL: "host",
  HOST_VERIFY_URL: "host/verify",
  CLIENT_VERIFY_URL: "user/verify",
  CAR: "host/car",
  POST_REVIEW_CAR: "host/review/car",
  POST_REVIEW_CLIENT: "host/review/client",
  PAYMENT: "omise/credit-payment",
  TICKET_SUPPORT: "ticket",
  CUSTOMER_URL: "omise/customer",
  CARD_URL: "user/card",
  ADD_CARD_URL: "omise/addCard",
  DELETE_CARD_URL: "omise/deleteCard",
};
