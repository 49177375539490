import * as actions from "../actionTypes";
import Actions from "../actions";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";
import { call, put, delay } from "redux-saga/effects";

export function* sagaGetDataVerifyHost() {
  try {
    yield put(Actions.getDataVerifyHostFetching());
    const make = [];
    const model = [];
    const odometer = [];
    const transmission = [];
    const trim = [];
    const style = [];
    const country = [];
    const why_sharing = [];
    const share_your_car = [];
    const current_use_car = [];
    const alert_home = [];
    const maximum_trip_duration = [];
    const minimum_trip_duration = [];
    const state = [];

    const data = {};

    const response = yield call(httpClient.post, server.HOST_VERIFY_URL);

    if (response.status == 200 && response.data.status == "ok") {
      response.data.data[0][0].make.map((item) => {
        make.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].model.map((item) => {
        model.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].Odometer.map((item) => {
        odometer.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].Transmission.map((item) => {
        transmission.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].trim.map((item) => {
        trim.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].style.map((item) => {
        style.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].country.map((item) => {
        country.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].why_sharing.map((item) => {
        why_sharing.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].current_use_car.map((item) => {
        current_use_car.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].share_your_car.map((item) => {
        share_your_car.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].alert_home.map((item) => {
        alert_home.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].maximum_trip_duration.map((item) => {
        maximum_trip_duration.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].minimum_trip_duration.map((item) => {
        minimum_trip_duration.push({
          value: item,
          label: item,
        });
      });

      response.data.data[0][0].state.map((item) => {
        state.push({
          value: item,
          label: item,
        });
      });

      data["make"] = make;
      data["model"] = model;
      data["odometer"] = odometer;
      data["transmission"] = transmission;
      data["trim"] = trim;
      data["style"] = style;
      data["country"] = country;
      data["why_sharing"] = why_sharing;
      data["share_your_car"] = share_your_car;
      data["current_use_car"] = current_use_car;
      data["alert_home"] = alert_home;
      data["maximum_trip_duration"] = maximum_trip_duration;
      data["minimum_trip_duration"] = minimum_trip_duration;
      data["car_features"] = response.data.data[0][0].car_features;
      data["state"] = state;

      yield put(Actions.getDataVerifyHostSuccess(data));
    }
  } catch (error) {
    yield put(Actions.getDataVerifyHostFailed());
  }
}
