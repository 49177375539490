import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const searchLoaction = (payload, navigation) => ({
  type: actions.SEARCH_LOCATION_REQUEST,
  payload,
  navigation,
});

export const searchLoactionFetching = () => ({
  type: actions.SEARCH_LOCATION_FETCHING,
});

export const searchLoactionFailed = () => ({
  type: actions.SEARCH_LOCATION_FAILED,
});

export const searchLoactionSuccess = (payload) => ({
  type: actions.SEARCH_LOCATION_SUCCESS,
  payload,
});

export default {
  searchLoaction,
  searchLoactionFetching,
  searchLoactionFailed,
  searchLoactionSuccess,
};
