import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const hostVerify = (payload, showFormSuccess, showFormError) => ({
  type: actions.HOST_VERIFY_REQUEST,
  payload,
  showFormSuccess,
  showFormError,
});

export const hostVerifyFetching = () => ({
  type: actions.HOST_VERIFY_FETCHING,
});

export const hostVerifyFailed = () => ({
  type: actions.HOST_VERIFY_FAILED,
});

export const hostVerifySuccess = (payload) => ({
  type: actions.HOST_VERIFY_SUCCESS,
  payload,
});

export default {
  hostVerify,
  hostVerifyFetching,
  hostVerifyFailed,
  hostVerifySuccess,
};
