// GET_USER
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_FETCHING = "GET_USER_FETCHING";
export const GET_USER_FAILED = "GET_USER_FAILED";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

// SEARCH_CAR
export const SEARCH_CAR_REQUEST = "SEARCH_CAR_REQUEST";
export const SEARCH_CAR_FETCHING = "SEARCH_CAR_FETCHING";
export const SEARCH_CAR_FAILED = "SEARCH_CAR_FAILED";
export const SEARCH_CAR_SUCCESS = "SEARCH_CAR_SUCCESS";

// CHECK_MAP_CAR
export const CHECK_MAP_CAR_REQUEST = "CHECK_MAP_CAR_REQUEST";
export const CHECK_MAP_CAR_FETCHING = "CHECK_MAP_CAR_FETCHING";
export const CHECK_MAP_CAR_FAILED = "CHECK_MAP_CAR_FAILED";
export const CHECK_MAP_CAR_SUCCESS = "CHECK_MAP_CAR_SUCCESS";

// HOST_VERIFY
export const HOST_VERIFY_REQUEST = "HOST_VERIFY_REQUEST";
export const HOST_VERIFY_FETCHING = "HOST_VERIFY_FETCHING";
export const HOST_VERIFY_FAILED = "HOST_VERIFY_FAILED";
export const HOST_VERIFY_SUCCESS = "HOST_VERIFY_SUCCESS";

// CLIENT
export const CLIENT_VERIFY_REQUEST = "CLIENT_VERIFY_REQUEST";
export const CLIENT_VERIFY_FETCHING = "CLIENT_VERIFY_FETCHING";
export const CLIENT_VERIFY_FAILED = "CLIENT_VERIFY_FAILED";
export const CLIENT_VERIFY_SUCCESS = "CLIENT_VERIFY_SUCCESS";

// GET DATA VERIFY HOST
export const GET_DATA_VERIFY_HOST_REQUEST = "GET_DATA_VERIFY_HOST_REQUEST";
export const GET_DATA_VERIFY_HOST_FETCHING = "GET_DATA_VERIFY_HOST_FETCHING";
export const GET_DATA_VERIFY_HOST_FAILED = "GET_DATA_VERIFY_HOST_FAILED";
export const GET_DATA_VERIFY_HOST_SUCCESS = "GET_DATA_VERIFY_HOST_SUCCESS";

// SEARCH_LOCATION
export const SEARCH_LOCATION_REQUEST = "SEARCH_LOCATION_REQUEST";
export const SEARCH_LOCATION_FETCHING = "SEARCH_LOCATION_FETCHING";
export const SEARCH_LOCATION_FAILED = "SEARCH_LOCATION_FAILED";
export const SEARCH_LOCATION_SUCCESS = "SEARCH_LOCATION_SUCCESS";

// GET_CAR
export const GET_CAR_REQUEST = "GET_CAR_REQUEST";
export const GET_CAR_FETCHING = "GET_CAR_FETCHING";
export const GET_CAR_FAILED = "GET_CAR_FAILED";
export const GET_CAR_SUCCESS = "GET_CAR_SUCCESS";

// GET_CHAT
export const GET_CHAT_REQUEST = "GET_CHAT_REQUEST";
export const GET_CHAT_FETCHING = "GET_CHAT_FETCHING";
export const GET_CHAT_FAILED = "GET_CHAT_FAILED";
export const GET_CHAT_SUCCESS = "GET_CHAT_SUCCESS";

// POST_REVIEW_CAR
export const POST_REVIEW_CAR_REQUEST = "POST_REVIEW_CAR_REQUEST";
export const POST_REVIEW_CAR_FETCHING = "POST_REVIEW_CAR_FETCHING";
export const POST_REVIEW_CAR_FAILED = "POST_REVIEW_CAR_FAILED";
export const POST_REVIEW_CAR_SUCCESS = "POST_REVIEW_CAR_SUCCESS";

// GET_REVIEW_CAR
export const GET_REVIEW_CAR_REQUEST = "GET_REVIEW_CAR_REQUEST";
export const GET_REVIEW_CAR_FETCHING = "GET_REVIEW_CAR_FETCHING";
export const GET_REVIEW_CAR_FAILED = "GET_REVIEW_CAR_FAILED";
export const GET_REVIEW_CAR_SUCCESS = "GET_REVIEW_CAR_SUCCESS";

// GET_REVIEW_CLIENT
export const GET_REVIEW_CLIENT_REQUEST = "GET_REVIEW_CLIENT_REQUEST";
export const GET_REVIEW_CLIENT_FETCHING = "GET_REVIEW_CLIENT_FETCHING";
export const GET_REVIEW_CLIENT_FAILED = "GET_REVIEW_CLIENT_FAILED";
export const GET_REVIEW_CLIENT_SUCCESS = "GET_REVIEW_CLIENT_SUCCESS";

// GET_TICKET_SUPPORT
export const GET_TICKET_SUPPORT_REQUEST = "GET_TICKET_SUPPORT_REQUEST";
export const GET_TICKET_SUPPORT_FETCHING = "GET_TICKET_SUPPORT_FETCHING";
export const GET_TICKET_SUPPORT_FAILED = "GET_TICKET_SUPPORT_FAILED";
export const GET_TICKET_SUPPORT_SUCCESS = "GET_TICKET_SUPPORT_SUCCESS";

// CREATE_TICKET_SUPPORT
export const CREATE_TICKET_SUPPORT_REQUEST = "CREATE_TICKET_SUPPORT_REQUEST";
export const CREATE_TICKET_SUPPORT_FETCHING = "CREATE_TICKET_SUPPORT_FETCHING";
export const CREATE_TICKET_SUPPORT_FAILED = "CREATE_TICKET_SUPPORT_FAILED";
export const CREATE_TICKET_SUPPORT_SUCCESS = "CREATE_TICKET_SUPPORT_SUCCESS";

// CREATE_CUSTOMER
export const CREATE_CUSTOMER_REQUEST = "CREATE_CUSTOMER_REQUEST";
export const CREATE_CUSTOMER_FETCHING = "CREATE_CUSTOMER_FETCHING";
export const CREATE_CUSTOMER_FAILED = "CREATE_CUSTOMER_FAILED";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";

// PAYMENT
export const PAYMENT_REQUEST = "PAYMENT_REQUEST";

// POST_REVIEW_CLIENT_REQUEST
export const POST_REVIEW_CLIENT_REQUEST = "POST_REVIEW_CLIENT_REQUEST";

// CREATE_TICKET_SUPPORT
export const CREATE_TICKET_SUPPORT = "CREATE_TICKET_SUPPORT";

// createVerifyCard
export const CREATE_VERIFY_CARD = "CREATE_VERIFY_CARD";
export const CREATE_VERIFY_CARD_SUCCESS = "CREATE_VERIFY_CARD_SUCCESS";

// authen KYC
export const AUTHEN_KYC_CARD = "AUTHEN_KYC_CARD";
export const AUTHEN_KYC_SUCCESS = "AUTHEN_KYC_SUCCESS";

// GET_CARD_BYID
export const GET_CARD_BYID_REQUEST = "GET_CARD_BYID_REQUEST";
export const GET_CARD_BYID_FETCHING = "GET_CARD_BYID_FETCHING";
export const GET_CARD_BYID_FAILED = "GET_CARD_BYID_FAILED";
export const GET_CARD_BYID_SUCCESS = "GET_CARD_BYID_SUCCESS";

// ADD_CARD_PAYMENT
export const ADD_CARD_PAYMENT_REQUEST = "ADD_CARD_PAYMENT_REQUEST";

// DELETE_CARD_PAYMENT
export const DELETE_CARD_PAYMENT_REQUEST = "DELETE_CARD_PAYMENT_REQUEST";
