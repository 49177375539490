import * as actions from "../actionTypes";
import Actions from "../actions";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";
import { call, put, delay } from "redux-saga/effects";

export function* sagagetReviewCar({ id }) {
  try {
    yield put(Actions.getReviewCarFetching());

    const response = yield call(
      httpClient.get,
      `${server.POST_REVIEW_CAR}?id=${id}`
    );

    if (response.status == 200 && response.data.data) {
      yield put(Actions.getReviewCarSuccess(response.data.data));
    }
  } catch (error) {
    yield put(Actions.getReviewCarFailed());
  }
}
