import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import * as geofirestore from "geofirestore";

firebase.initializeApp({
  apiKey: "AIzaSyAEypeqZqk-NxP9jGJK4iljbeXFqZG9PN4",
  authDomain: "carrental-th.firebaseapp.com",
  databaseURL:
    "https://carrental-th-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "carrental-th",
  storageBucket: "carrental-th.appspot.com",
  messagingSenderId: "67847001590",
  appId: "1:67847001590:web:55a7726f7c142557c623d7",
  measurementId: "G-CP252RL91V",
  databaseURL:
    "https://carrental-th-default-rtdb.asia-southeast1.firebasedatabase.app",
});

const firestore = firebase.firestore();
export const GeoFirestore = geofirestore.initializeApp(firestore);
