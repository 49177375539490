import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";
import Actions from "../actions";
import { call, put, delay } from "redux-saga/effects";

export function* sagaGetCardByid() {
  try {
    yield put(Actions.getCardByidFetching());

    const response = yield call(httpClient.get, `${server.CARD_URL}/1`);

    if (response.status == 200) {
      yield put(Actions.getCardByidSuccess(response.data.data));
    }
  } catch (error) {
    yield put(Actions.getCardByidFailed());
  }
}
