import React from "react";
import { Input } from "antd";

export default function TextInputPassword({
  field,
  label,
  name,
  id,
  value,
form: { touched, errors },
  ...props
}) {
  return (
    <>
      <Input id={id} type="password" {...field} {...props} />
    </>
  );
}
