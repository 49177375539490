import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const createTicketSupportRequest = (payload, showFormSuccess) => ({
  type: actions.CREATE_TICKET_SUPPORT_REQUEST,
  payload,
  showFormSuccess,
});

export default {
  createTicketSupportRequest,
};
