import React, { useEffect, useState } from "react";
import styles from "../../styles/FindCar.module.css";
// import Nav from "react-bootstrap/Nav";
import Mychat from "./Mychat";
import Read from "./Read";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../redux/actions";
// import { Button, Card, Form } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import CardCar from "../component/CardCar";
import { Layout, Menu, theme } from "antd";
import { Col, Row } from "antd";
import { Button, Space } from "antd";
import { Typography } from "antd";
import line2 from "../../assets/images/line2.svg";
import star from "../../assets/images/star.svg";
import profile1 from "../../assets/images/profile1.png";
import profile2 from "../../assets/images/profile2.png";
import profile3 from "../../assets/images/profile3.png";

export default function FindCar() {
  const [focus, setFocus] = useState("tab1");
  const [dateFrom, onDateFrom] = useState("");
  const [timeFrom, onTimeFrom] = useState("tab1");
  const [value, onChange] = useState("");

  const { Header, Content, Footer } = Layout;
  const { Title, Text } = Typography;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getCar());
  }, []);

  const getCarReducer = useSelector(({ getCarReducer }) => getCarReducer);

  return (
    <div className={[styles.container_fluid]}>
      <div
        style={{
          position: "relative",
          padding: 0,
          margin: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <img
          src={require("../../assets/images/banner.png")}
          className={styles.banner}
          width={"100%"}
          height={"200%"}
        />
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title
            level={2}
            className={"font_Medium"}
            style={{ fontSize: 36, color: "#fff", margin: 0 }}
          >
            Lorem Ipsum
          </Title>
          <Title
            level={5}
            className={"font_Light"}
            style={{
              fontSize: 16,
              color: "#fff",
              textAlign: "center",
              margin: 0,
            }}
          >
            Lorem ipsum is placeholder text commonly used in the graphic
          </Title>
        </div>
      </div>
      <div className={[styles.container]}>
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center ">
              <h4
                className={"font_Medium p-0 m-0"}
                style={{ fontSize: 20, color: "#000" }}
              >
                Top rated BMW
              </h4>
            </div>
            <Row justify={"center"}>
              {getCarReducer.result
                ? getCarReducer.result.map((item) => {
                    return (
                      <Col
                        md={6}
                        xs={24}
                        style={{
                          paddingRight: 8,
                        }}
                      >
                        <CardCar item={item} />
                      </Col>
                    );
                  })
                : []}
            </Row>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button type="primary" block className={[styles.tmp_button]} dark>
            See more
          </Button>
        </div>
        <img
          src={line2}
          className={styles.line}
          style={{ marginBottom: 5, marginTop: 20 }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          <h1
            className="font_Medium"
            style={{ fontSize: 30, color: "#000", margin: 0 }}
          >
            Review of Rental Sedan
          </h1>
          <h6
            className="font_Light"
            style={{ fontSize: 16, color: "#000", margin: 0, marginTop: 15 }}
          >
            Lorem ipsum is placeholder text commonly used in the graphic
          </h6>
        </div>
        <Row justify={"center"} className={styles.containerReview}>
          <Col md={8} xs={24} style={{ height: 230, paddingRight: 10 }}>
            <div className={styles.cardReview}>
              <div style={{ display: "flex" }}>
                <img
                  src={profile1}
                  width={50}
                  height={50}
                  style={{ borderRadius: 100 }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 10,
                  }}
                >
                  <Title
                    className="font_Medium"
                    level={5}
                    style={{
                      fontSize: 18,
                      color: "#000",
                      marginTop: 0,
                      fontWeight: 500,
                    }}
                  >
                    Mareeya
                  </Title>
                  <div className="d-flex mt-1">
                    <img
                      src={star}
                      width={12}
                      height={12}
                      style={{ marginRight: 2 }}
                    />
                    <img
                      src={star}
                      width={12}
                      height={12}
                      style={{ marginRight: 2 }}
                    />
                    <img
                      src={star}
                      width={12}
                      height={12}
                      style={{ marginRight: 2 }}
                    />
                    <img
                      src={star}
                      width={12}
                      height={12}
                      style={{ marginRight: 2 }}
                    />
                    <img src={star} width={12} height={12} />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 15 }}>
                <Text
                  className={"font_Light"}
                  style={{ fontSize: 14, color: "#000" }}
                >
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmodtempor incididunt ut labore et dolore magna aliqua.
                  Ut enimad minim veniam.”
                </Text>
              </div>
              <div style={{ display: "flex", marginTop: 10 }}>
                <Text
                  className={"font_Medium "}
                  style={{ fontSize: 14, color: "#000", fontWeight: 500 }}
                >
                  sep 2,2022,
                </Text>
                <Text
                  className={"font_Medium "}
                  style={{ fontSize: 14, color: "#000", fontWeight: 500 }}
                >
                  11:32:38 AM
                </Text>
              </div>
            </div>
          </Col>
          <Col md={8} xs={24} style={{ height: 230, paddingRight: 10 }}>
            <div className={styles.cardReview}>
              <div style={{ display: "flex" }}>
                <img
                  src={profile2}
                  width={50}
                  height={50}
                  style={{ borderRadius: 100 }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 10,
                  }}
                >
                  <Title
                    className="font_Medium"
                    level={5}
                    style={{
                      fontSize: 18,
                      color: "#000",
                      marginTop: 0,
                      fontWeight: 500,
                    }}
                  >
                    Mareeya
                  </Title>
                  <div className="d-flex mt-1">
                    <img
                      src={star}
                      width={12}
                      height={12}
                      style={{ marginRight: 2 }}
                    />
                    <img
                      src={star}
                      width={12}
                      height={12}
                      style={{ marginRight: 2 }}
                    />
                    <img
                      src={star}
                      width={12}
                      height={12}
                      style={{ marginRight: 2 }}
                    />
                    <img
                      src={star}
                      width={12}
                      height={12}
                      style={{ marginRight: 2 }}
                    />
                    <img src={star} width={12} height={12} />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 15 }}>
                <Text
                  className={"font_Light"}
                  style={{ fontSize: 14, color: "#000" }}
                >
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmodtempor incididunt ut labore et dolore magna aliqua.
                  Ut enimad minim veniam.”
                </Text>
              </div>
              <div style={{ display: "flex", marginTop: 10 }}>
                <Text
                  className={"font_Medium "}
                  style={{ fontSize: 14, color: "#000", fontWeight: 500 }}
                >
                  sep 2,2022,
                </Text>
                <Text
                  className={"font_Medium "}
                  style={{ fontSize: 14, color: "#000", fontWeight: 500 }}
                >
                  11:32:38 AM
                </Text>
              </div>
            </div>
          </Col>
          <Col md={8} xs={24} style={{ height: 230, paddingRight: 10 }}>
            <div className={styles.cardReview}>
              <div style={{ display: "flex" }}>
                <img
                  src={profile3}
                  width={50}
                  height={50}
                  style={{ borderRadius: 100 }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 10,
                  }}
                >
                  <Title
                    className="font_Medium"
                    level={5}
                    style={{
                      fontSize: 18,
                      color: "#000",
                      marginTop: 0,
                      fontWeight: 500,
                    }}
                  >
                    Mareeya
                  </Title>
                  <div className="d-flex mt-1">
                    <img
                      src={star}
                      width={12}
                      height={12}
                      style={{ marginRight: 2 }}
                    />
                    <img
                      src={star}
                      width={12}
                      height={12}
                      style={{ marginRight: 2 }}
                    />
                    <img
                      src={star}
                      width={12}
                      height={12}
                      style={{ marginRight: 2 }}
                    />
                    <img
                      src={star}
                      width={12}
                      height={12}
                      style={{ marginRight: 2 }}
                    />
                    <img src={star} width={12} height={12} />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 15 }}>
                <Text
                  className={"font_Light"}
                  style={{ fontSize: 14, color: "#000" }}
                >
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmodtempor incididunt ut labore et dolore magna aliqua.
                  Ut enimad minim veniam.”
                </Text>
              </div>
              <div style={{ display: "flex", marginTop: 10 }}>
                <Text
                  className={"font_Medium "}
                  style={{ fontSize: 14, color: "#000", fontWeight: 500 }}
                >
                  sep 2,2022,
                </Text>
                <Text
                  className={"font_Medium "}
                  style={{ fontSize: 14, color: "#000", fontWeight: 500 }}
                >
                  11:32:38 AM
                </Text>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
