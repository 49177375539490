import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const createVerifyCard = (payload, showFormVerifyCardSuccess) => ({
  type: actions.CREATE_VERIFY_CARD,
  payload,
  showFormVerifyCardSuccess,
});

export const addCreateVerifyCard = (payload, showFormVerifyCardSuccess) => ({
  type: actions.ADD_CARD_PAYMENT_REQUEST,
  payload,
  showFormVerifyCardSuccess,
});

export const deleteCard = (payload, showFormDeleteCardSuccess) => ({
  type: actions.DELETE_CARD_PAYMENT_REQUEST,
  payload,
  showFormDeleteCardSuccess,
});

export const createVerifyCardSuccess = (payload) => ({
  type: actions.CREATE_VERIFY_CARD_SUCCESS,
  payload,
});

export default {
  createVerifyCard,
  createVerifyCardSuccess,
  addCreateVerifyCard,
  deleteCard,
};
