import * as actions from "../actionTypes";
import Actions from "../actions";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";
import { call, put, delay } from "redux-saga/effects";

export function* sagaPayment({ payload, showFormSuccess }) {
  try {
    const response = yield call(httpClient.post, server.PAYMENT, payload);

    if (response.status == 200 && response.data.data) {
      showFormSuccess();
    }
  } catch (error) {}
}
