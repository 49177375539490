import * as actions from "../actionTypes";
import Actions from "../actions";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";
import { call, put, delay } from "redux-saga/effects";

export function* sagaCreateTicketSupport({ payload, showFormSuccess }) {
  try {
    const response = yield call(
      httpClient.post,
      server.TICKET_SUPPORT,
      payload
    );

    if (response.status == 200) {
      showFormSuccess();
    }
  } catch (error) {}
}
