import React, { useEffect, useState } from "react";
import styles from "../../styles/Payment.module.css";
// import Nav from "react-bootstrap/Nav";
import Mychat from "./Mychat";
import Read from "./Read";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../redux/actions";
// import { Button, Card, Form } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import CardCar from "../component/CardCar";
import { Layout, Menu, theme } from "antd";
import { Col, Row } from "antd";
import { Button, Space } from "antd";
import { Typography } from "antd";
import line from "../../assets/images/line.png";
import like from "../../assets/images/like.png";
import line2 from "../../assets/images/line3.png";
import car1 from "../../assets/images/car1.png";
import profile1 from "../../assets/images/profile1.png";
import star from "../../assets/images/star.svg";
import document from "../../assets/images/document.png";
import Delete from "../../assets/images/delete.png";

import Script from "react-load-script";
import { server } from "../../utils/Constants";
import { Checkbox } from "antd";
import { Axios } from "axios";
import { apiUrl } from "../../utils/Constants";
import httpClient from "../../utils/HttpClient";
import Swal from "sweetalert2";
import AddCreditCard from "../component/AddCreditCard";
import Iframe from "react-iframe";
import { useSearchParams } from "react-router-dom";
let OmiseCard;

const showFormSuccess = () => {
  Swal.fire({
    position: "center",
    icon: "success",
    customClass: "font_Light",
    title: "Payment Success",
    text: "",
    showConfirmButton: false,
    timer: 2000,
  }).then((result) => {
    // navigation(`/reviews?id=2`);
  });
};

export default function Payment() {
  const [focus, setFocus] = useState("tab1");
  const [dateFrom, onDateFrom] = useState("");
  const [timeFrom, onTimeFrom] = useState("tab1");
  const [value, onChange] = useState("");
  const [select, setSelect] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const getCardByidReducer = useSelector(
    ({ getCardByidReducer }) => getCardByidReducer
  );

  const { Header, Content, Footer } = Layout;
  const { Title, Text } = Typography;

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(searchParams.get("id"));
    dispatch(actions.getCardByid());
  }, []);

  const handleLoadScript = () => {
    OmiseCard = window.Omise;

    OmiseCard.publicKey = "pkey_test_5utz1vrwsf9lvwqs7yu";
  };

  const omiseCardHandler = () => {
    OmiseCard.open({
      amount: "5000",
      onCreateTokenSuccess: (token) => {
        console.log("token", token);
      },
      onFormClosed: () => {},
    });
  };

  const creditCardConfigure = () => {
    OmiseCard.configure({
      defaultPaymentMethod: "credit_card",
      otherPaymentMethods: [],
    });
    OmiseCard.configureButton("#credit-card");
    OmiseCard.attach();
  };

  const handleClick = (e) => {
    // creditCardConfigure();
    // omiseCardHandler();

    // const tokenParameters = {
    //   city: "New York",
    //   country: "US",
    //   expiration_month: 2,
    //   expiration_year: 2023,
    //   name: "Somchai Prasert",
    //   number: "4242424242424242",
    //   phone_number: "0123456789",
    //   postal_code: 10320,
    //   security_code: 123,
    //   state: "NY",
    //   street1: "476 Fifth Avenue",
    // };

    // OmiseCard.createToken(
    //   "card",
    //   tokenParameters,
    //   function (statusCode, response) {
    //     dispatch(
    //       actions.createToken(
    //         {
    //           carental_booking_id: response.id,
    //           uid: "1",
    //           amount: "5000",
    //           customer_id: "cust_test_5uvjsogwboijz3dxc4q",
    //           omiseToken: response.id,
    //           orderReceivedUrl:
    //             "https://75a3-2001-fb1-11f-ee4c-39c2-aeeb-a0b4-5872.ap.ngrok.io/api/v1/omise/webhook",
    //           headers: {
    //             "Content-Type": "application/json",
    //           },
    //         },
    //         showFormSuccess
    //       )
    //     );
    //   }
    // );

    dispatch(
      actions.paymentRequest(
        {
          carental_booking_id: "2314jksdfsl",
          uid: "1",
          amount: "10000",
          customer_id: select.customer_id,
          // omiseToken: response.id,
          card_id: select.card_id,
          orderReceivedUrl:
            "https://75a3-2001-fb1-11f-ee4c-39c2-aeeb-a0b4-5872.ap.ngrok.io/api/v1/omise/webhook",
          headers: {
            "Content-Type": "application/json",
          },
        },
        showFormSuccess
      )
    );
  };

  const showFormDeleteCardSuccess = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      customClass: "font_Light",
      title: "Delete Card Success",
      text: "",
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {
      setSelect(null);
      dispatch(actions.getCardByid());
    });
  };

  return (
    <div className={[styles.container_fluid]}>
      <div
        style={{
          maxWidth: 240,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Title
          level={2}
          className={styles.font_Medium}
          style={{ marginBottom: 0 }}
        >
          Checkout
        </Title>
      </div>

      <Row>
        <Col
          span={16}
          style={{
            paddingLeft: 0,
            paddingRight: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              paddingTop: 20,
              alignItems: "center",
            }}
          >
            <img src={like} style={{ width: 30 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                className={styles.font_Medium}
                style={{
                  marginLeft: 15,
                  fontSize: 18,
                  fontWeight: "bolder",
                  color: "#4F4F4F",
                }}
              >
                Free cancellation before October 8, 10:00 AM
              </Text>
              <Text
                style={{
                  marginLeft: 15,
                  fontSize: 16,
                  color: "#B4B4B4",
                }}
              >
                If your plans change, we’ve got your back.
              </Text>
            </div>
          </div>

          <img
            src={line2}
            className={styles.line}
            style={{ marginBottom: 0, marginTop: 0 }}
          />

          <Title
            level={5}
            className="font_Medium"
            style={{
              fontSize: 18,
              marginTop: 10,
              color: "#000",
            }}
          >
            Protection plans
          </Title>
          <Text
            className="font_Light"
            style={{
              fontSize: 14,
              color: "#4F4F4F",
            }}
          >
            Choose a protection plan that includes coverage under a third-party
            liability insurance policy issued to Turo from Travelers Excess and
            Surplus Lines Company ("Travelers").
          </Text>
          {/* Standard */}

          <div
            style={{
              borderWidth: 1,
              marginTop: 20,
              padding: 20,
              borderColor: "#DADADA",
              borderStyle: "solid",
              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Title
              level={5}
              className="font_Medium"
              style={{
                fontSize: 18,
                marginTop: 0,
                color: "#000",
              }}
            >
              Standard
            </Title>
            <Text
              className={styles.font_Medium}
              style={{
                fontSize: 14,
                color: "#4F4F4F",
              }}
            >
              Dependable—Hit the road confidently with solid protection.
            </Text>
            <Button
              className={[styles.button_upload]}
              style={{
                marginTop: 10,
                borderColor: "#3E3E3E",
                borderWidth: 1,
                padding: 1,
                width: 100,
                height: 30,
                color: "white",
                borderStyle: "solid",
                fontFamily: "Prompt-Light",
                fontWeight: 600,
              }}
            >
              <Text
                className={styles.font_Medium}
                style={{
                  fontSize: 13,
                  color: "#464646",
                }}
              >
                See details
              </Text>
            </Button>
          </div>

          {/* Minimum */}

          <div
            style={{
              borderWidth: 1,
              marginTop: 20,
              padding: 20,
              borderColor: "#DADADA",
              borderStyle: "solid",
              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Title
              level={5}
              className="font_Medium"
              style={{
                fontSize: 18,
                marginTop: 0,
                color: "#000",
              }}
            >
              Minimum
            </Title>
            <Text
              className={styles.font_Medium}
              style={{
                fontSize: 14,
                color: "#4F4F4F",
              }}
            >
              Cost-effective—Stay covered while pinching some pennies.
            </Text>
            <Button
              className={[styles.button_upload]}
              style={{
                marginTop: 10,
                borderColor: "#3E3E3E",
                borderWidth: 1,
                padding: 1,
                width: 100,
                height: 30,
                color: "white",
                borderStyle: "solid",
                fontFamily: "Prompt-Light",
                fontWeight: 600,
              }}
            >
              <Text
                className={styles.font_Medium}
                style={{
                  fontSize: 13,
                  color: "#464646",
                }}
              >
                See details
              </Text>
            </Button>
          </div>

          <Title
            level={5}
            className="font_Medium"
            style={{
              fontSize: 16,
              marginTop: 30,
              color: "#000",
            }}
          >
            No thanks,I’d like to decline damage protection
          </Title>

          <Button
            // htmlType="submit"
            className={[styles.tmp_button]}
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            <Text
              className="font_Medium"
              style={{
                color: "white",
              }}
            >
              Confirm protection
            </Text>
          </Button>

          <img
            src={line2}
            className={styles.line}
            style={{ marginBottom: 0, marginTop: 0 }}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <AddCreditCard values={{ email: "test@gmail.com", name: "test" }} />
            {/* <iframe src="http://localhost:3000/addCardPayment" title="W3Schools Free Online Web Tutorials" > */}
            {/* http://localhost:3000/addCardPayment */}
          </div>

          {/* card payment */}

          {getCardByidReducer.result
            ? getCardByidReducer.result.map((item) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 20,
                    }}
                  >
                    <div
                      onClick={() => {
                        setSelect(item);
                      }}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Title
                        level={5}
                        className="font_Medium"
                        style={{
                          fontSize: 18,
                          marginTop: 0,
                          color: "#000",
                        }}
                      >
                        Protection plans
                      </Title>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Title
                        level={5}
                        className="font_Medium"
                        style={{
                          fontSize: 18,
                          marginTop: 0,
                          color: "#000",
                        }}
                      >
                        {item.brand} {item.last_digits}
                      </Title>
                      <img
                        src={Delete}
                        width={20}
                        style={{ marginLeft: 10 }}
                        height={20}
                        onClick={() => {
                          dispatch(
                            actions.deleteCard(item, showFormDeleteCardSuccess)
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              })
            : []}

          <img
            src={line2}
            className={styles.line}
            style={{ marginBottom: 0, marginTop: 0 }}
          />

          {/* select card */}
          {select ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <Title
                  level={5}
                  className="font_Medium"
                  style={{
                    fontSize: 18,
                    marginTop: 0,
                    color: "#000",
                  }}
                >
                  Selected
                </Title>
                <Title
                  level={5}
                  className="font_Medium"
                  style={{
                    fontSize: 18,
                    marginTop: 0,
                    color: "#000",
                  }}
                >
                  {select?.brand} {select?.last_digits}
                </Title>
              </div>
            </>
          ) : (
            <></>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <Title
              level={5}
              className="font_Medium"
              style={{
                fontSize: 18,
                marginTop: 0,
                color: "#000",
              }}
            >
              You’ll be able to message Aaron after checkout.
            </Title>
            <Checkbox
              onChange={() => {
                // salvage_title
              }}
            >
              I agree to pay the total shown and to the Turo terms of service
              and cancellation policy.
            </Checkbox>
            {/*
            <Button
              id="credit-card"
              type="button"
              onClick={handleClick}
              htmlType="button"
              className={[styles.tmp_button]}
              style={{ marginTop: 30, marginBottom: 30 }}
            >
              <Text
                className="font_Medium"
                style={{
                  color: "white",
                }}
              >
                Book this trip
              </Text>
            </Button> */}
            <div className="own-form">
              <Script
                url="https://cdn.omise.co/omise.js"
                onLoad={handleLoadScript}
              />
              <form>
                <Button
                  id="credit-card"
                  type="button"
                  onClick={handleClick}
                  htmlType="button"
                  className={[styles.tmp_button]}
                  style={{ marginTop: 30, marginBottom: 30 }}
                >
                  <Text
                    className="font_Medium"
                    style={{
                      color: "white",
                    }}
                  >
                    Book this trip
                  </Text>
                </Button>
              </form>
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div
            style={{
              borderWidth: 1,
              marginTop: 20,
              padding: 20,
              borderColor: "#DADADA",
              borderStyle: "solid",
              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <img src={car1} width="100%" />
              <div style={{ position: "absolute", bottom: -15, right: -40 }}>
                <img src={profile1} width="80" />
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: 20,
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    width: 50,
                    right: -15,
                    top: -15,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  <img src={star} width={15} />
                  <Text
                    className="font_Light"
                    style={{
                      marginLeft: 5,
                      fontSize: 17,
                      fontWeight: "bolder",
                      color: "#4F4F4F",
                    }}
                  >
                    4.5
                  </Text>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: 5,
              }}
            >
              <Title
                level={5}
                className="font_Light"
                style={{
                  fontSize: 18,
                  marginTop: 0,
                  marginBottom: 0,
                  color: "#B4B4B4",
                }}
              >
                Aaron’s
              </Title>
              <Title
                level={5}
                className="font_Light"
                style={{
                  fontSize: 18,
                  marginTop: 0,
                  marginBottom: 0,
                  color: "#000",
                }}
              >
                Mercedes-Benz S-Class 2017
              </Title>
              <Title
                level={5}
                className="font_Light"
                style={{
                  fontSize: 18,
                  marginTop: 0,
                  marginBottom: 0,
                  color: "#767575",
                }}
              >
                s 550 4matic
              </Title>
              <div style={{ marginTop: 5, display: "flex" }}>
                <img src={star} width="20" />
                <Text
                  className="font_Light"
                  style={{
                    marginLeft: 10,
                    marginTop: 5,
                    color: "black",
                  }}
                >
                  4.5 (35 trips)
                </Text>
              </div>
              <img
                src={line2}
                className={styles.line}
                style={{ marginBottom: 0, marginTop: 0 }}
              />
              <Title
                level={5}
                className="font_Light"
                style={{
                  fontSize: 18,
                  marginTop: 0,
                  marginBottom: 0,
                  color: "#000",
                }}
              >
                Meeting Location
              </Title>
              <div
                style={{ display: "flex", marginTop: 10, alignItems: "center" }}
              >
                <img src={document} width="40" />
                <Text
                  className="font_Light"
                  style={{
                    fontSize: 16,
                    paddingLeft: 10,
                    color: "black",
                  }}
                >
                  San Francisco, CA 94103
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <Text
                  className="font_Light"
                  style={{
                    fontSize: 16,
                    color: "black",
                  }}
                >
                  $101.00 x 3 days
                </Text>
                <Text
                  className="font_Light"
                  style={{
                    fontSize: 16,
                    color: "black",
                  }}
                >
                  $303.00
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <Text
                  className="font_Light"
                  style={{
                    fontSize: 16,
                    color: "black",
                  }}
                >
                  Trip fee
                </Text>
                <Text
                  className="font_Light"
                  style={{
                    fontSize: 16,
                    color: "black",
                  }}
                >
                  $79.43
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <Text
                  className="font_Light"
                  style={{
                    fontSize: 16,
                    color: "black",
                  }}
                >
                  Standard protection
                </Text>
                <Text
                  className="font_Light"
                  style={{
                    fontSize: 16,
                    color: "black",
                  }}
                >
                  $121.20
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <Text
                  className="font_Light"
                  style={{
                    fontSize: 16,
                    color: "black",
                  }}
                >
                  600 total miles
                </Text>
                <Text
                  className="font_Light"
                  style={{
                    fontSize: 16,
                    color: "black",
                  }}
                >
                  FREE
                </Text>
              </div>
              <div
                style={{
                  marginTop: 15,
                  borderWidth: 1,
                  padding: 10,
                  backgroundColor: "#F9F9F9",
                  borderColor: "#DADADA",
                  borderStyle: "solid",
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    className="font_Light"
                    style={{
                      fontSize: 16,
                      color: "black",
                    }}
                  >
                    Total
                  </Text>
                  <Text
                    className="font_Light"
                    style={{
                      fontSize: 16,
                      color: "black",
                    }}
                  >
                    $503.63
                  </Text>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    paddingTop: 20,
                    alignItems: "center",
                  }}
                >
                  <img src={like} style={{ width: 30 }} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text
                      className={styles.font_Medium}
                      style={{
                        marginLeft: 15,
                        fontSize: 16,
                        fontWeight: "bolder",
                        color: "#4F4F4F",
                      }}
                    >
                      Free cancellation
                    </Text>
                    <Text
                      className="font_Medium"
                      style={{
                        marginLeft: 15,
                        fontSize: 13,
                        color: "#B4B4B4",
                      }}
                    >
                      Full refund before October 8, 10:00 AM in local time of
                      the car
                    </Text>
                  </div>
                </div>
              </div>
              <Title
                level={5}
                className="font_Light"
                style={{
                  fontSize: 16,
                  marginTop: 15,
                  marginBottom: 0,
                  color: "#000",
                }}
              >
                Add promo code
              </Title>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
