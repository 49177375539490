import React, { useEffect, useState } from "react";
import styles from "../../styles/Reviews.module.css";
// import Nav from "react-bootstrap/Nav";
import Mychat from "./Mychat";
import Read from "./Read";
import { useSelector, useDispatch } from "react-redux";
import { Button, Rate, Tabs } from "antd";
import actions from "../../redux/actions";
// import { Button, Card, Form } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import CardCar from "../component/CardCar";
import { Layout, Menu, theme } from "antd";
import { Col, Row } from "antd";
import { Space } from "antd";
import { Typography } from "antd";
import line from "../../assets/images/line.png";
import like from "../../assets/images/like.png";
import line2 from "../../assets/images/line3.png";
import car1 from "../../assets/images/car1.png";
import crown from "../../assets/images/crown.svg";
import profile1 from "../../assets/images/profile1.png";
import star from "../../assets/images/star.svg";
import document from "../../assets/images/document.png";
import Script from "react-load-script";
import TabPane from "antd/es/tabs/TabPane";
import { Checkbox } from "antd";
import { Axios } from "axios";
import { apiUrl } from "../../utils/Constants";
import { Progress } from "antd";
import { useSearchParams } from "react-router-dom";
import { Avatar, Divider, Tooltip } from "antd";
import Moment from "moment";
import CommentReview from "../component/CommentReview";

export default function Reviews() {
  const dispatch = useDispatch();

  const [focus, setFocus] = useState("1");
  const [searchParams, setSearchParams] = useSearchParams();

  const getReviewCarReducer = useSelector(
    ({ getReviewCarReducer }) => getReviewCarReducer
  );

  useEffect(() => {
    dispatch(actions.getReview(searchParams.get("id")));
  }, []);

  const { Title, Text } = Typography;

  const onChange = (index) => {
    console.log(index);
  };

  return (
    <div className={[styles.container_fluid]}>
      <div
        style={{
          //   height: 200,
          backgroundColor: "#000",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          //   padding: 30,
          paddingLeft: "10rem",
          paddingRight: "10rem",
          paddingTop: "2rem",
          paddingBottom: "2rem",
        }}
      >
        <Title
          level={2}
          className="font_Light"
          style={{ margin: 0, color: "white" }}
        >
          Tell us how to pay you
        </Title>
        <Title
          level={4}
          className="font_Light"
          style={{ margin: 0, color: "white", marginTop: 20 }}
        >
          Set up direct deposit and get pald.
        </Title>
      </div>

      <div className={[styles.container_Reviews]}>
        <Tabs defaultActiveKey="1" onChange={onChange}>
          <TabPane tab="VEHICLES" key="2"></TabPane>
          <TabPane tab="PERROMANCE" key="3"></TabPane>
          <TabPane tab="REVIEWS" key="4"></TabPane>
          <TabPane tab="EARNINGS" key="5"></TabPane>
          <TabPane tab="SETTINGS" key="6"></TabPane>
        </Tabs>

        <div style={{ display: "flex", marginTop: 10 }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Title
              level={5}
              className="font_Light"
              style={{ margin: 0, color: "#939090" }}
            >
              RATINGS: LAST 365 DAYS (34)
            </Title>
            <div
              style={{
                borderWidth: 1,
                marginTop: 20,
                padding: 20,
                borderColor: "#DADADA",
                borderStyle: "solid",
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                width: 200,
                justifyContent: "center",
              }}
            >
              <img src={star} width="25" />
              <Title
                level={2}
                className="font_Light"
                style={{
                  margin: 0,
                  color: "#000",
                  marginLeft: 10,
                  marginTop: 3,
                }}
              >
                {getReviewCarReducer.result &&
                  getReviewCarReducer.result.star.rate_star}
              </Title>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}
          >
            <Title
              level={5}
              className="font_Light"
              style={{ margin: 0, color: "#939090" }}
            >
              trips rated
            </Title>
            <div
              style={{
                borderWidth: 1,
                marginTop: 20,
                padding: 20,
                borderColor: "#DADADA",
                borderStyle: "solid",
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 200,
              }}
            >
              <Title
                level={2}
                className="font_Light"
                style={{
                  margin: 0,
                  color: "#000",
                  marginLeft: 10,
                  marginTop: 3,
                }}
              >
                {getReviewCarReducer.result &&
                  getReviewCarReducer.result.star.percent_rate}
                %
              </Title>
            </div>
          </div>
        </div>

        <div
          style={{ display: "flex", marginTop: 20, flexDirection: "column" }}
        >
          <div
            style={{
              width: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: 60, display: "flex" }}>
              <Text className="font_Light" style={{ color: "#939090" }}>
                5 star
              </Text>
            </div>
            <Progress
              percent={
                getReviewCarReducer.result
                  ? (
                      (100 / getReviewCarReducer.result.star.count) *
                      getReviewCarReducer.result.star.five
                    ).toFixed(2) == "NaN"
                    ? 0
                    : (
                        (100 / getReviewCarReducer.result.star.count) *
                        getReviewCarReducer.result.star.five
                      ).toFixed(2)
                  : 0
              }
              strokeColor="#27B1FF"
              style={{ paddingTop: 5 }}
            />
          </div>
          <div
            style={{
              width: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: 60, display: "flex" }}>
              <Text className="font_Light" style={{ color: "#939090" }}>
                4 star
              </Text>
            </div>
            <Progress
              strokeColor="#27B1FF"
              percent={
                getReviewCarReducer.result
                  ? (
                      (100 / getReviewCarReducer.result.star.count) *
                      getReviewCarReducer.result.star.four
                    ).toFixed(2) == "NaN"
                    ? 0
                    : (
                        (100 / getReviewCarReducer.result.star.count) *
                        getReviewCarReducer.result.star.four
                      ).toFixed(2)
                  : 0
              }
              style={{ paddingTop: 5 }}
            />
          </div>
          <div
            style={{
              width: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: 60, display: "flex" }}>
              <Text className="font_Light" style={{ color: "#939090" }}>
                3 star
              </Text>
            </div>
            <Progress
              strokeColor="#27B1FF"
              percent={
                getReviewCarReducer.result
                  ? (
                      (100 / getReviewCarReducer.result.star.count) *
                      getReviewCarReducer.result.star.three
                    ).toFixed(2) == "NaN"
                    ? 0
                    : (
                        (100 / getReviewCarReducer.result.star.count) *
                        getReviewCarReducer.result.star.three
                      ).toFixed(2)
                  : 0
              }
              style={{ paddingTop: 5 }}
            />
          </div>
          <div
            style={{
              width: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: 60, display: "flex" }}>
              <Text className="font_Light" style={{ color: "#939090" }}>
                2 star
              </Text>
            </div>
            <Progress
              strokeColor="#27B1FF"
              percent={
                getReviewCarReducer.result
                  ? (
                      (100 / getReviewCarReducer.result.star.count) *
                      getReviewCarReducer.result.star.two
                    ).toFixed(2) == "NaN"
                    ? 0
                    : (
                        (100 / getReviewCarReducer.result.star.count) *
                        getReviewCarReducer.result.star.two
                      ).toFixed(2)
                  : 0
              }
              style={{ paddingTop: 5 }}
            />
          </div>
          <div
            style={{
              width: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: 60, display: "flex" }}>
              <Text className="font_Light" style={{ color: "#939090" }}>
                1 star
              </Text>
            </div>
            <Progress
              strokeColor="#27B1FF"
              percent={
                getReviewCarReducer.result
                  ? (
                      (100 / getReviewCarReducer.result.star.count) *
                      getReviewCarReducer.result.star.one
                    ).toFixed(2) == "NaN"
                    ? 0
                    : (
                        (100 / getReviewCarReducer.result.star.count) *
                        getReviewCarReducer.result.star.one
                      ).toFixed(2)
                  : 0
              }
              style={{ paddingTop: 5 }}
            />
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <Title
            level={5}
            className="font_Light"
            style={{ margin: 0, color: "#939090" }}
          >
            Top comment
          </Title>
        </div>
        <div
          style={{
            marginTop: 30,
            // display: "flex",
          }}
        >
          {getReviewCarReducer.result
            ? getReviewCarReducer.result.data.map((item) => {
                return <CommentReview item={item} />;
              })
            : []}

          {/* <div style={{ width: 350, marginLeft: 50 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ marginTop: 20, display: "flex" }}>
                <img src={profile1} style={{ width: 100, height: 100 }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 20,
                  }}
                >
                  <Title
                    level={4}
                    className="font_Medium"
                    style={{ margin: 0, color: "#000" }}
                  >
                    Mareeya
                  </Title>
                  <div style={{ display: "flex", marginTop: 10 }}>
                    <img src={crown} width={20} />
                    <Title
                      level={5}
                      className="font_Light"
                      style={{ margin: 0, color: "#000", marginLeft: 10 }}
                    >
                      All-star hort
                    </Title>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Title
                      level={5}
                      className="font_Light"
                      style={{ margin: 0, color: "#000" }}
                    >
                      415 trip joined aug 2020
                    </Title>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <img src={star} width="15" />
              <img src={star} width="15" style={{ marginLeft: 2 }} />
              <img src={star} width="15" style={{ marginLeft: 2 }} />
              <img src={star} width="15" style={{ marginLeft: 2 }} />
              <img src={star} width="15" style={{ marginLeft: 2 }} />
            </div>
            <div style={{ marginTop: 10 }}>
              <Text className="font_Light" style={{ color: "#939090" }}>
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmodtempor incididunt ut labore et dolore magna aliqua. Ut
                enimad minim veniam.”
              </Text>
            </div>
            <div
              style={{
                marginTop: 10,
              }}
            >
              <Text className="font_Medium" style={{ color: "#000" }}>
                Lorem ipsum. sep 2022
              </Text>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
