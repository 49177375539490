import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";
import Actions from "../actions";
import { call, put, delay } from "redux-saga/effects";

export function* sagaGetCheckMapCar({
  setSearch,
  setLocation,
  startDate,
  endDate,
}) {
  try {
    yield put(Actions.checkMapCarFetching());

    const lat = JSON.parse(localStorage.getItem("location")).lat;
    const lng = JSON.parse(localStorage.getItem("location")).lng;

    setLocation({ lat: lat, lng: lng });
    const response = yield call(
      httpClient.get,
      `${server.MAP_URL}?lat=${lat}&lng=${lng}&startDate=${startDate}&endDate=${endDate}`
    );

    if (response.status == 200) {
      setSearch(response.data.data);
      yield put(Actions.checkMapCarSuccess(response.data.data));
    }
  } catch (error) {
    yield put(Actions.checkMapCarFailed());
  }
}
