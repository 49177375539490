import * as actions from "../actionTypes";
import httpClient from "../../utils/HttpClient";
import { server } from "../../utils/Constants";

export const postReviewCar = (payload, showFormSuccess, showFormError) => ({
  type: actions.POST_REVIEW_CAR_REQUEST,
  payload,
  showFormSuccess,
  showFormError,
});

export const postReviewClinet = (payload, showFormSuccess, showFormError) => ({
  type: actions.POST_REVIEW_CLIENT_REQUEST,
  payload,
  showFormSuccess,
  showFormError,
});

export default {
  postReviewCar,
  postReviewClinet,
};
