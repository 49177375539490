import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./frontoffice/components/fragments/Footer";
import Header from "./frontoffice/components/fragments/Header";
import ApprovedDrive from "./frontoffice/components/pages/ApprovedDrive";
import FindCar from "./frontoffice/components/pages/FindCar";
import Inbox from "./frontoffice/components/pages/Inbox";
import SearchCar from "./frontoffice/components/pages/SearchCar";
import Verify from "./frontoffice/components/pages/Verify";
import Payment from "./frontoffice/components/pages/Payment";
import Reviews from "./frontoffice/components/pages/Reviews";
import PostReviews from "./frontoffice/components/pages/PostReviews";
import Profile from "./frontoffice/components/pages/Profile";
import Car from "./frontoffice/components/pages/Car";
import TicketSupport from "./frontoffice/components/pages/TicketSupport";
import AddCardPayment from "./frontoffice/components/pages/AddCardPayment";

// import actions from "./redux/actions";

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    getLocation();
  }, []);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
    }
  }

  function showPosition(position) {
    localStorage.setItem(
      "MyLocation",
      JSON.stringify({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    );
  }

  return (
    <Router>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<FindCar />} />
        <Route path="/searchCar" element={<SearchCar />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/approved/drive" element={<ApprovedDrive />} />
        <Route path="/payment" element={<Payment />} />
        {/* <Route path="/addCardPayment" element={<AddCardPayment />} /> */}
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/post/reviews" element={<PostReviews />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/car" element={<Car />} />
        <Route path="/ticketSupport" element={<TicketSupport />} />
        <Route path="/inbox" element={<Inbox />} />
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}
