import { Button, Typography } from "antd";
import React, { useState } from "react";
import Script from "react-load-script";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import actions from "../../redux/actions";
import styles from "../../styles/Verify.module.css";

const { Title, Text } = Typography;

let OmiseCard;

export default function CreditCard({ values }) {
  const dispatch = useDispatch();

  const handleLoadScript = () => {
    OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      publicKey: "pkey_test_5v11ajc7ljbhwhqtywm",
      currency: "THB",
      frameLabel: "Vokkee",
      submitLabel: "Pay NOW",
      buttonLabel: "Pay with Omise",
    });
  };

  const creditCardConfigure = () => {
    OmiseCard.configure({
      defaultPaymentMethod: "credit_card",
      otherPaymentMethods: [],
    });
    OmiseCard.configureButton("#credit-card");
    OmiseCard.attach();
  };

  const handleClick = (e) => {
    creditCardConfigure();
    omiseCardHandler();
  };

  const showFormVerifyCardSuccess = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      customClass: "font_Light",
      title: "Verify Card Success",
      text: "",
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {});
  };

  const omiseCardHandler = () => {
    let tokenParameters = {
      ...values,
    };

    OmiseCard.open({
      amount: "2000",
      onCreateTokenSuccess: (token) => {
        dispatch(
          actions.createVerifyCard(
            {
              uid: "1",
              email: tokenParameters.email,
              name: tokenParameters.name,
              amount: "2000",
              omiseToken: token,
              orderReceivedUrl:
                "https://97cd-2001-fb1-11c-e854-c99-21aa-db6-d086.ap.ngrok.io/api/v1/omise/webhook",
            },
            showFormVerifyCardSuccess
          )
        );
      },
      onFormClosed: () => {},
    });
  };

  return (
    <div className="own-form">
      <Script url="https://cdn.omise.co/omise.js" onLoad={handleLoadScript} />
      <form>
        <div type="button" onClick={handleClick} id="credit-card">
          <Button
            className={[styles.tmp_button, "mt-4"]}
            style={{ marginTop: 10 }}
          >
            <Text
              style={{
                color: "white",
              }}
            >
              Verify Card
            </Text>
          </Button>
        </div>
      </form>
    </div>
  );
}
