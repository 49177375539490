import { db, database } from "../../utils/FirebaseDB";
import * as actions from "../actionTypes";

export const getCardByid = () => ({
  type: actions.GET_CARD_BYID_REQUEST,
});

export const getCardByidFetching = () => ({
  type: actions.GET_CARD_BYID_FETCHING,
});

export const getCardByidFailed = () => ({
  type: actions.GET_CARD_BYID_FAILED,
});

export const getCardByidSuccess = (payload) => ({
  type: actions.GET_CARD_BYID_SUCCESS,
  payload,
});

export default {
  getCardByid,
  getCardByidFetching,
  getCardByidFailed,
  getCardByidSuccess,
};
