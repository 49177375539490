import React, { useEffect, useState } from "react";
import styles from "../../styles/SearchCar.module.css";
// import { Card } from "react-bootstrap";
import { Card, Typography } from "antd";
import star from "../../assets/images/star.svg";
import crown from "../../assets/images/crown.svg";
import manuser from "../../assets/images/man-user.svg";
import iconcar from "../../assets/images/iconcar.svg";
import heart from "../../assets/images/heart.svg";
import distance from "../../assets/images/distance.png";
import line3 from "../../assets/images/line3.svg";

export default function CardCarMap({ item }) {
  const { Title, Text } = Typography;
  return (
    <div
      className={[styles.border_card]}
      style={{
        position: "relative",
        width: "100%",
        backgroundColor: "white",
        height: 300,
        paddingBottom: 5,
        marginBottom: 20,
      }}
    >
      <img src={`${item.image}`} width={"100%"} />
      <img
        src={heart}
        width={22}
        style={{ position: "absolute", right: 10, top: 10 }}
      />
      <div style={{ padding: 10 }}>
        <Title
          level={5}
          className={"font_Medium"}
          style={{ fontSize: 14, color: "#000", margin: 0 }}
        >
          {item.name}
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 7,
          }}
        >
          {/* star */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img src={star} width={12} style={{ paddingBottom: 4 }} />
            <Text
              className={"p-0 m-0 ps-1 pt-1 font_Light"}
              style={{
                fontSize: 12,
                color: "#000",
                margin: 0,
                marginLeft: 5,
              }}
            >
              {item.star}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginLeft: 10,
            }}
          >
            <img
              src={crown}
              width={12}
              className="pb-0"
              style={{ paddingBottom: 2 }}
            />
            <Text
              className={"font_Light"}
              style={{ fontSize: 12, color: "#000", marginLeft: 5 }}
            >
              {item.crown}
            </Text>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
          {/* star */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img src={manuser} width={12} style={{ paddingBottom: 2 }} />
            <Text
              className={"font_Light"}
              style={{ fontSize: 12, color: "#000", marginLeft: 5 }}
            >
              {item.user}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginLeft: 10,
            }}
          >
            <img src={iconcar} width={18} style={{ paddingBottom: 2 }} />
            <Text
              className={"font_Light"}
              style={{ fontSize: 12, color: "#000", marginLeft: 5 }}
            >
              {item.type}
            </Text>
            <Text
              className={"font_Light"}
              style={{ fontSize: 12, color: "#000", marginLeft: 5 }}
            >
              ({item.trip} trip)
            </Text>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          <img src={distance} width={15} />

          <Text
            className={"font_Light"}
            style={{ fontSize: 12, color: "#000", marginLeft: 10 }}
          >
            {item.distance} km.
          </Text>
        </div>

        {/* line */}
        <img
          src={line3}
          height={0.5}
          style={{ marginBottom: 5, marginTop: 5 }}
          className={[styles.line]}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: 5,
          }}
        >
          <Text
            className="font_Medium"
            style={{ fontSize: 12, color: "#000", margin: 0 }}
          >
            ${item.total}/day
          </Text>
        </div>
      </div>
    </div>
  );
}
